export const SEARCH_TYPES = {
  ALL_AFFAIRS: "AllAffairs",
  NATIONAL_AFFAIR: "NationalAffair",
  CANTONAL_AFFAIR: "CantonalAffair",
  NATIONAL_COUNCILLOR: "NationalCouncillor",
  CANTONAL_COUNCILLOR: "CantonalCouncillor",
  NATIONAL_COUNCIL: 'NationalCouncil',
  CANTONAL_COUNCIL: 'CantonalCouncil',
  CUSTOM_AFFAIR: "CustomAffair",
  NATIONAL_AFFAIR_CONSULTATION: "NationalAffairConsultation",
  CANTONAL_AFFAIR_CONSULTATION: "CantonalAffairConsultation",
  COUNCILLOR_NO_VOTES: "councillor.affairSuccess.noVotes",
  COUNCILLOR_POSITIVE_VOTES: "councillor.affairSuccess.positiveVotes",
  COUNCILLOR_PARTIAL_POSITIVE_VOTES: "councillor.affairSuccess.partialPositiveVotes",
  COUNCILLOR_NEGATIVE_VOTES: "councillor.affairSuccess.negativeVotes",
  COUNCILLOR_UNKNOWN_VOTES: "councillor.affairSuccess.unknownVotes",
  CANTONAL_COMMITTEE_PROGRAM_START_CANTONAL_AFFAIR_EVENT : "CantonalCommitteeProgramStartCantonalAffairEvent",
  NATIONAL_SESSION_PROGRAM_NATIONAL_AFFAIR_EVENT: 'NationalSessionProgramNationalAffairEvent',
  NATIONAL_COMMITTEE: 'NationalCommittee',
  CANTONAL_COMMITTEE: 'CantonalCommittee',
  AUTHOR_COMMITTEE: 'author_committee',
  CUSTOM_MEETING: 'CustomMeeting',
  CANTONAL_MISC_AUTHOR: 'CantonalMiscAuthor',
  AUTHOR_MISC: 'author_misc',
  NATIONAL_AFFAIR_CONSULTATION_AUHTOR: 'NationalAffairConsultationAuthor',
  CANTONAL_AFFAIR_CONSULTATION_AUTHOR: 'CantonalAffairConsultationAuthor',
  NATIONAL_PARTY_COUNCILLOR_MEMBERSHIP: 'NationalPartyCouncillorMembership',
  CANTONAL_PARTY_MEMBERSHIP: 'CantonalPartyMembership',
  DEPARTMENT: 'Department',
  USER: 'User',
  DISTRICT_CANTON: "DistrictCanton"
};

export const SEARCH_SORT_ORDER_BY = {
  AFFAIR: 'affairOrderBy',
  COUNCILLOR: 'councillorOrderBy',
  CONSULTATION: 'consultationOrderBy',
};

export const SEARCH_SORT_FIELDS = {
  BEST_RESULT: 'none',
  CANTON: 'canton',
  LAST_EVENT_DATE: 'lastEventDate',
  DEPOSIT_DATE: 'depositDate',
  TITLE: 'title',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
};