import React, { FC } from "react";
import { Spinner } from "../Spinner";
import FormControl from "@material-ui/core/FormControl";
import { IntegrationReactSelect } from "./MaterialMultipleSelect";
import { trans } from "@trans";

type $StatusSelectProps = {
  //section: $Area | string,
  margin?: "normal" | "none" | "dense",
  onChange: Function,
  value?: string[],
  disabled?: boolean;
  options: any[];
  isLoading: boolean;
  fullWidth?: boolean;
}
 
export const StatusSelect: FC<$StatusSelectProps> = (props) => {
  const {
    margin,
    onChange,
    value,
    options,
    isLoading,
    fullWidth
  } = props;

  return (
    <FormControl
      fullWidth={fullWidth}
      className='mr-1'
      margin={margin}
      style={{ zIndex: 'unset' }}>
      {isLoading ? (
        <Spinner />
      ) : (
        <IntegrationReactSelect
          {...props}
          onChange={onChange}
          value={value}
          placeholder={trans("ui.select_input.options.none")}
          label={trans("layout.search.affairStatesNational")}
          options={options} />
      )}
    </FormControl>
  );
};

