import React, { useState } from "react";
import styled from "styled-components";
import { get, uniq } from "lodash";

import { SimpleSelect, TextInput, Checkbox } from "@components/Shared/ui";

import { GLOBALS, ROLES, SPACINGS } from "@constants";
import { trans } from "@trans";
import { getAffairFromTableItem, getId } from "./script";
import { Header } from "../BaseUI";
import { uniqBy } from "lodash";
import { actionsDefault } from "./MenuActions";

export const ActionBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

type $Props = {
  checklist: ($Affair | $Event["xPltkParent"])[],
  actions?: $AffairTable_props["actions"],
  config: $AffairTable_config,
  filters: $AffairTable_filters,
  setFilters: Function,
  onSuccess?: Function,
  pagination: unknown,
  showPageSizeSelect: boolean,
  localStorageKey?: string | null,
  user: $User,
  setChecklist: Function,
  items: unknown[],
  title?: string,
  shouldShowDefaultActions?: boolean;
  setRemoteSearchQuery?: Function;
}

export function ActionBar({
  checklist,
  actions,
  config,
  filters,
  setFilters,
  onSuccess,
  pagination,
  showPageSizeSelect,
  localStorageKey,
  user,
  setChecklist,
  items,
  title,
  shouldShowDefaultActions = false,
  setRemoteSearchQuery
}: $Props) {
  const [sizeOptions] = useState<$UI_SelectOption[]>(uniq(
    [
      ...GLOBALS.PAGE_SIZES,
      get(user, "settings.list_size.value", 0),
      get(pagination, "itemsPerPage", filters.pageSize),
    ]
      .map(Number)
      .filter((v) => v)
  )
    .sort((a, b) => a - b)
    .map((s) => ({ label: String(s), value: s })));

  const allSelectable = uniqBy(items.map(getAffairFromTableItem).filter(Boolean), getId);
  const isChecked = allSelectable.length > 0 && (allSelectable.length === checklist.length);
  const isReadOnlyUser = user.roles.includes(ROLES.ROLE_FULL_READONLY) &&
    !user.roles.includes(ROLES.ROLE_FULL_READWRITE);

  return (
    <ActionBarWrapper>
      <div style={{
        display: 'flex',
        flexDirection: config.hideActions ? 'row' : 'column',
        justifyContent: 'space-between',
      }}>
        <div style={{ marginBottom: SPACINGS.X2 }}>
          {config.search && title && (
            <Header style={{ marginRight: SPACINGS.X1 }}>{title}</Header>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: !config.hideActions ? 'space-between' : 'flex-end', alignItems: 'center', height: SPACINGS.X4 }}>
          <div>
            {!config.hideActions && (
              <TextInput
                dataTest='action-bar-search'
                label={trans("layout.search.query")}
                name='search'
                value={filters.search}
                endicon='affair_list.search'
                onChange={(e) => {
                  if (setRemoteSearchQuery) {
                    setFilters({ ...filters, search: e.target.value });
                    setRemoteSearchQuery(e.target.value);
                  }
                  else {
                    setFilters({ ...filters, search: e.target.value, page: 1 });
                  }
                }} />
            )}
          </div>
          <div style={{ alignItems: 'center', width:'100%'}}>
            {Array.isArray(actions) &&
              actions.map((Action, i) => (
                <Action key={i} selectedItems={checklist} onSuccess={onSuccess} />
              ))}
          </div>
          <div style={{ display: 'flex', alignItems: 'center'}}>
            {showPageSizeSelect && (
              <SimpleSelect
                style={{ minWidth: "unset" }}
                title={trans("ui.table.header.actions.size.label")}
                value={get(pagination, "itemsPerPage", filters.pageSize)}
                onChange={(e) => {
                  if (
                    localStorageKey &&
                    e.target.value !==
                    get(pagination, "itemsPerPage", filters.pageSize)
                  ) {
                    localStorage.setItem(
                      localStorageKey,
                      JSON.stringify({ pageSize: e.target.value })
                    );
                  }

                  get(pagination, "setItemsPerPage")
                    ? get(pagination, "setItemsPerPage")(e.target.value)
                    : setFilters({ ...filters, pageSize: e.target.value, page: 1 });
                }}
                options={sizeOptions} />
            )}
            <div style={{ marginRight: `-${SPACINGS.X1}`}}>
              {!isReadOnlyUser && !config.hideColumns?.includes("actions") &&
                shouldShowDefaultActions && actionsDefault.map((Action, i) => (
                <Action key={i} selectedItems={checklist} onSuccess={onSuccess} />
              ))}
            </div>
            {!isReadOnlyUser && !config.hideColumns?.includes("actions") &&
              <Checkbox
                checked={isChecked}
                onChange={() => setChecklist(isChecked ? [] : allSelectable)} />
            }
          </div>

        </div>
      </div>
    </ActionBarWrapper>
  );
}
