import { trans as helpersTranslate } from "@trans";
import { getLanguageKey } from '@helpers';
import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import { updateLanguage } from './actions';

const useTranslation = () => {
  const {languageKey} = useSelector((globalState: $Reducer_root) => globalState.translations);

  const trans = useCallback((
    tranlationKey: string,
    alt?: string,
    section?: string
  ) => {
    return helpersTranslate(tranlationKey, alt, section);
  }, [languageKey]);

  const dispatch = useDispatch();

  const setLanguage = useCallback((val: string) => {
    dispatch(updateLanguage(getLanguageKey(val)));
  }, [dispatch]);

  return {
    trans,
    setLanguage,
    languageKey
  };
};

export default useTranslation;