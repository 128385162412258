import React, { ReactNode } from "react";
import styled from "styled-components";
import Select from "react-select";
import Typography from "@material-ui/core/Typography";
import { oldVersion as TextField, Chip } from "../ui";
import Paper from "@material-ui/core/Paper";
import { trans } from "@trans";
import { arrify } from '@helpers';
import { COLORS, SHARED, SPACINGS } from "@constants";
import get from "lodash/get";
import { A, InlineBadge, MenuItem } from "../BaseUI";
import Icon from "@components/Shared/InventoryIcon";

function NoOptionsMessage(props: { children?: ReactNode, selectProps: object }) {
  return props.children;
}

const StyledInputComponent = styled.div`
  &.MuiInputBase-input {
    display: flex;
    height: inherit;
  }
`;

function inputComponent({ inputRef, ...props }) {
  return (
    <StyledInputComponent
      ref={inputRef}
      data-test={props.name}
      {...props}
      style={{
        display: "flex",
        height: "inherit",
        justifyContent: "space-between",
      }} />
  );
}

function Control(props: {
  children?: React.ReactNode,
  innerProps: { onMouseDown: Function },
  innerRef: React.RefObject<HTMLElement>,
  selectProps: { TextFieldProps: object },
}) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth={true}
      onClick={(e) => {
        e.stopPropagation();
        const input = e.target.querySelector("input");

        if (input) {
          input.focus();
        }
      }}
      className='MuiInput-input__politik__multiple'
      InputProps={{
        // style: { minHeight: 44 },
        inputComponent,
        inputProps: {
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps} />
  );
}

function Option(props: {
  children: React.ReactNode,
  innerProps: {
    id: string,
    onClick?: Function,
    onMouseMove?: Function,
    onMouseOver?: Function,
    tabIndex: number,
  },
  data: {
    code: string,
    label: string,
    canton: string | null,
    key: string,
    value: string,
  }
  /**
   * Inner ref to DOM Node
   */
  innerRef: React.RefObject<HTMLElement>,
  isFocused: boolean,
  isSelected: boolean,
  isDisabled?: boolean
}) {
  const isPrivate = get(props, 'data.private', false);

  return (
    <MenuItem
      ref={props.innerRef}
      disabled={props.isDisabled}
      selected={props.isFocused}
      className='politik__multiple_select__menu_item'
      component='div'
      style={{
        fontWeight: 400,
      }}
      {...props.innerProps}>
      <div data-test='group-select-menu-item' 
        style={{
          maxWidth: "100%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}>
        {isPrivate && (
          <Icon
            icon='ui.private'
            style={{marginRight: 3}}/>
        )}
        {props.data.canton && (
          <img src={props.data.canton} width={SPACINGS.X2} height={SPACINGS.X2}
            style={{ marginRight: SPACINGS.X1 }} />
        )}
        {props.data.code && (
          <InlineBadge text={props.data.code} style={{ color: 'white', width: SPACINGS.X8, marginRight: SPACINGS.X1 }} />
        )}
        {props.children}
      </div>
    </MenuItem>
  );
}


function Placeholder(props: {
  children: React.ReactNode,
  innerProps: object,
  selectProps: object,
}) {
  const { children } = props;

  return (
    <div style={{ height: SHARED.INPUT_HEIGHT, color: COLORS.GRAY4 }}>
      {children}
    </div>
  );
}

function SingleValue(props: {
  children?: React.ReactNode,
  /**
   * Props passed to the wrapping element for the group.
   */
  innerProps: object,
  selectProps: object,
}) {
  return <Typography {...props.innerProps}>{props.children}</Typography>;
}


const StyledValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: 'Roboto', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  ${props => props.isMulti ? "" : `
    flex-wrap: nowrap;
    & p.MuiTypography-root {
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: ${SHARED.INPUT_HEIGHT};
    }
  `}
`;

const DeleteIcon = styled.i`
  color: inherit;
  line-height: 100%;
  margin-left: 7px;
  cursor: pointer;
`;

const ValueContainer = (props: {
  /**
   * The children to be rendered.
   */
  children?: React.ReactNode,
  selectProps: object,
  isMulti?: boolean
}) => (
  <StyledValueContainer className='multiple-select__value-container' isMulti={props.isMulti} data-test='group-select-container'>
    {props.children}
  </StyledValueContainer >
);

function MultiValue(props: {
  children?: React.ReactNode,
  isFocused: boolean,
  isDisabled: boolean,
  removeProps: {
    onClick: Function,
    onMouseDown: Function,
    onTouchEnd: Function,
  },
  selectProps: object,
}) {
  const isPrivate = get(props, 'data.private', false);

  const baseStyle = { overflow: "hidden", textOverflow: "ellipsis" };
  const style =
    props.isDisabled
      ? { ...baseStyle, color: "white", pointerEvents: "all" }
      : { ...baseStyle, pointerEvents: "all", color: "inherit" };

  return (
    <Chip 
      tabIndex={-1}
      className='multiple-select__chip'
      disabled={props.isDisabled} 
      data-test='group-select-item'>
      <A
        to={get(props, "data.url", null)}
        style={style}>
        {isPrivate && (
          <Icon
            icon='ui.private'
            style={{marginRight: 3}}/>
        )}
        {props.children}
      </A>
      {!props.isDisabled && (
        <DeleteIcon {...props.removeProps} onClick={props.removeProps.onClick} data-test='group-select-item-delete'>
          <Icon icon='ui.close' />
        </DeleteIcon>
      )}
    </Chip>
  );
}


function Menu(props: {
  /**
   * The children to be rendered.
   */
  children: React.ReactNode,
  /**
   * Props to be passed to the menu wrapper.
   */
  innerProps: object,
  selectProps: object,
}) {
  return (
    <Paper
      data-test='group-select-menu'
      square={true}
      {...props.innerProps}
      style={{
        position: "absolute",
        fontFamily: "'Roboto', sans-serif",
        zIndex: 99,
        marginTop: "5px",
        width:'fit-content',
        left: 0,
        background: "#FFFFFF",
        boxShadow: "0px 7px 64px rgba(0, 0, 0, 0.12)",
        borderRadius: "6px",
      }}>
      {props.children}
    </Paper>
  );
}

const DropdownIndicator = ({ isDisabled, selectProps, options, isMulti }) =>
  !isDisabled ? (
    <div style={{ cursor: "pointer" }} onClick={e => {
      e.stopPropagation();
    }}>

      {!get(selectProps, "config.disableSelectAll", false) && isMulti && (options?.length > selectProps.value?.length) && <i
        data-test='group-selector-all'
        title={trans("ui.select.all")}
        className='fas fa-check-double'
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          selectProps.onChange(options);
        }}
        style={{ color: "rgba(0, 0, 0, 0.54)", padding: "0 8px" }} />}
      <i
        data-test='group-selector-indicator' 
        className='fas fa-caret-down'
        style={{ color: "rgba(0, 0, 0, 0.54)", padding: "0 8px" }} />
    </div>
  ) : null;

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator,
};

const selectStyles = {
  input: (base) => ({
    ...base,
    "& input": {
      font: "inherit",
    },
  }),
  clearIndicator: (style) => ({
    ...style,
    cursor: "pointer",
  }),
  indicatorsContainer: (style) => ({
    ...style,
    height: SHARED.INPUT_HEIGHT,
    // transform: "translateX(27px)",
    alignSelf: "center",
  }),
  placeholder: (style) => ({
    ...style,
    height: SHARED.INPUT_HEIGHT,
  }),
};

type $Props = {
  options: $UI_SelectOption[];
  value: (string | number)[] | string | number | undefined;
  onChange: Function;
  label?: string;
  loading?: boolean;
  allSelected?: boolean;
  multi?: boolean;
  iri?: boolean;
  itemLabel?: Function;
  className?: string;
  isFiltering?: boolean;
  isClearable?: boolean;
  onTypeText?: Function;
  name?: string;
  error?: boolean;
  disabled?: boolean;
  dataTest?: string;
  maxItems?: number;
  placeholder?: string;
  getWholeItem?: boolean;
  config?: $MultipleSelectConfig
};

export function IntegrationReactSelect(props: $Props) {
  //Do not remove this local state - without it typing into the
  //input text field does not work correctly
  const [text, setText] = React.useState("");
  const { config, label } = props;
  const options: $UI_SelectOption[] = (props.loading
    ? [
      {
        label: trans("ui.select.loading"),
        isDisabled: true,
        value: text,
        key: 0,
      },
    ]
    : []
  ).concat(
    props.options.map((g) => ({
      ...g,
      label: props.itemLabel ? props.itemLabel(g) : g.label || g.title,
      value: g.value === undefined ? (props.iri || g["@id"] || g.id) : g.value,
      key: g["@id"] || g.id || g.value,
      isDisabled: false,
      private: g.private
    }))
  );
  const [multi, setMulti] = React.useState(
    props.allSelected
      ? options
      : props.multi === false
        ? props.value
        : options.filter((g) => (arrify(props.value) || []).includes(g.value))
  );

  function handleChangeMulti(value) {
    setMulti(value);
  }

  return (
    <Select
      className={props.className}
      data-test={props.dataTest}
      styles={selectStyles}
      isClearable={props.isClearable}
      TextFieldProps={{
        label: props.label || props.itemLabel,
        onChange: (e) => {
          if (props.onTypeText) {
            props.onTypeText(e);
          }

          setText(e.target.value);
        },
        name: props.name || label,
        error: props.error,
        InputLabelProps: {
          shrink: true,
        },
      }}
      isDisabled={props.disabled}
      filterOption={props.isFiltering}
      maxItems={props.maxItems}
      isLoading={props.loading}
      placeholder={props.placeholder}
      options={options}
      components={components}
      value={props.value
        ? props.multi === false
          ? props.value
          : options.filter((g) =>
            (arrify(props.value) || []).includes(g.value)
          )
        : multi}
      onChange={(v) => {
        handleChangeMulti(v);
        const values =
          Array.isArray(v) && v.length === 0
            ? options.filter((o) => o.isDisabled)
            : v;

        props.onChange({
          target: {
            value:
              props.multi === false
                ? v
                : (values || []).map((item) =>
                  props.getWholeItem ? item : item.value
                ),
          },
        });
      }}
      isMulti={props.multi === undefined ? true : props.multi}
      config={config} />
  );
}
