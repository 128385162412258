import React, { Component } from "react";
import { Provider } from "react-redux";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { NotificationPopup } from "@components/Shared";
import { Content } from "./AppContent";
import moment from "moment";

import { COLORS } from "@constants";
import store from "@redux/store";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: COLORS.PRIMARY,
    },
    secondary: {
      main: COLORS.INTERACTIVE,
    },
  },
});

moment.locale("fr-ch");

export default class App extends Component<{ env: "dev" | "prod" }> {
  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={materialTheme}>
          <NotificationPopup />
          <Content env={this.props.env} />
        </ThemeProvider>
      </Provider>
    );
  }
}
