import React from 'react';
import { GLOBALS } from '@constants';
import { getItemUrl } from '@helpers';
import { A } from "../Shared/BaseUI";
import { Routing } from '@backendRouter';

export const CouncillorList = ({ councillors, cantons }: { councillors: $Councillor[], cantons: $Canton[] }) => {
  const cantonalObject = cantons
    .reduce((acc, curr) => ({ ...acc, [curr.slug]: curr.title }), {});

  return <div className='row councillor-card-list'>{councillors.map((councillor, i) => <div className='col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3' key={i}>
    <div className='card card-profile'>
      <div className='card-avatar'>
        <A to={getItemUrl(councillor)} className='avatar-link'>
          <img src={councillor.smallPicturePath || councillor.picturePath || GLOBALS.COUCILLOR_DEFAULT_IMG}
            className='lazy desaturate'
            alt={councillor.fullName} />
        </A>
      </div>
      <div className='card-body'>
        <h3 className='councillor-name'>
          <A to={getItemUrl(councillor)}>
            {councillor.fullName}
          </A>
        </h3>
        <A to={Routing.generate('show_cantons', {
          slug: councillor.canton.slug,
        })}>
          {cantonalObject[councillor.canton.slug]}
        </A>
        <br />
        {councillor.faction && <A to={getItemUrl({ ...councillor.faction, canton: councillor.canton })}>
          {councillor.faction.fullName || `${councillor.faction.title} (${councillor.faction.shortname})`}
        </A>}
      </div>
    </div>
  </div>)}</div>;
};