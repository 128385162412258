import React, { FunctionComponent } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { InputLabel, FormControl } from "../BaseUI";
import { COLORS } from '@constants';

type $Props = {
  label?: string;
  choices: $UI_SelectOption[];
  onChange: (event: any) => void;
  value: any;
  name?: string;
};

export const RadioButtonGroup: FunctionComponent<$Props> = ({
  choices,
  label,
  onChange,
  value,
  name,
}) => {
  return (
    <FormControl>
      {label && <InputLabel>{label}</InputLabel>}
      <RadioGroup
        aria-label={name}
        name={name}
        row={true}
        onChange={onChange}
        value={value}>
        {choices.map((o, i) => (
          <FormControlLabel
            style={{color: COLORS.GRAY1}}
            className='mb-0'
            key={i}
            control={<Radio color='primary' />}
            label={o.label}
            value={o.value}
            labelPlacement='end'/>
        ))}
      </RadioGroup>
    </FormControl>
  );
};

