import React, { CSSProperties, ReactElement } from "react";
import get from "lodash/get";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import styled from "styled-components";
import { trans } from "@trans";
import { detectIE } from '@helpers';
import { inputStyles } from "./TextInput";
import { MenuItem } from '../BaseUI';
import { COLORS } from '@constants';


const emptyValue = "empty_select_value";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 85,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const FC = styled(FormControl)`
${props => inputStyles(props)}
.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input,.MuiInput-input__politik,input, textarea, .MuiInput-input__politik{
    padding-bottom: 7px;
    padding-top: 10px;
  }
`;

const SelectInput = styled(Select)``;

export function SimpleSelect(props: {
  title?: string,
  testData?: string,
  margin?: string,
  large?: boolean,
  className?: string,
  label?: string,
  value: string | number | undefined | boolean | null,
  disabled?: boolean,
  style?: CSSProperties,
  error?: boolean,
  onChange: Function,
  name?: string,
  id?: string,
  clearable?: boolean,
  placeholder?: string,
  options: $UI_SelectOption[],
  user?: $User,
  MenuProps?: {
    PaperProps?: { style?: { maxHeight?: string | number } },
  },
  menuAfterContent?: (option: $UI_SelectOption) => ReactElement | null
}) {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const style = props.style;

  React.useEffect(() => {
    setLabelWidth(get(inputLabel, "current.offsetWidth", 0));
  }, []);

  return (
    <FC
      variant='outlined'
      title={props.title}
      margin={props.margin}
      style={style}
      large={props.large}
      className={classes.formControl + " " + (props.className || "") + " select-input"}>
      <InputLabel
        ref={inputLabel}
        htmlFor='outlined-age-simple'>
        <span title={props.label} style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "inline-block",
          height: 18
        }}>{props.label}</span>
      </InputLabel>
      <SelectInput
        value={(props.value === undefined || props.value === null) ? emptyValue : props.value}
        disabled={props.disabled}
        data-test={props.testData || 'selector-input'}
        onChange={e => props.onChange({ ...e, target: { ...e.target, value: e.target.value === emptyValue ? null : e.target.value } })}
        error={props.error}
        labelWidth={labelWidth || (detectIE() ? 0.00001 : 0)}
        inputProps={{
          name: props.name,
          id: props.id,
        }}>
        {props.clearable && <MenuItem value={emptyValue}>
          <span style={{ color: COLORS.GRAY4 }}>{props.placeholder || trans("select_input.not_selected")}</span>
        </MenuItem>}
        {props.options
          .filter((o) =>
            get(o, "rolesRequired", []).every((r) =>
              props.user?.roles.includes(r)
            )
          )
          // eslint-disable-next-line no-unused-vars
          .map(({ rolesRequired, ...props }) => ({ ...props }))
          .map((o) => (
            <MenuItem key={o.key || o.value || o.label} {...o}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}>
                {o.label}
                {props.menuAfterContent && props.menuAfterContent(o)}
              </div>
            </MenuItem>
          ))}
      </SelectInput>
    </FC>
  );
}
