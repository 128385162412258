import React from "react";
import * as d3 from "d3";
import { COLORS } from "@constants";

const lineFunction = d3
  .line()
  .x(function(d) {
    return d.x;
  })
  .y(function(d) {
    return d.y;
  });
//   .interpolate("linear");

export class SuccessSVG extends React.Component<{ size: number, time: number, color?: string }> {
  constructor(props) {
    super(props);
    this.color = props.color || COLORS.SUCCESS;
    this.svg = React.createRef();
  }
  componentDidMount() {
    const color = this.color;
    var tau = 2 * Math.PI;
    const width = this.props.size || 100;
    const height = this.props.size || 100;
    const thickness = this.props.size / 10;
    const center = { x: 0, y: 0 };
    const line1Width = height / 4;
    const line2Width = 0.6 * height;
    const line1Angle = (-Math.PI * 3) / 4;
    const line2Angle = -Math.PI / 4;
    const animationStepDuration = (this.props.time || 0.5) * 1000 * 4 / 5;
    const line1Data = [
      {
        x: line1Width * Math.cos(line1Angle),
        y: line1Width * Math.sin(line1Angle)
      },
      center
    ];

    const line2Data = [
      center,
      {
        x: line2Width * Math.cos(line2Angle),
        y: line2Width * Math.sin(line2Angle)
      }
    ];
    var arc = d3
      .arc()
      .innerRadius(width / 2 - thickness)
      .outerRadius(width / 2)
      .cornerRadius(thickness)
      .startAngle(tau / 4);

    function arcTween(newAngle) {
      return function(d) {
        var interpolate = d3.interpolate(d.endAngle, newAngle);

        return function(t) {
          d.endAngle = interpolate(t);

          return arc(d);
        };
      };
    }

    const line1Tween = () => {
      return function() {
        var interpolate = d3.interpolate(0, line1Width);

        return function(t) {
          const width = interpolate(t);

          return lineFunction([
            {
              x: line1Width * Math.cos(line1Angle),
              y: line1Width * Math.sin(line1Angle)
            },
            {
              x:
                line1Width * Math.cos(line1Angle) -
                width * Math.cos(line1Angle),
              y:
                line1Width * Math.sin(line1Angle) - width * Math.sin(line1Angle)
            }
          ]);
        };
      };
    };

    const line2Tween = () => {
      return function() {
        var interpolate = d3.interpolate(0, line2Width);

        return function(t) {
          const width = interpolate(t);

          return lineFunction([
            center,
            {
              x: width * Math.cos(line2Angle),
              y: width * Math.sin(line2Angle)
            }
          ]);
        };
      };
    };

    const group = d3
      .select(this.svg.current)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet")
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

    group
      .append("path")
      .datum({ endAngle: (13 / 12) * tau })
      .style("fill", "#ddd")
      .attr("d", arc);

    const progress = group
      .append("path")
      .datum({ endAngle: 0 })
      .style("fill", color)
      .attr("d", arc);

    progress
      .transition()
      .duration(animationStepDuration)
      .attrTween("d", arcTween((13 / 12) * tau));

    const line1 = group
      .append("path")
      .data([[line1Data[0], line1Data[0]]])
      .attr("fill", "none")
      .attr("stroke", "transparent")
      .attr("stroke-width", thickness)
      .attr("stroke-linecap", "round")
      .attr("d", lineFunction)
      .attr("transform", "translate(0," + thickness + ")");

    line1
      .transition()
      .delay(animationStepDuration)
      .duration(animationStepDuration / 4)
      .attr("stroke", color)
      .attrTween("d", line1Tween());

    const line2 = group
      .append("path")
      .data([[line2Data[0], line2Data[0]]])
      .attr("fill", "none")
      .attr("stroke", "transparent")
      .attr("stroke-width", thickness)
      .attr("stroke-linecap", "round")
      .attr("d", lineFunction)
      .attr("transform", "translate(0," + thickness + ")");

    line2
      .transition()
      .delay(animationStepDuration)
      .duration(animationStepDuration / 4)
      .attr("stroke", color)
      .attrTween("d", line2Tween());
  }
  private color: string
  private svg: any

  render() {
    return <svg ref={this.svg} />;
  }
}
