import React from "react";
import ReactResizeDetector from "react-resize-detector";

export const sizeWrapper = <P extends $size>(Component: React.ComponentType<P>) => {
  return class withSize extends React.Component<any> {
    render() {
      return <ReactResizeDetector handleWidth={true} handleHeight={true}>
        {({ width, height }: $size["size"]) =>
          width !== undefined ? (
            <Component {...this.props as P} size={{ width, height }} />
          ) : (
            <div />
          )
        }
      </ReactResizeDetector>;
    }
  };
};