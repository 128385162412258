import React from "react";
import { Link } from "react-router-dom";
import { Routing } from '@backendRouter';
import styled from "styled-components";
import { COLORS, SPACINGS } from "@constants";
import { trans } from "@trans";

const Separator = styled.span`
  padding: 7px;
`;

const BreadCrumbContainer = styled.div`
  margin: 0px 0px ${SPACINGS.X5} 0px;
  font-size: 14px;
`;

const LinkDisabled = styled.span`
  color: ${COLORS.DISABLED}!important;
`;

export const BreadCrumb = ({ links = [] }: { links: ({ name?: string, url?: string })[] }) => (
  <BreadCrumbContainer>
    <Link to={Routing.generate("user_dashboard")}>
      {trans("ui.breadcrumb.home")}
    </Link>
    {links.map(
      (l) =>
        l.name && (
          <React.Fragment key={l.name}>
            <Separator>/</Separator>

            {l.url ? (
              <Link to={l.url}>{l.name}</Link>
            ) : (
              <LinkDisabled>{l.name}</LinkDisabled>
            )}
          </React.Fragment>
        )
    )}
  </BreadCrumbContainer>
);
