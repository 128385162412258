import React, { ComponentClass, FC, useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { MenuItem } from "@components/Shared/BaseUI";
import Icon from "@components/Shared/InventoryIcon";
import { COLORS } from "@constants";
import { styled } from "@material-ui/styles";

interface StyledMenuItemProps {
  disabled?: boolean;
}

const StyledMenuItem = styled(MenuItem)<StyledMenuItemProps>(({ disabled }) => ({
  ...(disabled &&
    {
      color: COLORS.DISABLED,
      cursor: 'not-allowed'
    }),
}));

type $MenuOption = {
  func?: Function;
  Comp?: ComponentClass<any> | FC<any>;
  text?: string;
  disabled?: boolean;
  show?: boolean;
};
interface SimpleMenuProps {
  text?: string;
  icon?: string;
  options: $MenuOption[];
  ButtonOptional?: ComponentClass<any> | FC<any>;
}

export function SimpleMenu({
  text,
  options = [],
  ButtonOptional,
  icon,
}: SimpleMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const Btn = ButtonOptional || Button;

  return (
    <>
      {(text || ButtonOptional) && (
        <Btn
          aria-controls='simple-menu'
          aria-haspopup='true'
          onClick={handleClick}>
          {text}
        </Btn>
      )}
      {icon && (
        <Icon
          icon={icon}
          color={COLORS.GRAY2}
          style={{ fontSize: 16 }}
          onClick={handleClick}/>
      )}
      {Boolean(anchorEl) && (
        <Menu
          id='simple-menu'
          anchorEl={anchorEl}
          keepMounted={true}
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          {Boolean(anchorEl) &&
            options.map((option, i) => (
              <StyledMenuItem
                key={i}
                disabled={option.disabled}
                onClick={() => {
                  handleClose();

                  if (option.func) {
                    option.func();
                  }
                }}>
                {option.Comp ? <option.Comp /> : option.text}
              </StyledMenuItem>
            ))}
        </Menu>
      )}
    </>
  );
}
