import { UPDATE_LANGUAGE } from './actionTypes';

import initialState from './initialState';

import Translator from "@translator";

const reducer = (state = initialState, action): $TranslationsReducer => {
  switch(action.type) {
  case UPDATE_LANGUAGE: {
    Translator.locale = action.payload;

    return {...state, languageKey: action.payload};
  }

  default: {
    return {...state};
  }
  }
};
    
export default reducer;
