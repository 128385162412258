import React from "react";
import get from "lodash/get";
import { Party } from "./Party";
import { Routing } from '@backendRouter';
import { isNull } from "is-what";
import { A } from "./BaseUI";
import { trans } from "@trans";
import { getAffairCantonSlug, isParty, isFaction } from '@helpers';
import { SEARCH_TYPES, COLORS } from '@constants';

const {
  NATIONAL_COUNCILLOR,
  CANTONAL_COUNCILLOR,
  NATIONAL_COMMITTEE,
  CANTONAL_PARTY_MEMBERSHIP,
  CANTONAL_COMMITTEE,
  CANTONAL_MISC_AUTHOR,
  NATIONAL_AFFAIR_CONSULTATION_AUHTOR,
  CANTONAL_AFFAIR_CONSULTATION_AUTHOR,
  NATIONAL_COUNCIL,
  DEPARTMENT,
  NATIONAL_PARTY_COUNCILLOR_MEMBERSHIP,
  AUTHOR_COMMITTEE,
  AUTHOR_MISC,
  USER,
  DISTRICT_CANTON
} = SEARCH_TYPES;

const defaultStyling = (backgroundColor, large = false) => {
  return {
    backgroundColor,
    padding: '3px 11px',
    color: 'white',
    borderRadius: '4px',
    fontSize: '10px',
    lineHeight: '12px',
    width: '100%',
    display: 'inline-block',
    maxWidth: large ? "250px" : '100px',
    textAlign: 'center' as 'center',
  };
};

type $Props = {
  author: $Author;
  affair?: $Affair | $Event['xPltkParent'];
  className?: string;
}

export function Author({ author, affair, className = "" }: $Props) {
  const type = ("categories" in author && author.categories) ? author.categories.split(',')[0] : author["@type"];

  if (!author || isNull(author)) {
    return null;
  }

  if (
    isParty(author) || isFaction(author)
  ) {
    return <Party className={className} party={author} canton={affair && "canton" in affair ? affair.canton : undefined} />;
  }

  if (type === NATIONAL_COMMITTEE) {
    return (
      <A
        className={`${get(author, "council") || ("url" in author && author.url) || "" } ${className}`}
        style={defaultStyling(COLORS.GRAY2)}
        to={Routing.generate("show_committees", {
          councilSlug: get(author,'abbr')?.slice(-2).toLowerCase() ||
          (("council" in author && (author.council?.abbr || author.council?.slug) || "")
          ).toLowerCase(),
          committeeSlug: get(author, "slug") || get(author, "abbr"),
        })}>
        <span>
          {get(author, "abbr")}
        </span>
      </A>
    );
  }

  if (type === NATIONAL_COUNCILLOR) {
    let partyAbbr;
    const memberships = get(author, "memberships");

    if (memberships) {
      for (let i = 0; i < memberships.length; i++) {
        if (memberships[i]["@type"] === NATIONAL_PARTY_COUNCILLOR_MEMBERSHIP) {
          partyAbbr = memberships[i].abbr;
          break;
        }
      }
    }
    else {
      partyAbbr = get(author, 'party.abbr');
    }

    return (
      <A
        to={Routing.generate("show_councillors", {
          slug: get(author, "slug")
        })}
        className={partyAbbr
          ? `party-label  ${partyAbbr.toLowerCase()} ${className}`
          : `party-label  ${className}`}
        title={partyAbbr || trans(`author.${author["@type"]}`)}>
        {get(author, "fullName") || `${get(author, "name")}`}
      </A>
    );
  }

  if (type === CANTONAL_COUNCILLOR) {
    let partyAbbr;
    const memberships = get(author, "memberships");

    if (memberships) {
      for (let i = 0; i < memberships.length; i++) {
        if (memberships[i]["@type"] === CANTONAL_PARTY_MEMBERSHIP) {
          partyAbbr = memberships[i].abbr;
          break;
        }
      }
    }
    else {
      partyAbbr = get(author, 'party.abbr');
    }

    return (
      <A
        to={Routing.generate("cantonal_show_councillor", {
          cantonSlug: getAffairCantonSlug(affair),
          councillorSlug: get(author, "slug"),
        })}
        className={partyAbbr
          ? `party-label text-nowrap ${partyAbbr.toLowerCase()} ${className}`
          : `${className} party-label no-party`}
        title={partyAbbr || trans(`author.${author["@type"]}`)}>
        {get(author, "fullName") || `${get(author, "name")}`}
      </A>
    );
  }

  if (type === CANTONAL_COMMITTEE || get(author, "type") === AUTHOR_COMMITTEE) {
    const authorName = get(author, "name");
    let normalizedName = '';
    
    if (authorName.includes('Commission') || authorName.includes('Komission')) {
      normalizedName =  authorName;
    }
    else {
      normalizedName = `${trans("author.commission.label")} ${authorName}`;
    }

    return (
      <A
        className={className}
        to={Routing.generate("cantonal_show_committee", {
          cantonSlug: getAffairCantonSlug(affair),
          committeeSlug: get(author, "slug"),
        })}>
        {normalizedName}
      </A>
    );
  }

  if (type === CANTONAL_MISC_AUTHOR || get(author, "type") === AUTHOR_MISC) {
    return (
      <A
        className={className}
        style={defaultStyling(COLORS.PRIMARY)}
        to={Routing.generate("cantonal_show_miscauthor", {
          cantonSlug: getAffairCantonSlug(affair),
          authorSlug: get(author, "slug"),
        })}>
        <span>
          {get(author, "name", "") || get(author, "fullName")}
        </span>
      </A>
    );
  }

  if (
    get(author, "type", type) === NATIONAL_AFFAIR_CONSULTATION_AUHTOR ||
    type === CANTONAL_AFFAIR_CONSULTATION_AUTHOR
  ) {
    return (
      <span style={defaultStyling(COLORS.PRIMARY, true)}>
        {get(author, "name") || get(author, "fullname")}
      </span>
    );
  }

  if (type === NATIONAL_COUNCIL) {
    return (
      <span style={defaultStyling(COLORS.PRIMARY)}>
        {trans(`councils.${get(author, "abbrDe", "").toLowerCase()}`)}
      </span>
    );
  }

  if (type === DEPARTMENT) {
    return (
      <span style={defaultStyling(COLORS.PRIMARY)}>
        {get(author, "abbr")}
      </span>
    );
  }

  if (type === USER) {
    return (
      <span style={defaultStyling(COLORS.PRIMARY)}>
        {get(author, "name")}
      </span>
    );
  }
  
  if (type === DISTRICT_CANTON) {
    return <img
      src={"logoPath" in author ? author.logoPath : ""}
      className='mr-1'
      style={{ maxHeight: "1.5em" }}
      alt={"abbreviation" in author && author.abbreviation || ""}
      title={"title" in author && author.title || ""} />;
  }


  return null;
}
