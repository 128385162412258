import React, { FC } from "react";
import styled from "styled-components";
import { PRIMARY_COLORS, GREYTONES, SPACINGS } from "@constants";

const Tab = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: ${(props) => (props.isActive ? GREYTONES.BLACK : GREYTONES.GREY_DARK)};
  position: relative;
  margin-right: ${SPACINGS.X3};
  margin-bottom: ${SPACINGS.X5};
  cursor: ${(props) => (props.isActive ? 'initial' : 'pointer')};
  :before {
    position: absolute;
    content: "";
    bottom: -4px;
    width: 100%;
    height: 2px;
    left: 0px;
    cursor: ${(props) => (props.isActive ? 'initial' : 'pointer')};
    background-color: ${(props) => (props.isActive ? PRIMARY_COLORS.TURQUOISE : GREYTONES.GREY_MEDIUM)};
  }
  &:hover {
    color: ${GREYTONES.BLACK};
    :before {
      background-color: ${(props) => (props.isActive ? PRIMARY_COLORS.TURQUOISE : GREYTONES.GREY_DARK)};
    }
  }
`;

export const Tabs: FC<$TabsProps> = ({tabs, activeTab, setActiveTab}) => {

  return (
    <div style={{display: 'flex'}}>
      {tabs.map((tab, i) => 
        <Tab
          data-test={`settings-tab-${i}`}
          key={tab.type}
          isActive={tab.type === activeTab}
          onClick={() => setActiveTab(tab.type)}>
          {tab.label}
        </Tab>)
      }
    </div>
  );
};