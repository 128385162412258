import React from "react";
import styled from "styled-components";
import Icon from '@components/Shared/InventoryIcon';
import { trans } from "@trans";
import { COLORS } from '@constants';

const Container = styled.div`
  cursor: pointer;
  display: inline;
  height: 2em;
`;

const Row = styled.div`
    height: 1em;
    display: flex;
    font-size: 1em;
    line-height:100%;
    white-space: nowrap;
`;

type $Props = {
  sortDirection?: $Sort,
  sortFunc: ($Sort) => void,
  tableHead?: boolean
}
 
export const SortingBox: React.FC<$Props> = ({
  sortDirection,
  sortFunc,
  tableHead
}) => (
  <Container
    data-test='sorting-box'
    title={trans(`ui.table.sort.asc.${sortDirection || "desc"}`)}
    onClick={() => sortFunc(sortDirection === "asc" ? "desc" : "asc")}>
    <Row
      data-test='sorting-box-asc'
      style={{
        color: sortDirection === "asc" ? COLORS.PRIMARY : 'gray',
      }}>
      {tableHead || <Icon icon='sort.bars' />}
      <Icon icon='sort.up' />
    </Row>
    <Row
      data-test='sorting-box-desc'
      style={{
        color: sortDirection === "desc" ?  COLORS.PRIMARY : 'gray',
      }}>
      {tableHead || <Icon icon='sort.bars' />}
      <Icon icon='sort.down' />
    </Row>
  </Container>
);
