import React, { forwardRef } from "react";
import styled from "styled-components";
import TextInput from '@components/Shared/ui/TextInput';

const TextFieldWithoutBorder = styled(TextInput)`
  background: transparent;
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

type $Props = {
  suggestion: string,
  onChange: Function,
  onKeyUp?: Function,
  onClick?: Function,
  value: string,
  InputProps,
  placeholder?: string,
  textFieldWithoutBorderClasses?: string;
}
export const SuggestionField = forwardRef((props: $Props, ref) => {
  // We need this class because the datepicker uses TextInput and shouldn't
  // have a width of 100%, so we need to exclude it in the CSS rule by targeting them
  const {textFieldWithoutBorderClasses = "" } = props;

  return (
    <div className='position=relative'>
      <TextFieldWithoutBorder
        className={`position-absolute ${textFieldWithoutBorderClasses}`}
        placeholder={props.suggestion || ''} />
      <TextInput
        {...props}
        ref={ref}
        style={{ background: "transparent" }}
        onChange={props.onChange}
        onKeyUp={props.onKeyUp}
        value={props.value}
        onClick={props.onClick}
        InputProps={props.InputProps}
        placeholder={props.suggestion ? "" : props.placeholder} />
    </div>
  );
});
