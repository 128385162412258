import { FETCH_GROUP_TREE } from "@redux/actionTypes";
import { Reducer } from "redux";


const defaultReducer = { data: [], loading: false };

const groupTreeReducer: Reducer<$Reducer_groupTree, any> = (state = defaultReducer, action) => {
  switch (action.type) {
  case FETCH_GROUP_TREE:
    return action.payload;
  default:
    return state;
  }
};

export default groupTreeReducer;