import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import get from "lodash/get";
import moment from "moment";
import store from "@redux/store";
import App from "./App";
import { configHelpScout, initHelpScout, identifyHelpScout } from './js/script/supportHelpScout.js';

const root = document.getElementById("root");
const env = get(root, "dataset.googleanalyticsenviroment");
const user = get(store.getState(), 'user');

ReactGA.initialize(
  JSON.parse(env) === "prod" ? "UA-81491312-5" : "UA-81491312-7",
  {
    gaOptions: {
      userId: String(get(store.getState(), "user.id")),
      siteSpeedSampleRate: 50,
      sampleRate: 50,
    },
  }
);


const lang = document.documentElement.lang;

moment.locale(`${lang}-ch`);

if (user.data) {
  initHelpScout();
  identifyHelpScout(user.data);
  configHelpScout();
}

if (root) {
  ReactDOM.render(<App env={env} />, root);
}
