import React, { Component, CSSProperties } from "react";
import { connect } from "react-redux";
import {IntegrationReactSelect} from "./MaterialMultipleSelect";
import { trans } from "@trans";

type $Props = {
  onChange: Function;
  onTypeText?: Function;
  value: string | (string | number)[] | undefined;
  placeholder?: string;
  label?: string;
  options: $UI_SelectOption[];
  isFiltering?: boolean;
  user?: $User;
  config?: $MultipleSelectConfig,
  className?: string,
  iri?: boolean,
  MenuProps?: { PaperProps?: { style?: { maxHeight?: string | number } } },
  disabled?: boolean,
  style?: CSSProperties,
  error?: boolean,
  isClearable?: boolean,
  draftsSelected?: number[],
  dataTest?: string,
  loading?: boolean,
  multi?: boolean,
  name?: string
}

class MultipleSelect extends Component<$Props> {
  render() {

    return (
      <IntegrationReactSelect
        {...this.props}
        dataTest={this.props.dataTest}
        onChange={this.props.onChange}
        isFiltering={this.props.isFiltering}
        value={this.props.value}
        placeholder={this.props.placeholder || trans('ui.select_multiple.placeholder')}
        label={this.props.label}
        options={this.props.options.filter((o) =>
          o.rolesRequired ? o.rolesRequired.every((r) =>
            this.props.user && (this.props.user.roles || []).includes(r)
          ) : true
        )} />
    );
  }
}

function mapStateToProps(state) {
  return { user: state.user.data };
}

export default connect(mapStateToProps, null)(MultipleSelect);
