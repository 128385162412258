import React, { useRef, useEffect } from "react";

// Hook that alerts clicks outside of the passed ref
function useOutsideAlerter(ref, callback) {
  useEffect(() => {
    // Alert if clicked on outside of element
    function handleClickOutside(event) {
      const popoverShown = document.querySelector(".MuiPopover-root");

      if (!popoverShown && ref.current && !ref.current.contains(event.target)) {
        if (callback) {
          callback();
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

// Component that alerts if you click outside of it
export function ClickAwayListener(props) {
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, props.onClickAway);

  return <div ref={wrapperRef}>{props.children}</div>;
}
