export const STATISTICS_TYPES = {
  ALL: 'all' as const,
  AGAINST: 'against' as const,
  LANGUAGE: 'language' as const,
  GENDER: 'gender' as const,
  FACTION: 'faction' as const,
  CANTON: 'canton' as const,
};

export const STATUSES = {
  IN_PROGRESS: 'in_progress',
};
