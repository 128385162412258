import React from "react";
import styled from "styled-components";
import { Spinner } from "./Spinner";
import MaterialUIIconButton from "@material-ui/core/IconButton";
import Icon from './InventoryIcon';
import { COLORS } from "@constants";

const ColoredIcon = styled(Icon)`
  width: 1em;
  height: 1em;
  position: relative;
  padding: 0px;
  color: ${(props) => props.color || "#ffff"};
  &::before {
    position: absolute;
    left 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    color: ${(props) => props.color || "#ffff"};
  }
`;

type $Props = {
  icon: string,
  title?: string,
  onClick?: ((event: any) => void) | (() => void),
  disabled?: boolean,
  loading?: boolean,
  color?: string,
  add?: boolean,
  style?: object,
  size?: "small" | "medium",
  variant?: "contained"
}
export const IconButton = ({
  icon,
  title,
  onClick,
  disabled = false,
  loading = false,
  color,
  add,
  style = {},
  size,
  ...props
}: $Props) =>
  (
    <MaterialUIIconButton
      disabled={disabled}
      className='position-relative'
      title={title}
      style={style}
      size={size}
      data-test={props["data-test"]}
      onClick={onClick}>
      {icon && (
        <ColoredIcon
          style={{ color }}
          color={disabled ? COLORS.PRIMARY : color || COLORS.INTERACTIVE}
          icon={icon} />
      )}
      {loading && (
        <Spinner
          style={{
            position: "absolute",
            height: "15px",
            top: 0,
            right: -14,
          }} />
      )}
      {add && (
        <div
          className='d-flex position-absolute'
          style={{
            background: "white",
            borderRadius: "50%",
            fontSize: "0.5em",
            top: 11,
            right: 10,
            color: disabled ? COLORS.GRAY3 : color || COLORS.PRIMARY,
          }}>
          <Icon icon='ui.add2' />
        </div>
      )}
    </MaterialUIIconButton>
  );
