import React, { PureComponent } from "react";
import { getNotifications } from "@api/notifications";
import { ShowDate } from "./List";
import { Scrollbars } from "react-custom-scrollbars";
import { getEventReminders } from "@api/events";
import {
  setNotificationSeen,
  setAllNotificationsSeen
} from "@api/notifications";
import { showDangerAlert, IconButton } from "../Shared";
import "custom-event-polyfill";
import { COLORS } from "@constants";
import { A } from "../Shared/BaseUI";
import { trans } from "@trans";

const List = props =>
  props.data.map((n, i) => (
    <div
      className={`list-item ${n.seen ? "" : "list-item__not-seen"}`}
      key={n.id}
      id={`notification_dropdown__list__item${i}`}>
      <h5 className='mb-0'>
        {props.legacy ? (
          <a
            className='btn btn-link m-0'
            onClick={props.close}
            href={`/profile/notifications?index=${i}`}>
            <span>{trans(n.subject, {}, "email")}</span>
            <span className='d-flex align-items-end'>
              {n.createdAt && <ShowDate time={n.createdAt} />}
            </span>
          </a>
        ) : (
          <A
            className='btn btn-link m-0'
            onClick={props.close}
            to={`/profile/notifications?index=${i}`}>
            <span>{trans(n.subject, {}, "email")}</span>
            <span className='d-flex align-items-end'>
              {n.createdAt && <ShowDate time={n.createdAt} />}
            </span>
          </A>
        )}
      </h5>
    </div>
  ));

type $Props = { legacy?: boolean };
type $State = {
  notifications: ({ id: number | string, sent: boolean, seen: boolean, subject: string, notificationItems: ({ "@id": string, object: unknown })[] })[],
  reminders: ({ "@id": string, object: unknown })[],
  pages: number,
  fetchedAll: boolean,
  fetchingNow: boolean,
  open: boolean,
  notificationsSetToSeen: boolean
};

export class NotificationsDropdown extends PureComponent<$Props, $State> {
  constructor(props) {
    super(props);
    this.dropdownBody = React.createRef();
    this.state = {
      notifications: [],
      reminders: [],
      pages: 0,
      fetchedAll: false,
      fetchingNow: false,
      open: false,
      notificationsSetToSeen: false
    };
  }

  componentDidMount() {
    this.fetchMore();
    this.fetchMoreReminders();
    document.addEventListener("notificationSeen", (e: any) => {
      this.setState(state => ({
        notifications: state.notifications.map(n =>
          e.detail === "all" || n.id === e.detail ? { ...n, seen: true } : n
        )
      }));
    });
  }

  private dropdownBody: React.RefObject<HTMLDivElement>;

  closeWhenClickedOutside = (e?) => {
    if (this.dropdownBody && !this.dropdownBody.current?.contains(e.target)) {
      document.removeEventListener("click", this.closeWhenClickedOutside);
      this.setState({ open: false });
    }
  };

  setNotificationSeen = id => {
    setNotificationSeen(id)
      .then(d => {
        const newList = this.state.notifications.map(n =>
          n.id === id ? d.data : n
        );

        this.setState({ notifications: newList });
        document.dispatchEvent(
          new CustomEvent("notificationSeen", { detail: id })
        );
      })
      .catch(() =>
        showDangerAlert(trans("notifications.error.set_seen"))
      );
  };

  setNotificationSent = index =>
    this.setState(state => ({
      notifications: state.notifications
        .slice(0, index)
        .concat({ ...state.notifications[index], sent: true })
        .concat(state.notifications.slice(index + 1))
    }));

  markRead = (n, i) => {
    if (!n.sent && !n.seen) {
      this.setNotificationSent(i);
      this.setNotificationSeen(n.id);
    }
  };

  markAllReadLocal = () => {
    const newList = this.state.notifications.map(n => ({
      ...n,
      seen: true
    }));

    this.setState({
      notifications: newList,
      notificationsSetToSeen: true
    });
  }

  markAllRead = () => {
    this.markAllReadLocal();

    setAllNotificationsSeen()
      .then(() => {
        document.dispatchEvent(
          new CustomEvent("notificationSeen", { detail: "all" })
        );
      })
      .catch(() => showDangerAlert(trans("api.notifications.mark_all_read.error")));
  }

  fetchMore = () => {
    this.setState({ fetchingNow: true });
    const pages = this.state.pages + 1;

    getNotifications(pages)
      .then(({ data }) => {
        const sortedNotifcations = data["hydra:member"];

        if (sortedNotifcations.length === 0) {
          return this.setState({ fetchedAll: true, fetchingNow: false });
        }

        this.setState({
          notifications: (this.state.notifications || []).concat(
            sortedNotifcations
          ),
          pages,
          fetchingNow: false
        });
      })
      .catch(() =>
        showDangerAlert(trans("notifications.error.get"))
      );
  };

  fetchMoreReminders = () => {
    getEventReminders()
      .then(({ data }) => {
        if (data["hydra:member"].length === 0) {
          return this.setState({
            reminders: data["hydra:member"]
          });
        }
      })
      .catch(() =>
        showDangerAlert(trans("event.reminders.error.get"))
      );
  };

  toggle = () => {
    const isOpen = this.state.open;

    this.setState({ open: !isOpen });

    setTimeout(() => {
      if (!isOpen) {
        document.addEventListener("click", this.closeWhenClickedOutside);
  
        if(!this.state.notificationsSetToSeen) {
          this.markAllRead();
        }
      }
      else {
        this.closeWhenClickedOutside();
      }
    });
  };

  getUnseenCount = () => this.state.notifications.filter(n => !n.seen).length;

  render() {
    const { notifications } = this.state;
    const unseenCount = this.getUnseenCount();
    const notificationUpdating = Boolean(notifications.find(n => n.sent));
    const notReadExists = Boolean(notifications.find(n => n.seen === false));
    const listData = [...this.state.notifications, {
      notificationItems: this.state.reminders.map(object => ({
        object,
        id: object["@id"]
      })),
      seen: true,
      id: "reminders",
      subject: trans("event.reminders.title")
    }];

    return (
      <li className='nav-item dropdown' id='notifications-dropdown'>
        <a
          className='nav-link'
          onClick={this.toggle}
          id='dropdownMenuButton'
          style={{ fontSize: "30px", zIndex: 2 }}
          title={trans("profile.notifications")}
          aria-expanded={this.state.open}>
          <IconButton
            loading={this.state.fetchingNow || notificationUpdating}
            icon='subscriptions.button.active'
            style={{ color: COLORS.PRIMARY }} />
          {Boolean(unseenCount) && (
            <div className='notification'>{unseenCount}</div>
          )}
        </a>
        <div
          className='position-absolute'
          style={{ right: 0, top: 0, zIndex: 300 }}>
          {this.state.open && (
            <div style={{ width: "450px", height: "300px" }}>
              <Scrollbars
                autoHide={true}
                className='notifications-dropdown-menu'
                aria-labelledby='dropdownMenuButton'>
                <div 
                  ref={this.dropdownBody}>
                  {notReadExists && (
                    <a
                      className='mark-all-notifications-read text-right mr-1'
                      href='#'
                      onClick={this.markAllRead}>
                      {trans("profile.notifications.markAllRead")}
                    </a>
                  )}
                  {!this.state.fetchingNow && notifications.length === 0 ? (
                    <div className='card'>
                      <div className={`card-header card-header-info`}>
                        <h5 className='mb-0'>
                          <a className='btn btn-link' href='#'>
                            {trans("profile.notifications.empty")}
                          </a>
                        </h5>
                      </div>
                    </div>
                  ) : (
                    <List
                      data={listData}
                      markRead={this.markRead}
                      legacy={this.props.legacy}
                      close={() => this.setState({ open: false })} />
                  )}
                </div>
              </Scrollbars>
            </div>
          )}
        </div>
        <style>{`
           .notifications-dropdown-menu {
             background: ${COLORS.PRIMARY};
             border-radius: 5px;
             border: 1px solid ${COLORS.INTERACTIVE};
             width: max-content;
             max-width: 50vw;
             max-height: 300px;
             font-size: 11px;
             position:absolute;
             top: 50px;
             right: 0px;
             z-index: 100;
             overflow: auto;
          }

          #notifications-dropdown .list-item .btn-link{
            color: #fff;
          }

          #notifications-dropdown .list-item:not(:last-child) {
            border-bottom: 1px solid ${COLORS.INTERACTIVE}
          }

          #notifications-dropdown .list-item:hover .btn-link{
            background: ${COLORS.ACTIVE};
          }

          #notifications-dropdown .badge {
            display: none;
          }

          #notifications-dropdown .btn.btn-link {
            width: 100%;
            text-align: left;
            display: flex;
            justify-content: space-between;
            padding: 5px;
          }

          #notifications-dropdown .nav-link {
            font-size: 20px;    
            cursor: pointer;     
          }

          #notifications-dropdown .notifications-collapse {
            font-size: 12px;
          }

          #notifications-dropdown .notification {
            top: 10px;
            right: 19px;
          }

          #notifications-dropdown .spinner{
            position: absolute;
            width: 12px;
            top: 12px;
            left: 28px;
          }

          #notifications-dropdown .profile-notifications__time{
            display: none;
          } 

          #notifications-dropdown .mark-all-notifications-read{
            display: flex;
            justify-content: flex-end;
          }

          #notifications-dropdown .list-item__not-seen {
            background: ${COLORS.INTERACTIVE};
          }

          `}</style>
      </li>
    );
  }
}
