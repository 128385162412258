import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import FormControl from "@material-ui/core/FormControl";
import { last, get } from "lodash";
import moment from "moment";

import InputAdornment from "@material-ui/core/InputAdornment";

import { SimpleSelect, TextInput, SuggestionField } from "../../ui";
import { Button, IconButton, ButtonLight, Header } from "../../BaseUI";

import { COLORS } from "@constants";
import { trans } from "@trans";
import {
  getDate,
  capitalize,
  compareArrays,
  getNumbersFromDate,
  isISOString,
} from '@helpers';
import InventoryIcon from "../../InventoryIcon";

const lang = document.documentElement.lang;

moment.locale(`${lang}-ch`);

const expandTo4Digit = (number, expander: number | string = "0") => {
  return Number(
    `${String(number)}${expander}${expander}${expander}`.slice(0, 4)
  );
};

const getDateFromNumbers = (numbers) =>
  moment()
    .year(numbers[2])
    .month(numbers[1] - 1)
    .date(numbers[0])
    .hour(0)
    .minute(0)
    .second(0);

const getDateFromAnyType = (date) => {
  if (!date) {
    return moment();
  }

  const numbers = getNumbersFromDate(date);

  return numbers.length === 3
    ? moment()
      .year(numbers[2])
      .month(numbers[1] - 1)
      .date(numbers[0])
      .hour(0)
      .minute(0)
      .second(0)
    : moment();
};

const isValidDate = (date) => {
  if (typeof date !== "string") {
    return false;
  }

  const numbers = getNumbersFromDate(date);

  return (
    numbers.length === 3 &&
    numbers.find((n) => !n) === undefined &&
    String(numbers[2]).length === 4
  );
};

const shouldAddDotToValue = (value, selected, formattedSuggestion) => {
  const isValidDate = formattedSuggestion.indexOf(value) === 0;
  const numbers = getNumbersFromDate(value);

  return (
    last(value) !== "." &&
    isValidDate &&
    (value.length === 2 || (numbers.length === 2 && numbers[1] > 1)) &&
    value.length > selected.length
  );
};

//main function for checking validity of inputted date, returning suggested date, returning null if input is not complete or inputting false if input is wrong
//used to validate the value of input and showing suggestion as well
const suggestion = (str, config) => {
  // returns suggested date or false if given numbers are not valid or null if numbers are incomplete
  const numbersAfter =
    Boolean(get(config, "after", false)) && getNumbersFromDate(config.after);
  const numbersBefore =
    Boolean(get(config, "before", false)) && getNumbersFromDate(config.before);
  const zerosInputted =
    getNumbersFromDate(str, { ...config, string: true }).find(
      (n) => n[0] === "0"
    ) !== undefined;

  const numbers: [number, number, number] = getNumbersFromDate(str, config).map(Number);
  const numbersAreBefore =
    numbersBefore && numbers.length === 3
      ? getDateFromNumbers(
        numbers.map((n, i) => (i === 2 ? expandTo4Digit(n, 0) : n))
      ).valueOf() <= getDateFromAnyType(config.before).valueOf()
      : true;
  const numbersAreAfter =
    numbersAfter && numbers.length === 3
      ? getDateFromNumbers(
        numbers.map((n, i) => (i === 2 ? expandTo4Digit(n || 9, 9) : n))
      ).valueOf() >= getDateFromAnyType(config.after).valueOf()
      : true;
  const numbersInIntervalGiven = numbersAreBefore && numbersAreAfter;
  const areNumbersValid =
    numbersInIntervalGiven &&
    numbers.find(isNaN) === undefined &&
    !zerosInputted;

  const dateFromNumbers =
    numbers.length === 3 &&
    moment()
      .date(numbers[0])
      .month(numbers[1] - 1)
      .year(
        Number(String(numbers[2]) +
          String(moment().year()).slice(String(numbers[2]).length))
      )
      .format("D.M.YYYY");
  const newNumbers = dateFromNumbers && getNumbersFromDate(dateFromNumbers);

  const numbersWrong =
    !areNumbersValid ||
    numbers.length > 3 ||
    (newNumbers &&
      !compareArrays(numbers.slice(0, -1), newNumbers.slice(0, -1)));

  const dateDoesNotExist = numbers[0] > 31 || numbers[1] > 12 || numbersWrong;

  const notSuggestable =
    (numbers[0] === 0 || (numbers.length > 2 && numbers[1] === 0)) &&
    !zerosInputted;

  if (notSuggestable) {
    return null;
  }

  if (dateDoesNotExist) {
    return false;
  }

  const date = getDateFromAnyType(config.before || config.after);

  // setting day number
  if (numbers[0]) {
    date.date(numbers[0]);
  }
  else if (numbersBefore) {
    date.date(numbersBefore[0]);
  }
  else if (numbersAfter) {
    date.date(numbersAfter[0]);
  }

  //setting month number
  if (numbers[1]) {
    //when month number is given

    date.month(numbers[1] - 1);
  }
  else if (numbersBefore) {
    //if year is not inputted, or inputted and equal to before date, and inputted day is higher than day before we should increase month
    date.month(
      (numbers[2] ? numbers[2] === numbersBefore[2] : true) &&
        numbers[0] > numbersBefore[0]
        ? numbersBefore[1] - 1
        : numbersBefore[1] - 2
    );
  }
  else if (numbersAfter) {
    //if year is not inputted, or inputted and equal to after date, and inputted day is lower than day after we should increase month

    const newMonthNumber =
      (numbers[2] ? numbers[2] === numbersAfter[2] : true) &&
        numbers[0] < numbersAfter[0]
        ? numbersAfter[1]
        : numbersAfter[1] - 1;

    date.month(newMonthNumber % 12);
  }

  //setting year number
  if (numbers[2]) {
    const len = String(numbers[2]).length;
    const shouldIncreaseYear =
      numbersAfter &&
      (date.month() < numbersAfter[1] ||
        (date.month() === numbersAfter[1] && date.date() < numbersAfter[0]));

    const shouldDecreaseYear =
      numbersBefore &&
      (date.month() > numbersBefore[1] ||
        (date.month() === numbersBefore[1] && date.date() > numbersBefore[0]));
    const year = shouldIncreaseYear
      ? numbersAfter[2] + 1
      : shouldDecreaseYear
        ? numbersBefore[2] - 1
        : String(numbers[2]) + String(date.year()).slice(len);

    date.year(Number(year));
  }
  else if (numbersBefore) {
    const shouldSubtractYear =
      numbersBefore[1] < date.month() ||
      (numbersBefore[1] === date.month() && numbersBefore[0] < date.date());
    const year = shouldSubtractYear ? numbersBefore[2] - 1 : numbersBefore[2];

    date.year(Number(year));
  }
  else if (numbersAfter) {
    const shouldAddYear =
      numbersAfter[1] > date.month() ||
      (numbersAfter[1] === date.month() && numbersAfter[0] > date.date());
    const year = shouldAddYear ? numbersAfter[2] + 1 : numbersAfter[2];

    date.year(Number(year));
  }

  if (date.daysInMonth() < numbers[0]) {
    // month should be changed if number of days is too high for it
    const newMonthNumber = date.month() + get(config, "before") ? -1 : 1;

    date.month(newMonthNumber % 12);

    if (newMonthNumber === -1) {
      date.year(date.year() - 1);
    }

  }

  return date.toISOString();
};

const formatDate = (date) =>
  isISOString(date) ? moment(date).format("D.M.YYYY") : "";

const getDaysNumber = (year, month) =>
  moment(`${year}-${month}`, "YYYY-MM").daysInMonth();
const getDateString = (year, month, day) =>
  moment()
    .year(year)
    .month(month - 1)
    .date(day)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .toISOString();

const StyledTimeInput = styled(TextInput)`
  text-align: center;
  width: 100px;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
const TextFieldWithSuggestion = styled(SuggestionField)`
  & .MuiInputBase-input {
    width 135px;
  }
  & .MuiInputBase-root {
    width: 155px;
  }
  & .MuiInputBase-input.MuiOutlinedInput-input {
    width 135px;
  }
`;
const ButtonSmall = styled(ButtonLight)`
  font-size: 12px;
  color: inherit;
  margin-right: 1px;
`;
const Icon = styled(IconButton)`
  padding: 3px;
  color: ${COLORS.GRAY3};
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  ${(props) => (props.disabled ? "pointer-events: none;" : "")}
  ${(props) => (props.disabled ? "filter: brightness(1.3);" : "")}
    &:hover {
    color: ${COLORS.GRAY1};
    background: none;
    filter: blur(1px);
  }
`;
const InputContainer = styled.div`
  display: inline-flex;
  position: relative;
  flex-wrap: nowrap;
  border-radius: 4px;
  ${(props) =>
    props.disabled ? "pointer-events:none;filter:grayscale(1);" : ""}

  transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  &::after {
    content: "";
    pointer-events: none;
    border-radius: 4px;
    z-index: -1;
    width: 100%;
    height: 100%;
    border: 1px solid
      ${(props) =>
    props.focus && !props.disabled ? "rgb(63, 51, 86)" : "#AAA"};
    position: absolute;
    top: 0px;
    left: 0px;
  }
`;
const InputSeparator = styled.div`
  border-right: 1px #aaa solid;
  margin: 10px 1px;
  z-index: -1;
`;
const SelectWithoutBorder = styled(SimpleSelect)`
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & label {
    font-size: 12px;
  }
`;
const CalendarContainer = styled.div`
  position: absolute;
  display: flex;
  z-index: 100;
  background: #fff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 15px;
  border: 1px solid ${COLORS.TABLE};
  left: ${(props) => props.offsetLeft}px;
  flex-direction: column;
`;

const MonthsContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

const CalendarOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 13px;
  padding: 15px;
  border-bottom: 1px solid ${COLORS.TABLE};
`;

const MonthTable = styled.div`
  margin: 0px 10px;
  color: ${COLORS.GRAY2};
`;

const MonthTableCaption = styled.span`
  display: block;
  color: black;
  font-weight: 600;
  font-size: large;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 10px;
  & span {
    display: flex;
    justify-content: ${(props) => (props.first ? "flex-start" : "flex-end")};
    align-items: baseline;
  }
`;

const DayContent = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    filter: sepia(1);
  }
`;

const DayFirstCover = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 50%;
  height: 100%;
  background: ${COLORS.PRIMARY_LIGHT};
`;

const DayLastCover = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 50%;
  height: 100%;
  background: ${COLORS.PRIMARY_LIGHT};
`;

const DayCircle = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: ${(props) => (props.selected ? COLORS.PRIMARY : "transparent")};
`;

const Cell = styled.div`
  width: 3em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Day = styled(Cell)`
    position:relative;
    color: ${(props) =>
    props.first || props.last
      ? "white"
      : props.disabled
        ? COLORS.DISABLED
        : (props) =>
          props.afterToday || props.today ? COLORS.GRAY1 : COLORS.GRAY3};
    background : ${(props) =>
    props.selected && !props.first && !props.last
      ? COLORS.PRIMARY_LIGHT
      : "inherit"}
    cursor: pointer;
    &:hover {
      &::after {
        background: rgba(145, 202, 219,0.4);
      }
    }
    &::after {
      content: '';
      position: absolute;
      width:100%;
      height:100%;
      border-radius:6px;
      left:0;
      top: 0;
    }
  `;
const Arrow = styled(IconButton)`
  position: absolute;
  top: 50%;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  background: white;
  &:hover {
    background: #ddd;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.24);
  }
`;

const ArrowRight = ({ onClick }) => (
  <Arrow
    className='fas fa-arrow-right'
    style={{
      right: "0%",
      transform: "translate(50% , -50%)",
    }}
    onClick={onClick} />
);
const ArrowLeft = ({ onClick }) => (
  <Arrow
    className='fas fa-arrow-left'
    style={{
      left: "0%",
      transform: "translate(-50% , -50%)",
    }}
    onClick={onClick} />
);

export const TimePickerArrow = styled.i`
  text-align: center;
  font-size: 25px;
  cursor: pointer;
`;

const Week = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1px;
`;

const Month = ({
  year,
  month,
  onChange,
  first,
  last,
  selected,
  selected_end,
}) => {
  moment.locale(`${lang}-ch`);

  const config = {
    months: moment.months().map(capitalize),
    days: moment
      .weekdaysShort()
      .slice(1)
      .concat(moment.weekdaysShort()[0])
      .map(capitalize)
      .map((s) => s.replace(".", "")),
  };

  const numDays = getDaysNumber(year, month);
  const firstDayofWeek = moment(getDateString(year, month, 1)).weekday();
  const days = new Array(firstDayofWeek)
    .fill(null)
    .concat(new Array(numDays).fill(1).map((item, i) => i + 1))
    .concat(new Array(7).fill(null));
  const weeks = new Array(6)
    .fill(1)
    .map((item, i) => days.slice(i * 7, i * 7 + 7));

  return (
    <MonthTable>
      <MonthTableCaption first={first} last={last}>
        {`${config.months[month - 1]} ${year}`}
      </MonthTableCaption>
      <Week>
        {config.days.map((d) => (
          <Cell key={d}>
            <Header>{d}</Header>
          </Cell>
        ))}
      </Week>
      {weeks.map((week, index) => (
        <Week key={index}>
          {week.map((day, i) => {
            if (day) {
              const disabled = false;
              const today =
                moment().format("YYYY-MM-DD") ===
                moment(getDateString(year, month, day)).format("YYYY-MM-DD");
              const afterToday =
                new Date(getDateString(year, month, day)).getTime() >=
                new Date().getTime();
              const first =
                selected &&
                compareArrays(getNumbersFromDate(selected), [day, month, year]);

              getDate(selected) === getDate(getDateString(year, month, day));
              const selected_endNumbers = getNumbersFromDate(selected_end);

              const last =
                selected_end &&
                compareArrays(selected_endNumbers, [day, month, year]);

              const afterSelected = isValidDate(selected)
                ? getDateFromAnyType(selected).valueOf() <
                moment(getDateString(year, month, day)).hour(23).valueOf()
                : false;

              const beforeSelected_end = isValidDate(selected_end)
                ? getDateFromAnyType(selected_end).valueOf() >
                moment(getDateString(year, month, day)).valueOf()
                : false;

              const selectedDay =
                (beforeSelected_end && afterSelected) ||
                (!selected && beforeSelected_end) ||
                (!selected_end && afterSelected);

              return (
                <Day
                  data-test={`calendar-day-${i}`}
                  key={i}
                  disabled={disabled}
                  today={today}
                  afterToday={afterToday}
                  selected={selectedDay}
                  first={first}
                  last={last}
                  onClick={() =>
                    day && onChange(getDateString(year, month, day))}>
                  <DayContent>{day}</DayContent>
                  {first &&
                    !(first && last) &&
                    (String(selected_endNumbers[2] || "").length === 4 ||
                      !selected_end) &&
                    selected_endNumbers[1] !== 0 && <DayFirstCover />}
                  {last && !(first && last) && isISOString(selected) && (
                    <DayLastCover />
                  )}
                  <DayCircle selected={first || last} />
                </Day>
              );
            }

            return <Cell key={i} Z={true} />;
          })}
        </Week>
      ))}
    </MonthTable>
  );
};

const TimePicker = ({ time, onChange }) => {
  const resetField = (e) => {
    if (e.target.value === '0') {
      e.target.value = '';
    }
  };

  return (
    <div
      style={{
        borderBottom: `1px solid ${COLORS.TABLE}`,
        padding: "10px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "20px",
      }}>
      <FormControl margin='normal'>
        <StyledTimeInput
          type='number'
          value={time.h}
          onClick={(e) => resetField(e)}
          onChange={(e) =>
            onChange(
              Math.max(e.target.value % 24),
              Math.max(time.m),
              Math.max(time.s)
            )}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Icon>
                  <InventoryIcon
                    icon='ui.arrow_left'
                    onClick={() =>
                      onChange((time.h - 1 + 24) % 24, time.m, time.s)} />
                </Icon>
                <Icon onClick={() => onChange((time.h + 1) % 24, time.m, time.s)}>
                  <InventoryIcon icon='ui.arrow_right' />
                </Icon>
              </InputAdornment>
            ),
          }} />
      </FormControl>{" "}
      <span className='m-2'>:</span>
      <FormControl margin='normal'>
        <StyledTimeInput
          type='number'
          value={time.m}
          onClick={(e) => resetField(e)}
          onChange={(e) => onChange(time.h, e.target.value % 60, time.s)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Icon>
                  <InventoryIcon
                    icon='ui.arrow_left'
                    onClick={() =>
                      onChange(time.h, (time.m - 1 + 60) % 60, time.s)} />
                </Icon>
                <Icon onClick={() => onChange(time.h, (time.m + 1) % 60, time.s)}>
                  <InventoryIcon icon='ui.arrow_right' />
                </Icon>
              </InputAdornment>
            ),
          }} />
      </FormControl>
    </div>
  );
};

type $Props = {
  value: string | null,
  value_end: string | null,
  setValues: Function,
  updateValue: Function,
  months: ({ y: number, m: number })[],
  showDateOnCalendar: Function,
  goBack: () => void,
  goForward: () => void,
  close: () => void,
  offsetLeft: number,
  type: "time" | "range" | "single",
  dataTest?: string,
}
export const Calendar = (
  (
    {
      value,
      value_end,
      setValues,
      updateValue,
      months,
      showDateOnCalendar,
      goBack,
      goForward,
      close,
      offsetLeft,
      type,
      dataTest,
    }: $Props,
    ref
  ) => {
    const input = useRef(null);
    const input_end = useRef(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [selected, setSelected] = useState<string | null | false>(value || "");
    const [selected_end, setSelected_end] = useState<string | null | false>(value_end || "");
    const [time, setTime] = useState({
      h: value ? moment(value).hours() : 0,
      m: value ? moment(value).minutes() : 0,
      s: value ? moment(value).seconds() : 0,
    });
    const [cursorWorkaround, triggerCursorWorkaround] = React.useState<{
      timesUpdated: number,
      selectionEnd: null | number,
      selectionStart: null | number,
      target: null | { selectionStart: number | null, selectionEnd: number | null }
    }>({
      timesUpdated: 0,
      selectionEnd: null,
      selectionStart: null,
      target: null,
    });

    useEffect(() => {
      if (cursorWorkaround.target) {
        cursorWorkaround.target.selectionStart =
          cursorWorkaround.selectionStart;
        cursorWorkaround.target.selectionEnd = cursorWorkaround.selectionEnd;
      }
    }, [cursorWorkaround.timesUpdated]);

    useEffect(() => {
      onDaySelected(selected);
    }, [time]);

    useEffect(() => {
      const date = getDateFromAnyType(selected);
      const monthShown = Boolean(
        months.find((m) => m.y === date.year() && m.m === date.month())
      );

      if (isValidDate(selected) && !monthShown) {
        showDateOnCalendar(date);
      }
    }, [selected]);

    useEffect(() => {
      const date = getDateFromAnyType(selected_end);
      const monthShown = Boolean(
        months.find((m) => m.y === date.year() && m.m === date.month() + 1)
      );

      if (!monthShown) {
        if (isValidDate(selected_end)) {
          showDateOnCalendar(getDateFromAnyType(selected_end), {
            position: "end",
          });
        }
        else if (isValidDate(selected)) {
          showDateOnCalendar(getDateFromAnyType(selected));
        }
      }
    }, [selected_end]);

    const giveFocus = (input) => {
      setFocusedInput(input);
      input.current?.querySelector("input").focus();
    };

    const timeOnChange = (h, m, s) => {
      setTime({ h, m, s});
    };

    const onDaySelected = (date) => {
      if (type === "time") {
        const { h, m, s } = time;
        const selected = moment(date)
          .hours(h)
          .minutes(m)
          .seconds(s)
          .toISOString();

        updateValue(selected);

        setSelected(selected);
        setSelected_end(selected);

        return;
      }

      if (type === "range") {
        const focus_end = focusedInput === input_end;
        const focus_start = !focus_end;
        const before_start =
          selected && new Date(selected).getTime() > new Date(date).getTime();
        const after_end =
          selected_end &&
          new Date(selected_end).getTime() < new Date(date).getTime();

        const startDate = focus_start || before_start ? date : selected;
        const endDate =
          (focus_start && after_end) || (focus_end && before_start)
            ? null
            : focus_end
              ? date
              : selected_end;

        const startDate_changed = date !== selected;

        setSelected(startDate);
        setSelected_end(endDate);
        giveFocus(startDate_changed ? input_end : input);

        return;
      }

      setSelected(date);
      setSelected_end(date);
    };

    return (
      //Absolute position maximum width is restricted to its parent relative/absolute component in ie11
      //@ts-ignore
      <div>
        <CalendarContainer
          id='date-picker__calendar'
          offsetLeft={offsetLeft}
          type={type}>
          <ArrowLeft onClick={goBack} />
          <ArrowRight onClick={goForward} />

          {type === "time" && (
            <TimePicker
              time={time}
              onChange={(h, m, s) => timeOnChange(h, m, s)} />
          )}
          {type === "range" && (
            <CalendarOptions>
              <span className='d-flex'>
                <SelectWithoutBorder
                  value={""}
                  dataTest='tetetet'
                  label={trans("ui.date_picker.calendar.ranges")}
                  onChange={(e) => {
                    const { value } = e.target;
                    const date = moment();

                    Object.entries(value).forEach(([k, v]) =>
                      //@ts-ignore
                      date.subtract(v, k)
                    );
                    date.hours(0).toISOString();
                    setValues(date, moment().hours(23).toISOString());

                    if (value) {
                      close();
                    }
                  }}
                  options={[
                    {
                      key: "6m",
                      label: trans(
                        "ui.date_picker.calendar.ranges.6months",
                        "6 monate"
                      ),
                      value: { months: 6 },
                    },
                    {
                      key: "12m",
                      label: trans(
                        "ui.date_picker.calendar.ranges.12months",
                        "12 monate"
                      ),
                      value: { months: 12 },
                    },
                  ]} />
                <ButtonSmall
                  type='button'
                  onClick={() => {
                    setSelected(null);
                    setSelected_end(null);
                    giveFocus(input);
                  }}>
                  {trans("ui.date_picker.calendar.options.clear")}
                </ButtonSmall>
              </span>
              <div
                className='d-flex justify-content-end'
                style={{ flexGrow: 1 }}>
                <InputContainer>
                  <TextFieldWithSuggestion
                    ref={input}
                    suggestion={focusedInput === input &&
                      formatDate(suggestion(selected, { before: selected_end }))
                      ? formatDate(
                        suggestion(selected, { before: selected_end })
                      )
                      : ""}
                    onBlur={() => {
                      const suggestionVal = formatDate(
                        suggestion(selected, { before: selected_end })
                      );

                      if (selected && selected.length < suggestionVal.length) {
                        setSelected(suggestionVal);
                      }
                    }}
                    value={formatDate(selected) || selected || ""}
                    placeholder={trans("ui.date_picker.past")}
                    onClick={() => giveFocus(input)}
                    onChange={(e) => {
                      const { value } = e.target;
                      const suggested = suggestion(value, {
                        before: selected_end,
                      });

                      if (suggested === false) {
                        const cursorMoved =
                          value.length >
                            (formatDate(selected) || selected || []).length
                            ? 1
                            : -1;

                        return triggerCursorWorkaround({
                          timesUpdated: cursorWorkaround.timesUpdated + 1,
                          selectionStart: e.target.selectionStart - cursorMoved,
                          selectionEnd: e.target.selectionEnd - cursorMoved,
                          target: e.target,
                        });
                      }

                      const formattedSuggestion = formatDate(suggested);
                      const shouldAddDot = shouldAddDotToValue(
                        value,
                        selected,
                        formattedSuggestion
                      );

                      formattedSuggestion.replace(value, "")[0] === ".";

                      setSelected(shouldAddDot ? value + "." : value);

                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();

                        setSelected(
                          suggestion(e.target.value, { before: selected_end })
                        );
                        giveFocus(input_end);
                      }
                    }}
                    InputProps={{
                      endAdornment: selected && (
                        <InputAdornment position='end'>
                          <Icon
                            disabled={!isValidDate(selected)}
                            onClick={() =>
                              setSelected(
                                getDateFromAnyType(selected)
                                  .subtract(1, "days")
                                  .toISOString()
                              )}>
                            <InventoryIcon icon='ui.arrow_left' />
                          </Icon>
                          <Icon
                            onClick={() =>
                              setSelected(
                                getDateFromAnyType(selected)
                                  .add(1, "days")
                                  .toISOString()
                              )}
                            disabled={!isValidDate(selected) ||
                              (isValidDate(selected_end) &&
                                getDateFromAnyType(selected)
                                  .add(1, "days")
                                  .valueOf() >
                                getDateFromAnyType(selected_end).valueOf())}>
                            <InventoryIcon icon='ui.arrow_right' />
                          </Icon>
                        </InputAdornment>
                      ),
                    }} />
                  <InputSeparator />
                  <TextFieldWithSuggestion
                    
                    // We need this class in order to exclude the datepicker from the
                    // CSS selector that would otherwise give it a width of 100% and break it
                    textFieldWithoutBorderClasses='calendar-end-date-picker'
                    ref={input_end}
                    value={formatDate(selected_end) || selected_end || ""}
                    onClick={() => giveFocus(input_end)}
                    suggestion={focusedInput === input_end
                      ? formatDate(
                        suggestion(selected_end, { after: selected })
                      )
                      : ""}
                    placeholder={trans("ui.date_picker.future")}
                    onChange={(e) => {
                      const { value } = e.target;
                      const suggested = suggestion(value, { after: selected });

                      if (suggested === false) {
                        const cursorMoved =
                          value.length >
                            (formatDate(selected_end) || selected_end || []).length
                            ? 1
                            : -1;

                        return triggerCursorWorkaround({
                          timesUpdated: cursorWorkaround.timesUpdated + 1,
                          selectionStart: e.target.selectionStart - cursorMoved,
                          selectionEnd: e.target.selectionEnd - cursorMoved,
                          target: e.target,
                        });
                      }

                      const formattedSuggestion = formatDate(suggested);
                      const shouldAddDot = shouldAddDotToValue(
                        value,
                        selected_end,
                        formattedSuggestion
                      );

                      formattedSuggestion.replace(value, "")[0] === ".";

                      setSelected_end(shouldAddDot ? value + "." : value);
                    }}
                    onBlur={() => {
                      const suggestionVal = formatDate(
                        suggestion(selected_end, { after: selected })
                      );

                      if (
                        selected_end &&
                        selected_end.length < suggestionVal.length
                      ) {
                        setSelected_end(suggestionVal);
                      }
                    }}
                    onKeyUp={(e) => {
                      if (e.keyCode === 13) {
                        e.preventDefault();

                        setSelected_end(
                          suggestion(e.target.value, { after: selected })
                        );
                      }
                    }}
                    InputProps={{
                      endAdornment: selected_end && (
                        <InputAdornment position='end'>
                          <Icon
                            onClick={() =>
                              setSelected_end(
                                getDateFromAnyType(selected_end)
                                  .subtract(1, "days")
                                  .toISOString()
                              )}
                            disabled={!isValidDate(selected_end) ||
                              (!isValidDate(selected) &&
                                getDateFromAnyType(selected_end)
                                  .subtract(1, "days")
                                  .valueOf() <
                                getDateFromAnyType(selected).valueOf())}>
                            <InventoryIcon icon='ui.arrow_left' />
                          </Icon>
                          <Icon
                            onClick={() =>
                              setSelected_end(
                                getDateFromAnyType(selected_end)
                                  .add(1, "days")
                                  .toISOString()
                              )}
                            disabled={!isValidDate(selected_end)}>
                            <InventoryIcon icon='ui.arrow_right' />
                          </Icon>
                        </InputAdornment>
                      ),
                    }} />
                </InputContainer>
              </div>
            </CalendarOptions>
          )}
          <div style={{ padding: "15px" }}>
            <MonthsContainer>
              {months.map(({ y, m }, index) => (
                <Month
                  key={`${y}${m}`}
                  year={y}
                  month={m}
                  first={index === 0}
                  last={index === months.length - 1}
                  selected={selected}
                  selected_end={selected_end}
                  onChange={onDaySelected} />
              ))}
            </MonthsContainer>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}>
              <Button
                data-test='meeting-create-calendar-save'
                type='button'
                small={true}
                onClick={() => setValues(selected, selected_end)}>
                {trans("ui.submit")}
              </Button>
            </div>
          </div>
        </CalendarContainer>
      </div>
    );
  }
);
