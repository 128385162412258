// export const ADD_FLASH_MESSAGE = "add_flash_messsage";
export const EDIT_FLASHES = "edit_flashes";
export const LOCK_FLASHES = "lock_flashes";

export function editFlashes(store) {
  return {
    type: EDIT_FLASHES,
    payload: store
  };
}

export function lockFlashes() {
  return {
    type: LOCK_FLASHES
  };
}
