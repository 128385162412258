import React, { memo, useState, useCallback, useRef, ReactElement } from "react";
import TextField from "@material-ui/core/TextField";
import { IconButton } from "../IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from '@components/Shared/InventoryIcon';
import styled from "styled-components";
import { COLORS, SHARED, SPACINGS } from "@constants";
import { measureText } from '@helpers';

export const inputStyles = (props) => `
&:hover {
  .MuiInputLabel-outlined.MuiInputLabel-shrink:not(:hover) {
    max-width: unset;
    background:white;
  }
}
border-radius: 6px;
border: 1px solid ${COLORS.GRAY4};
.MuiInputBase-root {
  background-color: #fff;
}
input.MuiInputBase-input {
  ${props.large ? "font-size: 32px" : ""}
  padding: 0px;
  font-family: 'Roboto', sans-serif;
}
input:not(:disabled).MuiInputBase-input {
  color: #3f3356;
}
.MuiInputLabel-root.MuiInputLabel-shrink.MuiInputLabel-outlined {
  white-space: nowrap;
}
.MuiInputAdornment-root.MuiInputAdornment-positionEnd{
  transform: translateX(18px);
}
.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input,.MuiInput-input__politik,input, textarea, .MuiInput-input__politik{
  padding: 9px 34px 9px 12px;
}
.MuiInputBase-root.MuiOutlinedInput-root.MuiInput-input__politik__multiple.MuiInput-input__politik {
  padding-right: 5px;
}

.MuiFormLabel-root {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size:16px;
  // line-height: 20px;
  display: flex;
  align-items: center;
  transform: ${props.large ? "translate(11px, 20px) scale(2)" : "translate(11px, 11px) scale(1)"};
  color: #c4c4c4;
}
.MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: inherit;
  max-width: 100%;
}
.MuiInputAdornment-root.MuiInputAdornment-positionEnd .MuiIconButton-edgeEnd, .MuiInputAdornment-root.MuiInputAdornment-positionEnd .MuiButtonBase-root.MuiIconButton-root {
  font-size: 12px;
  color: #c4c4c4;
  &:hover {
    color: #3f3356;
  }
}

.MuiOutlinedInput-inputAdornedStart {
  padding-left: 0;
}

.MuiInputBase-input.MuiOutlinedInput-input:not(textarea) {
  height: ${props.large ? "50px" : SHARED.INPUT_HEIGHT};
}
.bmd-form-group{
  width: inherit;
}
`;
const TextInput = styled(TextField)`
  ${props => inputStyles(props)}
  div:not(.MuiInput-input__politik):not(.multiple-select__chip), textarea:not(.MuiInput-input__politik),input:not(.MuiInput-input__politik) {
    padding: 0px;
    margin: 0px;
    line-height: ${SHARED.INPUT_HEIGHT};
    input {
      height: calc(${SHARED.INPUT_HEIGHT} - 2px);
    }
  }
  textarea.MuiOutlinedInput-inputMultiline {
    margin-bottom: -4px;
  }
`;

type $Props = {
  autoFocus?: string,
  value?: string;
  onChange?: Function;
  onKeyPress?: Function;
  onKeyDown?: Function;
  type?: "number" | "email" | "password";
  label?: string;
  inputLabel?: string;
  maxLength?: number;
  color?: string;
  className?: string;
  endicon?: string;
  iconAsImage?: ReactElement;
  startIcon?: string;
  onSubmit?: any;
  InputProps?: any;
  id?: string;
  name?: string;
  multiline?: boolean;
  rowsMax?: string;
  error?: boolean;
  disabled?: boolean;
  onBlur?: Function;
  fullWidth?: boolean;
  margin?: "normal";
  placeholder?: string;
  style?: object;
  large?: boolean;
  rows?: string,
  autoComplete?: "off",
  onClick?: Function,
  onKeyUp?: Function,
  variant?: "outlined"
  required?: boolean;
  autocomplete?: "on" | "off",
  dataTest?: string,
};

export const oldVersion = React.forwardRef((props: $Props, ref) => (
  <>
    {props.inputLabel && <InputLabel>{props.inputLabel}</InputLabel>}
    <TextInput
      ref={ref}
      variant='outlined'
      {...props}
      label={props.label}
      large={Number(props.large || 0)}
      InputLabelProps={{ shrink: true }}
      InputProps={{
        className: `${props.className || ""} MuiInput-input__politik`,
        endAdornment: props.endicon && (
          <InputAdornment position='end'>
            {props.onSubmit ? (
              <IconButton
                style={props.large ? { transform: "scale(1.5) translateX(-20%)" } : {}}
                aria-label='search button'
                onClick={props.onSubmit}
                icon={props.endicon} />
            ) : (
              <Icon icon={props.endicon} style={{ color: COLORS.GRAY3 }} />
            )}
          </InputAdornment>
        ),
        ...(props.InputProps || {}),
      }} />
  </>
));

const InputLabel = styled.label`
  display: flex;
  width: calc(100% - 20px);
  position:absolute;
  pointer-events:none;
  z-index:1;
  top: 8px;
  left: 12px;
  height: ${(props) => props.large ? "50px" : SHARED.INPUT_HEIGHT};
  cursor: text;
  font-size: 16px;
  ${props => props.large ? "font-size: 32px" : ""}
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  transition: all .3s cubic-bezier(0.34, 1.45, 0.7, 1);
  align-items: center;
  ${props => (props.value || props.placeholder) ? `
  top:-10px;
  left: 8px;
  font-size: 12px;
  padding: 0px 5px;
  color: rgba(0, 0, 0, 0.87);
  height: ${SHARED.INPUT_HEIGHT};
  `: ""}
`;
const LabelText = styled.span`
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
`;
const BorderTop = styled.div`
    background: ${COLORS.FONTDARK};
    position: absolute;
    width: 0px;
    top: -2px;
    left: 6px;
    height: 2px;
    transition: width .3s cubic-bezier(0.34, 1.45, 0.7, 1);

    ${props => borderTopOnFocusStyle(props.labelWidth)
}
`;
export const InputContainer = styled.div`
  color: rgba(0, 0, 0, 0.87);
  padding:1px;
  cursor: text;
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  font-size: 16px;
  height: ${(props) => props.large ? "72px" : "36px"};
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: 1.1876em;
  letter-spacing: 0.00938em;
  border-radius: 4px;
  min-width: 85px;
  border: 1px solid ${COLORS.GRAY3};
  animation-duration: 5s;
  background: white;
  ${props => props.fullWidth ? "width:100%;" : ""}
  ${(props) =>
    props.margin === "normal"
      ? `
    
    margin-top: 16px;
    margin-bottom: 8px;
    `
      : ""}
  ${props => props.large ? "font-size: 32px" : ""}
  // &:hover ${InputLabel} {
  //   top:-10px;
  //   left: 8px;
  //   font-size: 12px;
  //   padding: 0px 5px;
  //   color: rgba(0, 0, 0, 0.87);
  //   height: ${SHARED.INPUT_HEIGHT};
  // }
  ${props => props.focus ? `
    border-color: ${COLORS.PRIMARY};
    border-width: 2px;
    padding:0px;
  ` : `
  &:hover {
    border-color: ${COLORS.FONTDARK};
  }
  `}
  ${props => (props.error) ? `
  border-color:${COLORS.DANGER}
  border-width: 1px;
  padding:0px;
  ` : ""}

  ${props => props.disabled && `
  border-color: ${COLORS.GRAY3}!important;`}
`;
const StyledInput = styled.input`
padding: 7px 11px 7px 11px;
color: ${(props) => props.color || ''};
box-sizing:content-box;
height: ${(props) => props.large ? "50px" : SHARED.INPUT_HEIGHT};
${props => props.rows ? "height:unset;" : ""}
border:none;
background: transparent;
flex:1;
&:focus + ${InputLabel} {
  top:-10px;
  left: 8px;
  font-size: 12px;
  padding: 0px 5px;
  color: rgba(0, 0, 0, 0.87);
  height: ${SHARED.INPUT_HEIGHT};
  &:${InputContainer} {
    display:none
  }
}
`;

const StyledTextArea = styled.textarea`
padding: 8px 12px 8px 12px;
box-sizing:content-box;
${props => props.rows ? `
height: ${(props) => props.large ? "50px" : SHARED.INPUT_HEIGHT};
` : ""}
border:none;
background: transparent;
flex:1;
&:focus + ${InputLabel} {
  top:-10px;
  left: 8px;
  font-size: 12px;
  padding: 0px 5px;
  color: rgba(0, 0, 0, 0.87);
  height: ${SHARED.INPUT_HEIGHT};
  &:${InputContainer} {
    display:none
  }
}
`;

const borderTopOnFocusStyle = width => `
background: white;
width:${width || 100}px;`;

const InputButtons = styled.div`
`;

const largeIconStyles = { transform: "scale(1.5) translateX(-20%)", marginRight: SPACINGS.X1 };
const iconStyles = { fontSize: SPACINGS.X2, padding: SPACINGS.X1, marginRight: SPACINGS.X1 };
const startIconStyles = { margin: 0, padding: `${SPACINGS.X1} 0px ${SPACINGS.X1} ${SPACINGS.X1}`};
const inactiveIconStyles = { ...iconStyles, color: COLORS.GRAY3 };

const Input = React.forwardRef((props: $Props) => {
  const [focus, setFocus] = useState(false);

  const {
    value,
    label,
    large,
    endicon,
    iconAsImage,
    startIcon,
    color,
    onSubmit,
    onChange,
    error,
    rows,
    onBlur,
    fullWidth,
    className,
    style,
    margin,
    name,
    autoFocus,
    required,
    onKeyDown,
    onKeyUp,
    onKeyPress,
    onClick,
    disabled,
    type,
    maxLength,
  } = props;

  //Due to a bug we have because we are calculating the label placeholder
  //width manually, if the TextInput doesn't have a placeholder value, the
  //outlined label width gets calculated incorrectly. That would require either
  //to change multiple files and add the placeholders props everywhere, or to set
  //a default placeholder, which has the same value as the label, in order
  // to force the component to render properly.
  const placeholder = props.placeholder || label;

  const onFocusCallback = useCallback(() => setFocus(true), []);
  const onBlurCallback = useCallback((e) => {
    setFocus(false);
    if (onBlur)
      onBlur(e);
  }, []);
  const ref:any = useRef();
  const labelWidth = label ? measureText(label, 16) : 0;

  return <InputContainer
    className={className}
    style={style}
    ref={ref}
    large={large}
    value={Boolean(value)}
    placeholder={placeholder}
    focus={focus}
    error={error}
    fullWidth={fullWidth}
    margin={margin}
    disabled={disabled}>
    {
      startIcon && (
        <InputButtons large={large}>
          <Icon icon={startIcon} style={{...inactiveIconStyles, ...startIconStyles}} />
        </InputButtons>
      )
    }
    {rows 
      ? <StyledTextArea
        data-test={props.dataTest}
        large={large}
        rows={rows}
        value={value}
        onChange={onChange}
        labelWidth={labelWidth}
        onFocus={onFocusCallback}
        onBlur={onBlurCallback}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onKeyPress={onKeyPress}
        onClick={onClick} />

      : <StyledInput
        data-test={props.dataTest}
        disabled={disabled}
        type={type}
        large={large}
        rows={rows}
        value={value}
        color={color}
        maxLength={maxLength}
        onChange={onChange}
        labelWidth={labelWidth}
        placeholder={props.placeholder ? placeholder : ""}
        onFocus={onFocusCallback}
        onBlur={onBlurCallback}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onKeyPress={onKeyPress}
        onClick={onClick}
        name={name}
        autoFocus={autoFocus}
        required={required} />
    }
     
    {label && <InputLabel large={large} value={Boolean(value)} placeholder={placeholder} >
      <LabelText>{label}</LabelText>
    </InputLabel>
    }
    {label && <BorderTop value={Boolean(value)} placeholder={placeholder} labelWidth={labelWidth}
      focus={focus}/>}

    {
      endicon && (
        <InputButtons large={large}>
          {onSubmit ? (
            <IconButton
              icon={endicon}
              data-test='search-button'
              style={large ? largeIconStyles : iconStyles}
              aria-label='search button'
              color='rgb(178, 178, 178)'
              onClick={onSubmit} />
          ) : (
            <Icon icon={endicon} style={inactiveIconStyles} />
          )}
        </InputButtons>
      )
    }
    {iconAsImage}
  </InputContainer >;
});

export default memo(Input);