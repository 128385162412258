import React from "react";
import styled from "styled-components";
import { COLORS, SPACINGS } from "@constants";

const FormStyled = styled.div`
  width: 65vw;
  position: relative;
  background: white;
  background: #ffffff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
`;

const SaveTemplateForm = styled(FormStyled)`
  border-radius: 6px;
`;

const FormContent = styled.div`
  padding: ${SPACINGS.X3};
`;
const Title = styled.h4`
  color: #fff;
  padding: 18px ${SPACINGS.X3};
  border-radius: 6px 6px 0px 0px;
  background-color: ${COLORS.PRIMARY};
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
`;

const SaveTemplateTitle = styled(Title)`
  border-radius: 6px 6px 0px 0px
`;
export const Form = ({ title, children, style, method }: { title: string, children, style?: {}, method?: "post" }) => (
  <FormStyled style={{ ...style, maxWidth: '800px' }}>
    <Title>{title}</Title>
    <FormContent>{React.Children.toArray(children)}</FormContent>
  </FormStyled>
);

export const SaveConfigForm = ({ title, children}: { title: string, children, method?: "post"  }) => (
  <SaveTemplateForm>
    <SaveTemplateTitle>{title}</SaveTemplateTitle>
    <FormContent>{React.Children.toArray(children)}</FormContent>
  </SaveTemplateForm>
);

