import axios from "./instance";
import { Routing } from '@backendRouter';

export const deleteItem = async (apiUrl) => {
  await axios.delete(apiUrl);
}

export const editTitle = async (apiUrl, title) => {
  await axios.patch(apiUrl, {
    title: title
  });
}

export const editSorting = async (apiUrl, sort) => {
  await axios.patch(apiUrl, {
    sorting: sort
  });
}

export const updateAffairs = async (apiUrl, data) => {
  await axios.patch(apiUrl, data);
}

export const replicate = async (apiUrl) => {
  await axios.post(apiUrl);
}

export const getAvailableParticipants = () => {
  const url = Routing.generate(
    "api_custom_meetings_get_participants_collection"
  );

  return axios.get(url);
}

export const getTreeDataForMeetings = () => {
  const url = Routing.generate("api_custom_meetings_get_collection", {
    "parent[exists]": false, "pagination": false
  });

  return axios.get(url);
}

export const getTreeDataForMeetingsArchived = () => {
  const url = Routing.generate("api_custom_meetings_get_archived_collection", {
    "parent[exists]": false, "pagination": false
  });

  return axios.get(url);
}

export const getAgendaPoints = (config = {}) => {
  const url = Routing.generate(
    "api_custom_meeting_agenda_points_get_collection",
    config
  );

  return axios.get(url);
}

export const getAgendaPointsBasic = (config = {}) => {
  const url = Routing.generate(
    "api_custom_meeting_agenda_points_get_basic_collection",
    config
  );

  return axios.get(url);
}

export const getCustomMeetings = () => {
  const url = Routing.generate("api_custom_meetings_get_collection");

  return axios.get(url);
}

export const getCustomMeeting = (id) => {
  const url = Routing.generate("api_custom_meetings_get_item", { id });

  return axios.get(url);
}

export const getCustomMeetingEdit = (id) => {
  const url = Routing.generate("api_custom_meetings_get_edit_item", { id });

  return axios.get(url);
}

export const deleteNode = (id) => {
  const url = Routing.generate("api_custom_meeting_nodes_delete_item", {
    id
  });

  return axios.delete(url);
}

export const editNode = (id, body = {}) => {
  const url = Routing.generate("api_custom_meeting_nodes_put_item", {
    id
  });

  return axios.put(url, { ["@type"]: "CustomMeeting", ...body });
}

export const assignAffairToAgendaPoint = (parentId, affair) => {
  const url = Routing.generate("api_custom_meeting_nodes_post_collection");
  const body = {
    item: affair["@id"],
    parent: parentId,
    "@type": `CustomMeetingAgendaPoint${affair["@type"]}Leaf`
  };

  return axios.post(url, body);

};

export const createMeetingAgendaPoint = (parentId, body = {}, affair) => {
  const url = Routing.generate("api_custom_meeting_nodes_post_collection");
  const parent = Routing.generate("api_custom_meetings_get_item", {
    id: parentId
  });

  return axios
    .post(url, {
      "@type": `CustomMeetingAgendaPoint`,
      parent,
      ...body
    })
    .then(({ data }) => {
      affair && assignAffairToAgendaPoint(data.id, affair);

      return data;
    });
};

export const getMeetingAgendaPoint = id => {
  const url = Routing.generate("api_custom_meeting_agenda_points_get_item", {
    id
  });

  return axios.get(url);
};

export const createNode = (body = {}) => {
  const url = Routing.generate("api_custom_meeting_nodes_post_collection");

  return axios.post(url, { ["@type"]: "CustomMeeting", ...body });
};


export const getMeetingsBasic = () => {
  const url = Routing.generate("api_custom_meetings_get_basic_collection");

  return axios.get(url);
};

export const unarchiveMeeting = (id, body = {}) => {
  const url = Routing.generate("api_custom_meeting_nodes_put_unarchive_item", {
    id
  });

  return axios.put(url, { ["@type"]: "CustomMeeting", ...body });
}
