export const COLORS = {
  INTERACTIVE: "#2c6777",
  PRIMARY: "#2EA6AF",
  PRIMARY_LIGHT: "#BDE0E3",
  PRIMARY_DARK: "#2C6777",
  SECONDARY: "#3e4e54",
  THIRD: "#FFCD44",
  DANGER: "#ee4125",
  DISABLED: "gray",
  SUCCESS_DARKER: "#339339",
  SUCCESS: "#70D66E",
  ERROR: "#E23D32",
  WARNING: "",
  WHITE: "#FFFFFF",
  ACTIVE: "#123456",
  PRIVATE: "#969eb7",
  TABLE: "#ECEBED",
  HOVER: "rgba(0, 0, 0, 0.54)",
  PRIMARY_GRADIENT: "linear-gradient(60deg, #4DBDC6, #97D2D4)",
  NOTIFICATION: "#FFCD44",
  GRAY1: "#323232",
  GRAY2: "#575756",
  GRAY3: "#B2B2B2",
  GRAY4: "#878787",
  LIGHTGRAY: "#EEEEEE",
  LIGHTGRAY2: "rgba(0, 0, 0, 0.04)",
  LIGHTGRAY3: "#c2c2c2",
  GRAYLIGHT: "#ECEBED",
  BACKGROUND: "#E5E5E5",
  BACKGROUND_LOADING: '#F6F7F8',
  FONTDARK: "#323232",
  FONT_MID_DARK: "#3C4858",
  MEDIUM_GREY: '#C4C4C4',
  DARK_GREY: "#575757",
};

// NEW COLOR CONSTANTS

export const PRIMARY_COLORS = {
  TURQUOISE: "#2EA6AF",
  TURQUOISE_LIGHT: "#BDE0E3",
  ORANGE: "#F7A600",
  YELLOW: "#FFCE44",
};

export const GREYTONES = {
  BLACK: "#323232",
  GREY_DARK: "#575757",
  GREY_MEDIUM: "#C4C4C4",
  GREY_LIGHT: "#F0F0F0",
  TAG_GREY: "#E5E5E5",
  APP_BACKGROUND: "#DDDDDD",
};

export const SEMANTIC_COLORS = {
  SEMANTIC_GREEN: "#70D66E",
  SEMANTIC_RED: "#E23D32",
  CONTRA_RED: "#D14B3C",
  PRO_GREEN: "#4F9045",
};

export const CALENDAR_COLORS = {
  DARK_GREEN: "#2C6777",
  TURQ1: "#46AEB7",
  TURQ2: "#2A8392",
  TURQ4: "#ACDBDE",
  RED2: "#717A8C",
  RED3: "#9A6077",
  REMINDER_SEMANTIC_GREEN: "#70D66E",
  REMINDER_DARK_RED: "#830800",
};

export const SECONDARY_COLORS = {
  DARK_GREEN: "#2C6777",
  GREEN1: "#4B8A3E",
  TURQ3: "#2C7482",
  TURQ2: "#2A8392",
  TURQ1: "#2E99A4",
  TURQ_1: "#4BB1BA",
  TURQ_2: "#76C3C9",
  TURQUOISE_LIGHT: "#BDE0E3",
  RED1: "#4994A1",
  RED2: "#717A8C",
  RED3: "#9A6077",
  RED4: "#B54F69",
  GREY_GREEN_1: "#A0C8CD",
  GREY_GREEN_2: "#74A3AD",
  GREY_GREEN_3: "#497F8D",
};

export const OVERLAY_COLORS = {
  TRANSPARENT_WHITE: "#ffffffaa"
}

export const VIS_COLORS = {
  V_GRADIENT: {
    0: '#F40909',
    30: '#E7B83F',
    60: '#FAFF00',
    100: '#00FF66',
  },
  H_GRADIENT: {
    0: '#2C6777',
    30: '#2EABAF',
    60: '#717A8C',
    100: '#B54F69',
  },
  DEFAULT: "rgb(46, 171, 175)",
}