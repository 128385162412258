import { Reducer } from "redux";

const root = document.getElementById("root");

const cantons: $Canton[] =
    root?.dataset.cantons && Object.values(JSON.parse(root.dataset.cantons)) || [];
const defaultReducer: $Reducer_cantons = cantons;


const cantonsReducer: Reducer<$Reducer_cantons, any> = (state = defaultReducer, action) => {
  switch (action.type) {
  default:
    return state;
  }
};

export default cantonsReducer;