import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { IntegrationReactSelect } from "./MaterialMultipleSelect";
import { SortableSelect } from "./SortableSelect";
import { Spinner } from "../Spinner";
import { showDangerAlert } from "../NotificationPopup";
import { trans } from "@trans";
import { getGroupTitle } from '@helpers';
import { fetchGroups } from "@redux/actions/groups";
import { Routing } from '@backendRouter';
import { getTreeDataArchived } from "@api/groups";

type $Props = {
  iri?: boolean;
  allSelected?: boolean;
  groups: $API_Collection<$AffairGroup>;
  onLoad?: Function;
  fetchGroups: Function;
  value: any;
  onChange: Function;
  isSortable?: boolean;
  label?: string | null;
  config?: $MultipleSelectConfig;
  disabled?: boolean,
  error?: boolean,
  name?: string
  placeholder?: string;
  includeArchivedGroups?: boolean;
};
type $State = {
  groupsSelected: string[];
  archivedGroups: $AffairGroup[];
};

class GroupsSelect extends Component<$Props, $State> {
  constructor(props) {
    super(props);
    this.state = {
      groupsSelected: [],
      archivedGroups: [],
    };
  }

  componentDidMount() {
    this.fetchData();
    this.fetchArchivedGroups();
  }

  fetchData = () => {
    this.props.fetchGroups().then(() => {
      if (this.props.allSelected) {
        const groupsSelected = (this.props.groups.data || []).map((item) =>
          this.props.iri ? item["@id"] : String(item.id)
        );

        this.setState({ groupsSelected }, () => {
          const event = {
            target: {
              value: groupsSelected,
            },
          };

          this.props.onLoad ?
            this.props.onLoad(event) :
            this.props.onChange(event);
        });
      }
    })
      .catch(() => showDangerAlert(trans("affairGroups.assign.error")));
  };

  fetchArchivedGroups = () => {
    getTreeDataArchived({
      "properties[]": [
        "children",
        "title",
        "description",
        "lvl",
        "sorting",
        "sortPosition",
        "id",
        "createdAt",
        "private",
        "author",
        "sortPosition",
      ],
    })
      .then(({ data }) => this.setState({ archivedGroups: data["hydra:member"] }))
      .catch(() => showDangerAlert(trans("affairGroups.assign.error")));
  };

  render() {
    const { groupsSelected, archivedGroups } = this.state;
    const { onChange, value, label, groups, includeArchivedGroups, isSortable } = this.props;
    const { loading, data } = groups;
    const groupsOptions = data.map((g) => ({
      ...g,
      value: this.props.iri ? g["@id"] : g.id,
      label: getGroupTitle(data, g.id),
      url: Routing.generate("user_affairgroup_overview", {
        affairGroupId: g.id,
      }),
      private: g.private
    }));
    const archivedGroupsOptions = archivedGroups.map(group => ({
      value: group["@id"],
      label: group.title,
      url: Routing.generate("user_archived_affairgroup_list"),
    }));
    const labelToShow =
      label === undefined
        ? trans("ui.select.groups")
        : label === null
          ? ""
          : label;

    return (!loading || this.props.groups.data.length > 0) ? (
      isSortable ? (
        <SortableSelect
          error={this.props.error}
          allSelected={this.props.allSelected}
          disabled={this.props.disabled}
          onChange={onChange}
          value={value || groupsSelected}
          placeholder={this.props.placeholder || trans("ui.select.groups.placeholder")}
          label={labelToShow}
          options={groupsOptions} />
      ) : (
        <IntegrationReactSelect
          {...this.props}
          onChange={onChange}
          disabled={this.props.disabled}
          value={value || groupsSelected}
          placeholder={this.props.placeholder || trans("ui.select.groups.placeholder")}
          label={labelToShow}
          options={includeArchivedGroups ? [...groupsOptions, ...archivedGroupsOptions] : groupsOptions} />
      )

    ) : (
      <Spinner />
    );
  }
}

function mapStateToProps(state) {
  return { groups: state.groups };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchGroups }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupsSelect);
