import React from 'react';
import useTranslation from './useTranslation';

const withTranslation = (Component: any) => (props: any) => {
  const {
    trans,
    setLanguage
  } = useTranslation();

  return (
    <Component {...props} trans={trans} setLanguage={setLanguage}/>
  );
};

export default withTranslation;