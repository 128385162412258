import { FETCH_NATIONAL_SESSIONS } from "@redux/actionTypes";
import { Reducer } from "redux";

const defaultReducer = { data: [], loading: false };

const nationalSessionsReducer: Reducer<$Reducer_nationalSessions, any> = (state = defaultReducer, action) => {
  switch (action.type) {
  case FETCH_NATIONAL_SESSIONS:
    return action.payload;
  default:
    return state;
  }
};

export default nationalSessionsReducer;