import { FETCH_GROUPS } from "../actionTypes";
import { Reducer } from "redux";


const defaultReducer = { data: [], loading: false };

const groupsReducer: Reducer<$Reducer_groups, any> = (state = defaultReducer, action) => {
  switch (action.type) {
  case FETCH_GROUPS:
    return action.payload;
  default:
    return state;
  }
};

export default groupsReducer;