import axios, { AxiosInstance } from "axios";
import ReactGA from "react-ga";
import store from "@redux/store";
import get from "lodash/get";
import { GLOBALS } from "@constants";
import { lockFlashes, editFlashes } from "@redux/actions/flashes";
import { Routing } from '@backendRouter';

export const headers: any = {
  "X-Requested-With": "XMLHttpRequest",
  "Content-Type": "application/ld+json",
  Accept: "application/ld+json",
  "Cache-Control": "no-cache, no-store, must-revalidate",
  Pragma: "no-cache",
  Expires: "0",
};

const logErrorToAPI = (error) => {
  const url = Routing.generate("api_create_log");
  
  return instance.post(url, {
    level: 400,
    message: error.message,
    context : {
      requestedResource: error.request.responseURL
    }
  });
};

const instance: AxiosInstance = axios.create({
  // timeout: 5000,
  headers,
});

instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async (error) => {

    if(error.message === GLOBALS.ERROR_404) {
      await logErrorToAPI(error); 
    }

    
    if (error.message === GLOBALS.ERROR_401) {
      store.dispatch(editFlashes([]));
      store.dispatch(lockFlashes());

      if(window.location.pathname !== "/login") {
        location.href = "/login";
      }
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  function(config) {
    const user = store && get(store.getState(), "user");

    if (user) {
      ReactGA.event({
        category: "api",
        action: config.url || "",
      });
    }

    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default instance;
