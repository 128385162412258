import { FETCH_TIMELINE } from "@redux/actionTypes";
import { Reducer } from "redux";

const defaultReducer = { data: {}, loading: false };

const timelinesReducer: Reducer<$Reducer_timelines, any> = (state = defaultReducer, action) => {
  switch (action.type) {
  case FETCH_TIMELINE:
    return action.payload;
  default:
    return state;
  }
};

export default timelinesReducer;