export const sortingStrategy = {
    depositDate: {
        desc: true,
    },
    title: {
        desc: false,
    },
    canton: {
        desc: false,
    },
    lastEventDate: {
        desc: true
    },
    firstName: {
        desc: false
    },
    lastName: {
        desc: false
    }
};
