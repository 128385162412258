import React, { Component } from "react";
import { connect } from "react-redux";
import { FormControl } from "../BaseUI";
import MultipleSelect from "../SelectPickers/MultipleSelect";
import { mapToOptions } from '@helpers';
import { trans } from "@trans";
import { ROLES } from "@constants";

type $Props = {
  onChange: (Event) => void;
  margin?: "none" | "dense" | "normal" | undefined;
  value?: string[];
  defaultAll?: boolean;
  user: $User;
  iri?: boolean;
  disabled?: boolean
};
type $State = {
  groupsSelected: string[];
  error: boolean;
};

class UserGroupSelect extends Component<$Props, $State> {
  constructor(props: $Props) {
    super(props);
    const groupsSelected = (props.value && props.value.length) ? props.value : props.defaultAll
      ? props.user.company.userGroups.map((g) =>
        props.iri ? g["@id"] : g.id
      )
      : props.user.userGroups.map((g) => (props.iri ? g["@id"] : g.id));

    const isAdmin = props.user.roles.includes(ROLES.ROLE_COMPANY_ADMIN);

    this.options = mapToOptions((isAdmin
      ? props.user.company.userGroups
      : props.user.userGroups
    ).map((g) => ({ ...g, value: props.iri ? g["@id"] : g.id })));

    this.state = {
      groupsSelected,
      error: false,
    };
  }

  private options: $UI_SelectOption[];

  onChange = (e) => {
    const { value } = e.target;

    if (value.length === 0) {
      return null;
    }

    const newValue = value;

    this.setState({ groupsSelected: newValue }, () =>
      this.props.onChange(newValue)
    );
  };
  render() {
    const disabled =
      (this.props.value || this.state.groupsSelected || []).length < 2;
    const options = this.options.map((o) => ({ ...o, disabled }));

    return (
      <FormControl margin={this.props.margin || "normal"}>
        <MultipleSelect
          {...this.props}
          onChange={this.onChange}
          value={this.props.value || this.state.groupsSelected}
          placeholder={trans(`ui.select.user_groups.placeholder`, "")}
          label={trans(`ui.select.user_groups.label`)}
          options={options}
          isClearable={false} />
      </FormControl>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.user.data };
}

export default connect(mapStateToProps, null)(UserGroupSelect);
