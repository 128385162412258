import React from "react";
import { Header } from "./BaseUI";
import { trans } from "@trans";

export const Documents = ({ documents, title }: { documents: $Attachment[], title?: string }) => (
  <div>
    {title && <Header>{title}</Header>}
    <ul>
      {Object.values(documents).map((a, i) => (
        <li key={i}>
          <a href={a.path} target='_blank'>
            {a.title ||
              (
                trans("affair.genericAttachments.download") ||
                "Download attachment #%number%"
              ).replace("%number%", i + 1)}
          </a>
        </li>
      ))}
    </ul>
  </div>
);
