import axios, { AxiosResponse } from "axios";
import { Routing } from "@backendRouter";

const CancelToken = axios.CancelToken;
export let cancel;

export function searchAffairs(bodyFormData = {}) {
  const url = Routing.generate("api_search_affairs_custom_get");
  const data = { api: 1, ...bodyFormData };

  return axios({
    method: "POST",
    url,
    data,
    cancelToken: new CancelToken(function (c) {
      cancel = c;
    })
  });
}

export function searchCouncillors(bodyFormData = {}) {
  const url = Routing.generate("api_search_councillors_get");
  const data = { api: 1, ...bodyFormData };

  return axios({
    method: "POST",
    url,
    data,
    cancelToken: new CancelToken(function (c) {
      cancel = c;
    })
  });
}

export function searchEvents(bodyFormData = {}): Promise<AxiosResponse<{ "hydra:member": $Event[] }>> {
  const url = Routing.generate("api_search_events_custom_get");
  const data = { api: 1, pagination: false, ...bodyFormData };

  return axios({
    method: "POST",
    url,
    data,
    cancelToken: new CancelToken(function (c) {
      cancel = c;
    })
  });
}

export function getSearchConfigs(params = {}) {
  const url = Routing.generate("api_search_configs_get_collection", {
      pagination: false,
      ...params
  });

  return axios.get(url);
}

export function saveSearchConfig(data = {}) {
  const url = Routing.generate("api_search_configs_post_collection");

  return axios({
    method: "POST",
    url,
    data
  });
}

export function editSearchConfig(id, bodyFormData = {}) {
  const url = Routing.generate("api_search_configs_put_item", { id });

  return axios.put(url, bodyFormData);
}

export function deleteSearchConfig(id) {
  const url = Routing.generate("api_search_configs_delete_item", { id });

  return axios.delete(url);
}