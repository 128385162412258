export const baseStyles = {
  styles: {
    columns: [
      { width: 150 },
      { width: "1%", whiteSpace: "nowrap", paddingLeft: 10, paddingRight: 10 },
      { textAlign: "left" },
      { width: "1%" },
      { width: 100 },
      { width: 120 },
      { width: "1%", whiteSpace: "nowrap" }
    ]
  }
};

