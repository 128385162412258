import { Reducer } from "redux";
import { EDIT_FLASHES, LOCK_FLASHES } from "../actions/flashes";

const defaultReducer = {
  data: [],
  isLocked: false
};


const flashesReducer: Reducer<$Reducer_flashes, any> = (state = defaultReducer, action) => {
  switch (action.type) {
  case EDIT_FLASHES:
    if(!state.isLocked) {
      return {...state, data: action.payload};
    }

    return {...state};
  case LOCK_FLASHES:
    return {...state, isLocked: true};
  default:
    return {...state};
  }
};

export default flashesReducer;