// This mapper is needed because the routes differ
// when the data language is updated
// https://app.politik.ch/national/committees/nr
// https://app.politik.ch/national/committees/sr

//     NR | SR
// ------------
// EN  nr | sr
// DE  nr | sr
// FR  cn | ce
// IT  cn | cs

export const councilSlugMapper = {
    'cn': 'NATIONAL_COUNCIL',
    'ce': 'COUNCIL_OF_STATES',
    'nr': 'NATIONAL_COUNCIL',
    'sr': 'COUNCIL_OF_STATES',
    'cs': 'COUNCIL_OF_STATES',
};

export const NATIONAL_COUNCIL_ID = 1;
