import React, { Component } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export class Paragraph extends Component<{
  open?: boolean,
  title?: string,
  children: any,
  className?: string,
  style?: object,
  renderTitle?: Function,
  onClick?: (MouseEvent) => void | (() => void)
}, { touched: boolean, open: boolean }> {

  constructor(props) {
    super(props);
    this.state = {
      touched: false,
      open: Boolean(props.open),
    };
  }

  toggle = () =>
    this.setState((state) => ({
      touched: true,
      open: !state.open,
    }));

  render() {
    const { onClick, title } = this.props;

    return (
      <Accordion onClick={onClick}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'>
          {this.props.renderTitle && <this.props.renderTitle />}
          {title}
        </AccordionSummary>
        <AccordionDetails>
          {React.Children.toArray(this.props.children)}
        </AccordionDetails>
      </Accordion>
    );
  }
}
