import React, {
  ReactNode,
  RefObject,
  useState,
  MouseEventHandler,
  ComponentClass,
  useEffect
} from "react";
import styled from "styled-components";
import Select, {
  components,
  MultiValueGenericProps,
  MultiValueProps,
  Props,
} from "react-select";
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortEndHandler,
  SortableHandle,
} from 'react-sortable-hoc';
import { oldVersion as TextField, Chip } from "../ui";
import Paper from "@material-ui/core/Paper";
import { trans } from "@trans";
import { COLORS, SHARED, SPACINGS } from "@constants";
import get from "lodash/get";
import { A, MenuItem } from "../BaseUI";
import Icon from "@components/Shared/InventoryIcon";

const NoOptionsMessage = (props: { children?: ReactNode, selectProps: object }) => {
  return props.children;
};

const inputComponent = ({ inputRef, ...props }) => {
  return (
    <StyledInputComponent
      ref={inputRef}
      data-test={props.name}
      {...props}
      style={{
        display: "flex",
        height: "inherit",
        justifyContent: "space-between",
      }} />
  );
};

const Control = (props: {
  children?: ReactNode,
  innerProps: { onMouseDown: Function },
  innerRef: RefObject<HTMLElement>,
}) => {
  const {
    children,
    innerProps,
    innerRef,
  } = props;

  return (
    <TextField
      fullWidth={true}
      onClick={(e) => {
        e.stopPropagation();
        const input = e.target.querySelector("input");

        if (input) {
          input.focus();
        }
      }}
      className='MuiInput-input__politik__multiple'
      InputProps={{
        inputComponent,
        inputProps: {
          ref: innerRef,
          children,
          ...innerProps,
        },
      }} />
  );
};

const Option = (props: {
  children: ReactNode,
  innerProps: {
    id: string,
    onClick?: Function,
    onMouseMove?: Function,
    onMouseOver?: Function,
    tabIndex: number,
  },
  /**
   * Inner ref to DOM Node
   */
  innerRef: RefObject<HTMLElement>,
  isFocused: boolean,
  isSelected: boolean,
  isDisabled?: boolean
}) => {
  return (
    <MenuItem
      ref={props.innerRef}
      disabled={props.isDisabled}
      selected={props.isFocused}
      className='politik__multiple_select__menu_item'
      component='div'
      style={{
        fontWeight: 400,
      }}
      {...props.innerProps}>
      <div data-test='group-select-menu-item'
        style={{
          maxWidth: "350px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}>
        {props.children}
      </div>
    </MenuItem>
  );
};

const Placeholder = (props: {
  children: ReactNode,
  innerProps: object,
  selectProps: object,
}) => {
  const { children } = props;

  return (
    <div style={{ height: SHARED.INPUT_HEIGHT, color: COLORS.GRAY4 }}>
      {children}
    </div>
  );
};

const Menu = (props: {
  /**
   * The children to be rendered.
   */
  children: ReactNode,
  /**
   * Props to be passed to the menu wrapper.
   */
  innerProps: object,
  selectProps: object,
}) => {
  return (
    <Paper
      data-test='group-select-menu'
      square={true}
      {...props.innerProps}
      style={{
        position: "absolute",
        fontFamily: "'Roboto', sans-serif",
        zIndex: 99,
        marginTop: "5px",
        left: 0,
        background: "#FFFFFF",
        boxShadow: "0px 7px 64px rgba(0, 0, 0, 0.12)",
        borderRadius: "6px",
      }}>
      {props.children}
    </Paper>
  );
};

const DropdownIndicator = ({ isDisabled, selectProps, options, isMulti }) => {
  return (
    !isDisabled ? (
      <div style={{ cursor: "pointer" }} onClick={e => {
        e.stopPropagation();
      }}>

        {isMulti && <i
          data-test='group-selector-all'
          title={trans("ui.select.all")}
          className='fas fa-check-double'
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            selectProps.onChange(options);
          }}
          style={{ color: "rgba(0, 0, 0, 0.54)", padding: `0 ${SPACINGS.X1}` }} />}
        <i
          data-test='group-selector-indicator'
          className='fas fa-caret-down'
          style={{ color: "rgba(0, 0, 0, 0.54)", padding: `0 ${SPACINGS.X1}` }} />
      </div>
    ) : null);
};

const arrayMove = <T extends unknown>(array: readonly T[], from: number, to: number) => {
  const slicedArray = array.slice();

  slicedArray.splice(
    to < 0 ? array.length + to : to,
    0,
    slicedArray.splice(from, 1)[0]
  );

  return slicedArray;
};

const SortableMultiValue = SortableElement(
  (props: MultiValueProps) => {
    const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const innerProps = { ...props.innerProps, onMouseDown };

    return (
      <Chip
        tabIndex={-1}
        className='multiple-select__chip'
        data-test='group-select-item'>
        <components.MultiValue {...props} innerProps={innerProps} />
      </Chip>);
  }
);

const SortableMultiValueLabel = SortableHandle((props: MultiValueGenericProps) => {
  const style = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    pointerEvents: "all",
    color: "inherit",
    backgroundColor: 'transparent'
  };

  return (
    <A
      to={get(props, "data.url", null)}
      style={style}>
      <components.MultiValueLabel {...props} />
    </A>
  );
}
);

const MultiValueRemove = (props: MultiValueGenericProps) => (
  <components.MultiValueRemove {...props}>
    <DeleteIcon data-test='group-select-item-delete'>
      <Icon icon='ui.close' />
    </DeleteIcon>
  </components.MultiValueRemove>
);

const customComponents = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  DropdownIndicator,
  MultiValueRemove,
};

const selectStyles = {
  input: (base) => ({
    ...base,
    "& input": {
      font: "inherit",
    },
  }),
  clearIndicator: (style) => ({
    ...style,
    cursor: "pointer",
  }),
  indicatorsContainer: (style) => ({
    ...style,
    height: SHARED.INPUT_HEIGHT,
    alignSelf: "center",
  }),
  placeholder: (style) => ({
    ...style,
    height: SHARED.INPUT_HEIGHT,
  }),
  //reset base style of react-select
  multiValue: () => ({
    display: 'flex'
  }),
  multiValueLabel: () => { },
  multiValueRemove: () => { }
};

const SortableSelector = SortableContainer(Select) as ComponentClass<
  Props & SortableContainerProps
>;

type $Props = {
  options: $UI_SelectOption[];
  value: any;
  onChange: Function;
  label?: string;
  loading?: boolean;
  allSelected?: boolean;
  error?: boolean;
  disabled?: boolean;
  placeholder?: string;
};

export const SortableSelect = (props: $Props) => {
  const { options, value, loading, disabled, placeholder } = props;

  const [multi, setMulti] = useState<$UI_SelectOption[]>([]);
  const [clicked, setClicked] = useState<boolean>(false);

  useEffect(() => {
    if (value.length === 0 && !clicked) {
      setMulti(options);
    }
    else {
      setMulti(value);
    }
  }, [value]);

  function handleChangeMulti(value) {
    setClicked(true);

    if (value) {
      setMulti(value);

      return;
    }

    setMulti([]);
  }

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(multi, oldIndex, newIndex);

    setMulti(newValue);
    updateValues(newValue);
  };

  const updateValues = (values) => {
    const newValues = Array.isArray(values) && values.length !== 0
      ? values
      : [];
      
    props.onChange({
      target: {
        value: newValues,
      }
    });
  };

  return (
    <SortableSelector
      useDragHandle={true}
      // react-sortable-hoc props:
      axis='xy'
      onSortEnd={onSortEnd}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      closeMenuOnSelect={false}
      styles={selectStyles}
      isDisabled={disabled}
      isLoading={loading}
      placeholder={placeholder}
      options={options}
      components={{
        ...customComponents,
        MultiValue: SortableMultiValue,
        MultiValueLabel: SortableMultiValueLabel,
      }}
      value={multi}
      onChange={(values) => {
        handleChangeMulti(values);
        updateValues(values);
      }}
      isMulti={true} />
  );
};

const DeleteIcon = styled.i`
  color: inherit;
  line-height: 100%;
  margin-left: 7px;
  cursor: pointer;
`;


const StyledInputComponent = styled.div`
  &.MuiInputBase-input {
    display: flex;
    height: inherit;
  }
`;