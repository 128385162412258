import { FETCH_AFFAIR_STATES } from "../actionTypes";
import { Reducer } from "redux";

const defaultReducer: $Reducer_affairStates = { data: { national: [], cantonal: [] }, loading: false };

const affairStatesReducer: Reducer<$Reducer_affairStates, any> = (state = defaultReducer, action) => {
  switch (action.type) {
  case FETCH_AFFAIR_STATES:
    return action.payload;
  default:
    return state;
  }
};

export default affairStatesReducer;
