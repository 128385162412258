import React, { useState } from "react";
import styled from "styled-components";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import { IconButton}  from "./IconButton";
import { ClickAwayListener } from "./ClickAwayListener";

const PopoverWithStyles = styled(Popper)`
  & .MuiPaper-root {
    overflow: visible;
  }
`;
const PopoverContent = styled.div`
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;

export function Action({
  children,
  icon,
  title,
  disabled,
  options,
  color
}: {
  children?: React.ReactChildren,
  icon: string,
  title?: string,
  disabled?: boolean,
  options: ({ func?: Function, Comp?: any, text?: string })[],
  color?: string
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div title={title}>
      <IconButton
        aria-describedby={id}
        variant='contained'
        color={color || 'primary'}
        onClick={(e) => {
          if (children || options) {
            handleClick(e);
          }
        }}
        disabled={disabled}
        icon={icon} />

      {(children || options) && (
        <PopoverWithStyles
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <PopoverContent>
                {React.Children.toArray(children).map((el: any) =>
                  React.cloneElement(el, { close: handleClose })
                )}
                {options &&
                  options.map((o, i) => (
                    <MenuItem
                      key={i}
                      onClick={(e) => {
                        handleClose();

                        if (o.func) {
                          o.func();
                        }
                      }}>
                      {o.Comp ? <o.Comp /> : o.text}
                    </MenuItem>
                  ))}
              </PopoverContent>
            </ClickAwayListener>
          </Paper>
        </PopoverWithStyles>
      )}
    </div>
  );
}
