import React, { Component } from "react";
import { trans } from "@trans";
import { isAffair, getParentAffair, isConsultation, getItemUrl } from '@helpers';
import { ActionBarItem } from "./ActionBarItem";
import { assignAffairsToGroups } from "@api/groups";
import { Button } from "@components/Shared/BaseUI";
import { GroupsSelect, MultipleSelect } from "@components/Shared/SelectPickers";
import { showDangerAlert, showSuccessAlert } from "@components/Shared/NotificationPopup";
import { Routing } from '@backendRouter';
import { COLORS, SPACINGS } from "@constants";
import { assignAffairToAgendaPoint, getAgendaPointsBasic } from "@api/customMeetings";


type $Props = { selectedItems: ($Affair | $Event["xPltkParent"])[], onSuccess?: Function, close?: () => void, color?: string };

class ActionContent extends Component<$Props, { loading: boolean, groupsSelected: string[], meetingsSelected: string[], data: $AgendaPoint[], disabled: boolean }> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      groupsSelected: [],
      meetingsSelected: [],
      data : [],
      disabled: false,
    };
  }
  componentDidMount = () => {
    this.fetchData();
  }
  fetchData = () => {
    this.setState({ loading : true});
    getAgendaPointsBasic({
      pagination: false,
    })
      .then(({ data }) => {
        const allAgendaPoints = data["hydra:member"].map((agendaPoint) => ({
          ...agendaPoint,
          title: `${agendaPoint.parent && agendaPoint.parent.title ? `${agendaPoint.parent.title} \\ ` : ""
          }${agendaPoint.title}`,
          url: getItemUrl(agendaPoint.parent),
        }));

        this.setState({ data: allAgendaPoints });
      })
      .catch(() => {
        showDangerAlert(trans("customMeetings.assignAgendaPoint.error"));
      })
      .finally (() => this.setState({loading : false}));
  }
  onSubmit = () => {
    this.setState({ disabled: true, loading: true });
    const groups = this.state.groupsSelected.map((id) =>
      Routing.generate("api_affair_groups_get_item", { id })
    );

    const body = this.props.selectedItems
      .map((item) => (isAffair(item) || isConsultation(item)) ? item : getParentAffair(item))
      .filter((x) => x)
      .map((tableItem) => groups.map((group) => [tableItem, group]))
      .flat();

    if (this.state.meetingsSelected.length > 0) {
      this.addAffairsToMeetings();
    }

    if (this.state.groupsSelected.length > 0) {
      this.assignAffairsToGroups(body);
    }
  };

  assignAffairsToGroups = (body) => {
    assignAffairsToGroups(body)
      .then(() => {
        showSuccessAlert(trans("affairGroups.assign.success"));

        if (this.props.onSuccess) {
          this.props.onSuccess(this.props.selectedItems);
        }

        if (this.props.close) {
          this.props.close();
        }
      })
      .catch(() => showDangerAlert(trans("affairGroups.assign.error")))
      .finally (() => this.setState({loading : false, disabled : false}));
  }

  addAffairsToMeetings = () => {
    Promise.all(this.state.meetingsSelected.map(meeting => {
      this.props.selectedItems.map(item => assignAffairToAgendaPoint(meeting,item));
    }))
      .then(() => {
        showSuccessAlert(trans("customMeetings.assignAgendaPoint.success"));

        if (this.props.onSuccess) {
          this.props.onSuccess(this.props.selectedItems);
        }
      
        if (this.props.close) {
          this.props.close();
        }
      })
      .catch(() =>
        showDangerAlert(trans("customMeetings.assignAgendaPoint.error"))
      )
      .finally (() => this.setState({loading : false, disabled : false}));
  }

  render() {
    return (
      <div>
        <div style={{ width: 400, maxWidth: "100vw" }}>
          <p>
            <div style={{ fontWeight: 700 }}>
              {trans("layout.affair_groups")}
            </div>
            <a
              href={Routing.generate("user_affairgroup_list")}
              style={{ textDecoration: "underline", fontSize: 14 }}>
              {trans(
                "ui.table.header.actions.group_assign.manage_groups",
                "Manage groups"
              )}
            </a>
          </p>
          <GroupsSelect
            disabled={this.state.disabled}
            value={this.state.groupsSelected}
            onChange={(e) => this.setState({ groupsSelected: e.target.value })} />
        </div>
        <div style={{ width: 400, maxWidth: "100vw", marginTop: SPACINGS.X3 }}>
          <p>
            <div style={{ fontWeight: 700 }}>
              {trans("layout.custom_meetings")}
            </div>
            <a
              href={Routing.generate("custom_meetings_list")}
              style={{ textDecoration: "underline", fontSize: 14 }}>
              {trans("customMeetings.editMeeting.title")}
            </a>
          </p>
          <MultipleSelect
            value={this.state.meetingsSelected}
            disabled={this.state.disabled}
            label={trans("layout.custom_meetings")}
            onChange={(e) => this.setState({ meetingsSelected: e.target.value })} 
            options={this.state.data} />
        </div>
        <div className='d-flex justify-content-end mt-2'>
          <Button
            data-test='group-assign-save'
            className='mt-1'
            type='button'
            loading={this.state.loading}
            disabled={(this.state.groupsSelected.length === 0 && this.state.meetingsSelected.length === 0) || this.state.disabled}
            onClick={this.onSubmit}>
            {trans("ui.submit")}
          </Button>
        </div>
      </div>
    );
  }
}

export function GroupsAssign({ selectedItems, onSuccess, close }: $Props) {
  return (
    <ActionBarItem
      title={trans("ui.table.header.actions.group_assign")}
      selectedItems={selectedItems}
      disabled={selectedItems.length === 0}
      icon='groups.actions.assign'
      color={selectedItems ? COLORS.INTERACTIVE : COLORS.PRIMARY}>
      <ActionContent selectedItems={selectedItems} onSuccess={onSuccess} close={close} />
    </ActionBarItem>
  );
}
