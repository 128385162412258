import React, { useState, useEffect, CSSProperties, FC, MouseEventHandler } from 'react';
import styled from "styled-components";
import { COLORS, GREYTONES, SPACINGS, SECONDARY_COLORS } from "@constants";
import Icon from "../Shared/InventoryIcon";
import { createVisualizerPublicLink } from '@helpers';

const StyledConfigMenuItem = styled.div`
  color: ${COLORS.DARK_GREY};
  font-size: 14px;
  line-height: 20px;
  padding-left: ${SPACINGS.X2};
  padding-right: ${SPACINGS.X2};
  margin-bottom: ${SPACINGS.X1};
  display: flex;
  align-items: center;
  transition: background-color 0.2s linear;
  &:hover {
    color: ${GREYTONES.BLACK};
  }
`;
export const SaveConfigMenuItem = styled.div`
  ${(props) => (props.isDisabled
    ? `color: ${COLORS.MEDIUM_GREY}`
    : `color: ${COLORS.PRIMARY};
      &:hover {
        color: ${SECONDARY_COLORS.DARK_GREEN};
      }
    `
  )}
  font-size: 14px;
  line-height: 20px;
  transition: background-color 0.2s linear;
`;
export const UpdateConfigMenuItem = styled.div`
  color: ${COLORS.DARK_GREY};
  font-size: 14px;
  line-height: 20px;
  transition: background-color 0.2s linear;
  ${(props) => (props.isDisabled
    ? `color: ${COLORS.MEDIUM_GREY}`
    : `&:hover { color: ${GREYTONES.BLACK}; }`
  )}
`;

interface ConfigMenuItemProps {
  config: {
    name?: string;
    title?: string;
    private?: boolean;
    shared?: boolean;
    id: string;
  };
  handleEdit: MouseEventHandler;
  handleDelete: MouseEventHandler;
  loadSelectedConfig: () => void; 
  shouldRenderAccessIcons: boolean;
  accessIcon?: string;
}

export const ConfigMenuItem: FC<ConfigMenuItemProps> = ({
  config,
  handleEdit,
  handleDelete,
  loadSelectedConfig,
  shouldRenderAccessIcons,
  accessIcon
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [noDisplay, setNoDisplay] = useState<boolean>(!isHovered);

  // Note: The config has either title (for reports) OR name (for search templates)
  const { name, title, private: isPrivate, shared, id } = config;
  const hasAccessIcon = isPrivate || shared;

  // We need to take into account the CSS transitions of 0.2s
  // and apply the same delay to the DOM manipulations as well
  useEffect(() => {
    if (!isHovered) {
      setTimeout(() => setNoDisplay(true), 200);
    }
    else {
      setNoDisplay(false);
    }
  }, [isHovered]);

  const itemContainerStyles = (type: string): CSSProperties => ({
    width: isHovered ? "calc(100% - 44px)" : "100%",
    transition: "width 0.2s linear",
    display: 'flex',
    alignItems: 'center',
    cursor: type === '' ? 'alias' : 'pointer',
  });

  const itemStyles: CSSProperties = {
    width: shouldRenderAccessIcons ? 'calc(100% - 14px)' : '100%',
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  };

  const iconStyles: CSSProperties = {
    width: '14px',
    marginRight: SPACINGS.X1,
    visibility: hasAccessIcon ? "visible" : 'hidden'
  };

  const handleClick = () => {
    if (config["@type"] === "VisualizerConfig") {
      window.open(createVisualizerPublicLink(id));
    }
    else {
      loadSelectedConfig();
    }
  };

  return (
    <StyledConfigMenuItem
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <div style={{
        width: "100%",
        display: 'flex'
      }}>
        <div style={itemContainerStyles(config["@type"])}>
          {shouldRenderAccessIcons && accessIcon &&
            <Icon icon={accessIcon} style={iconStyles}/>
          }
          <span onClick={handleClick} style={itemStyles}>
            {name || title}
          </span>
        </div>
        {isHovered &&
          // hiding the edit and delete icons after 0.2 seconds
          <div style={{ display: "flex", ...(noDisplay && { display: "none" }) }}>
            <Icon
              icon='ui.edit3'
              onClick={handleEdit}
              style={{ margin: `0px ${SPACINGS.X1}`, cursor: "pointer" }} />
            <Icon
              icon='ui.delete_x'
              onClick={handleDelete}
              style={{ color: COLORS.ERROR, cursor: "pointer" }} />
          </div>
        }
      </div>
    </StyledConfigMenuItem>
  );
};