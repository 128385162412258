import React, { Component } from "react";
import { Routing } from '@backendRouter';
import { trans } from "@trans";
import { getDate, getPartyAbbr } from '@helpers';
import { uniq, last } from "lodash";

const ListItem = ({ affair }: { affair: $Affair }) => (
  <div className='row cantonal-affair__list__item'>
    <div className='col-12 col-sm-2'>
      {affair.canton && (
        <img
          src={affair.canton.logoPath}
          className='m-1'
          alt={`(${affair.canton.abbreviation})`}
          title={affair.canton.title} />
      )}

      <span className='badge badge-secondary m-1'>
        {affair.affairType.abbr}
      </span>
      <span className='badge badge-info m-1'>
        {getDate(affair.depositDate)}
      </span>
      {affair.code && (
        <a
          href={Routing.generate("cantonal_show_affair", {
            cantonSlug: affair.canton?.abbreviation?.toLowerCase(),
            affair: affair.id
          })}
          className='badge badge-primary session-overview m-1'>
          {affair.code}
        </a>
      )}
    </div>
    <div className='col-12 col-sm-6'>
      <div className='row'>
        <div className='col-12'>
          <a
            href={Routing.generate("cantonal_show_affair", {
              cantonSlug: affair.canton?.abbreviation?.toLowerCase(),
              affair: affair.id
            })}>
            {affair.title}
          </a>
        </div>
      </div>
    </div>
    <div className='col-12 col-sm-4'>
      {(affair.authors || []).map((author, index) => (
        <div className='row' key={"id" in author ? author.id : index}>
          <div className='col'>
            <a
              href={Routing.generate("cantonal_show_councillor", {
                cantonSlug: affair.canton?.abbreviation?.toLowerCase(),
                councillorSlug: "slug" in author ? author.slug : null
              })}>
              {"fullName" in author ? author.fullName : ""}
            </a>
          </div>
          <div className='col'>
            {"party" in author && (
              <span className='party-label'>{getPartyAbbr(author.party)}</span>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
);

export class AffairList extends Component<{
  affairs: $Affair[]
}, { daySelected: "all" | number }> {
  constructor(props) {
    super(props);
    this.state = {
      daySelected: 'all'
    };
  }

  getDates = () => uniq(this.props.affairs.map(a => getDate(a.depositDate)));

  getEventsInDay = day =>
    this.props.affairs.filter(a => getDate(a.depositDate) === day);

  render() {
    const dates = this.getDates();
    const datesFiltered = this.state.daySelected === 'all' ? dates : [dates[this.state.daySelected]];

    return (
      <div id='cantonal-affair__list'>
        <div className='row'>
          <p className='col-12'>
            {dates[0]} – {last(dates)}
          </p>
        </div>
        <hr className='councillor-separator' />
        <div className='row'>
          <div className='col-sm-12'>
            <ul id='sessionDatesNavigation' className='nav nav-pills mb-0'>
              <li className='nav-item'>
                <span
                  className={`cursor-pointer nav-link  ${this.state.daySelected === 'all' ? "active" : ""
                  }`}
                  onClick={() => this.setState({ daySelected: 'all' })}>
                  {trans("session.dayFilter.all")}
                </span>
              </li>
              {dates.map((date, i) => (
                <li className='nav-item' key={date}>
                  <span
                    className={`cursor-pointer nav-link  ${this.state.daySelected === i ? "active" : ""
                    }`}
                    onClick={() => this.setState({ daySelected: i })}>
                    {date}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <hr className='councillor-separator' />
        {datesFiltered.map(date => (
          <div className='card historyEvent-overview' id={date} key={date}>
            <div className='card-header card-header-info'>{date}</div>
            <div className='card-body'>
              <div className='list-group'>
                {this.getEventsInDay(date).map((a, i) => (
                  <React.Fragment key={a.id}>
                    {i !== 0 && <hr className='row same-affair-separator' />}
                    <ListItem affair={a} />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        ))}

        <style>{`
      .cantonal-affair__list__item img{
        height: 20px;
      }
    `}</style>
      </div>
    );
  }
}
