import React, { FunctionComponent, useRef, useState } from 'react';
import { ButtonBordered } from '../BaseUI';
import { IconButton } from '../IconButton';
import { trans } from "@trans";
import { COLORS } from '@constants';
import { showDangerAlert } from "@components/Shared/NotificationPopup";

type $Props = {
  label?: string;
  handleFileEvent: (ev: any) => void;
  handleRemoveUploaded: () => void;
  className?: string,
  maxSize?: number
};

export const FileUploader: FunctionComponent<$Props> = ({
  handleFileEvent,
  label = trans('file_upload.btn_label'),
  handleRemoveUploaded,
  className,
  maxSize
}) => {
  const [value, setValue] = useState('');
  const [fileName, setFileName] = useState('');
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (!hiddenFileInput || !hiddenFileInput.current) {
      return;
    }

    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];

    if(maxSize && fileUploaded.size > maxSize) {
      showDangerAlert(trans('file_upload.file_too_big.error'));

      return;
    }

    setValue(event.target.value);

    setFileName(fileUploaded.name);
    handleFileEvent(event);
  };

  const removeUploadedFile = () => {
    setValue('');
    setFileName('');
    handleRemoveUploaded();
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} className={className}>
      <ButtonBordered onClick={handleClick} type='button'>
        {label}
      </ButtonBordered>
      <div style={{ marginLeft: 10 }}>
        {fileName ? fileName : trans('file_upload.no_file')}
      </div>
      {value && (
        <IconButton
          style={{ fontSize: '1em' }}
          title={trans('file_upload.remove')}
          icon='ui.delete'
          color={COLORS.ERROR}
          onClick={removeUploadedFile}/>
      )
      }
      <input
        value={value}
        type='file'
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }} />
    </div>
  );
};