import React, { CSSProperties, memo } from "react";
import get from "lodash/get";
import icon_inventory from "../../../../../icon_inventory.json";
import FeatherIcon from 'feather-icons-react';


const FIcon = ({ icon, ...props }) => {

  return <FeatherIcon icon={icon} size='1em' strokeWidth='2px'
    {...props} />;
};

const Ico = ({ icon, mod = "", children, className, style = {}, ...props }: {
  icon: string,
  mod?: string,
  children?: React.ReactChildren | string,
  className?: string,
  style?: CSSProperties,
  title?: string,
  color?: string,
  onClick?: (event: any) => void
}) =>
  get(icon_inventory, icon, '').indexOf("feather-") === 0 ? (
    <FIcon
      icon={get(icon_inventory, icon).replace("feather-", "")}
      className={className}
      style={{ color: "inherit", ...style }}
      {...props} />
  ) : (
    <i
      {...props}
      style={style}
      className={(
        get(icon_inventory, icon) ||
          get(icon_inventory, "affair.timeline.default")
      ).concat(" ", mod, " ", className || "")}>
      {React.Children.toArray(children)}
    </i>
  );

export default memo(Ico);
