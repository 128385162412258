import React from "react";
import styled from 'styled-components';
import { Routing } from '@backendRouter';
import { trans } from "@trans";
import { getFactionName, getPartyAbbr, limitLength } from '@helpers';
import get from "lodash/get";
import { A } from "./BaseUI";

export const FactionSpan = styled.span`
.fraction-label {
  display: inline-block;
  border-radius: 4px;
  margin: 2px 0 2px 0;
  padding: 3px 11px;
  color: gray("100");
  background-color: gray("600");
}

.fraction-label.fraction-c {
  background-color: #d6862b;
}

.fraction-label.fraction-m-ceb {
  background-color: #d6862b;
}

.fraction-label.fraction-rl {
  background-color: #3872b5;
}

.fraction-label.fraction-bd {
  background-color: #ffdf01;
}

.fraction-label.fraction-v {
  background-color: #4b8a3e;
}

.fraction-label.fraction-g {
  background-color: #84b547;
}

.fraction-label.fraction-gl {
  background-color: #c4c43d;
}

.fraction-label.fraction-s {
  background-color: #f0554d;
}

.fraction-label.fraction-na {
  background-color: #e1e1df;
}
`;


const getPartyUrl = (party, canton) => {
  if (party["@type"] === "CantonalParty") {
    return canton
      ? Routing.generate("cantonal_show_party", {
        cantonSlug: canton.slug,
        partySlug: party.slug
      })
      : null;
  }

  if (party["@type"] === "NationalParty") {
    return Routing.generate("show_parties", {
      slug: party.slug
    });
  }

  if (party["@type"] === "CantonalFaction") {
    return canton
      ? Routing.generate("cantonal_show_faction", {
        factionSlug: party.slug,
        cantonSlug: canton.slug
      })
      : null;
  }

  if (party["@type"] === "NationalFaction") {
    return Routing.generate("show_factions", {
      slug: party.slug
    });
  }

  return null;
};

export const Party = ({ party, className = "", canton }: { party: $Party | $Faction, className?: string, canton?: $Canton }) => {
  const isFaction = party["@type"].includes("Faction");
  const abbr = getPartyAbbr(party).toLowerCase();

  return (
    <A
      to={getPartyUrl(party, get(party, "canton", canton))}
      title={party.title ||
        party.fullName ||
        trans(`party.${party["@type"]}`)}>
      <span
        className={`text-uppercase ${isFaction ? `fraction-label ${abbr}` : `party-label ${abbr}`
        } text-nowrap ${className}`}
        style={{ lineHeight: "initial" }}>
        {limitLength(isFaction? getFactionName(party) : getPartyAbbr(party), 25)}
      </span>
    </A>
  );
};
