import React, { memo } from "react";
import { InlineBadge as Badge, ManagedBadge, A, PrivateBadge } from "../Shared/BaseUI";
import { get } from 'lodash';
import { trans } from "@trans";
import { getItemUrl } from "@helpers";
import { COLORS } from "@constants";
import { SEARCH_TYPES } from '@constants';

const Badges = ({ affair }) => {
  const type = affair["categories"] || affair["@type"];
  const CODE_INDEX = 0;
  const DEPARTMENT_INDEX = 0;
  
  if (type === SEARCH_TYPES.NATIONAL_AFFAIR) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {(affair.slug || get(affair, 'codes.length')) && <A to={getItemUrl(affair)} style={{ width: '100%' }}><Badge>{affair.slug || get(affair, 'codes.0')}</Badge></A>}
        {affair.affairType && (
          <Badge background={COLORS.GRAY3}>{affair.affairType.abbr}</Badge>
        )}
        {affair.managed && <ManagedBadge />}
        {affair.private && <PrivateBadge />}
        {affair.xPltkTags && affair.xPltkTags.filter(tag => !(affair.codes || []).includes(tag.label)).map(tag => <Badge key={tag.label}>{tag.label}</Badge>)}
      </div>
    );
  }

  if (type === SEARCH_TYPES.CANTONAL_AFFAIR) {
    return (
      <div>
        {affair.codes && (
          <A to={getItemUrl(affair)}>
            <Badge>{affair.codes[CODE_INDEX]}</Badge>
          </A>
        )}
        {affair.affairType && (
          <Badge background={COLORS.GRAY3}>{affair.affairType.abbr}</Badge>
        )}
        {affair.managed && <ManagedBadge />}
      </div>
    );
  }

  if (type === SEARCH_TYPES.CUSTOM_AFFAIR) {
    return (
      <div>
        {affair.internalId && <A to={getItemUrl(affair)}><Badge text={affair.internalId} /></A>
        || affair.codes && <A to={getItemUrl(affair)}><Badge text={affair.codes[CODE_INDEX]} /></A>}
        {affair.shortIdParlService && (
          <A to={getItemUrl(affair)}><Badge text={affair.shortIdParlService} /></A>
        )}
        {affair.affairType && <Badge text={affair.affairType.title} />}
        {affair.affairState && <Badge text={affair.affairState.title} />}
        {affair.topics &&
          affair.topics.map((topic, i) => (
            <Badge key={i} text={topic.title} />
          ))}
      </div>
    );
  }

  if (
    type === SEARCH_TYPES.NATIONAL_AFFAIR_CONSULTATION ||
    type === SEARCH_TYPES.CANTONAL_AFFAIR_CONSULTATION ||
    type === "AffairGroup" ||
    type === "CustomMeeting" ||
    type === "CustomMeetingAgendaPoint"
  ) {
    return (
      <div>
        {/* This is for all other affair types */}
        {affair.departments && type !== SEARCH_TYPES.NATIONAL_AFFAIR_CONSULTATION && (
          <Badge
            text={affair.departments[DEPARTMENT_INDEX].abbr}
            title={affair.departments[DEPARTMENT_INDEX].name} />
        )}
        {affair.managed && <ManagedBadge inline={true} />}
        {affair.link && (
          <A
            to={affair.link}
            target='_blank'
            title={trans("affair.originalSource")}>
            <Badge icon={"affair.link"} interactive={true} />
          </A>
        )}
      </div>
    );
  }


  return null;
};

export default memo(Badges);