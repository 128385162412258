import React, { ReactNode, CSSProperties } from "react";
import { ButtonLight } from "./BaseUI";
import styled, { keyframes } from "styled-components";

const appear = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const Cover = styled.div`
  position: fixed;
  z-index: 999;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  animation: ${appear} 0.3s linear;
  overflow:auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const ModalBox = styled.div`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  min-height: 100px;
  cursor: initial;
  width: fit-content;
  display: table;
`;

const CloseButton = styled(ButtonLight)`
  position: absolute;
  top: 12px;
  right: 18px;
  z-index: 8;
  font-size: 20px;
  color: #fff;
`;

export const Modal = ({ children, close, style }: { children: ReactNode, close: Function, style?: CSSProperties }) => (
  <Cover
    className='politik-modal'
    style={style}
    onClick={e => {
      if (e.target.classList && e.target.classList.contains("politik-modal")) {
        close();
      }
    }}>
    <ModalBox>
      <CloseButton onClick={close}>
        <i className='fas fa-times' />
      </CloseButton>
      {React.Children.toArray(children)}
    </ModalBox>
    <style>{`
        body, html {
          overflow: hidden;
        }
        body {
          padding-right: 15px;
      }
    `}</style>
  </Cover>
);
