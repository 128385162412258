import axios from "./instance";
import { Routing } from "@backendRouter";

export function editCompany(id, body = {}) {
  const url = Routing.generate("api_companies_put_item", { id });

  return axios.put(url, body);
}

export function deleteLogo(id) {
  const url = Routing.generate("api_custom_media_objects_delete_item", { id });

  return axios.delete(url);
}


export function getUserGroups() {
  const url = Routing.generate(`api_user_groups_get_collection`);

  return axios.get(url);
}

export function getUserGroup(id) {
  const url = Routing.generate(`api_user_groups_get_item`, { id });

  return axios.get(url);
}

export function editUserGroup(id, body) {
  const url = Routing.generate(`api_user_groups_put_item`, { id });

  return axios.put(url, body);
}

export function createUserGroup(body) {
  const url = Routing.generate(`api_user_groups_post_collection`);

  return axios.post(url, body);
}

export function deleteUserGroup(id) {
  const url = Routing.generate(`api_user_groups_delete_item`, { id });

  return axios.delete(url);
}

export function getUserGroupRoles() {
  const url = Routing.generate(`api_custom_data_roles_get_collection`);

  return axios.get(url);
}

export function getCompanies(id) {
  const url = Routing.generate(`api_users_get_companies_item`, { id });

  return axios.get(url);
}

export function setUserCompany(id, company) {
  const url = Routing.generate(`api_users_put_switch_company_item`, { id });

  return axios.put(url, { company });
}
