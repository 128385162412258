import React from "react";
import ReactQuill, { Quill } from "react-quill";
import styled from "styled-components";
import { RenderHtmlWithAbsoluteLinks } from "../RenderHtmlWithAbsoluteLinks";
import { ImageUpload } from "quill-image-upload";
import { trans } from "@trans";
import { COLORS } from "@constants";
import { EmbededLabel } from "../BaseUI";

Quill.register("modules/imageUpload", ImageUpload); 

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    // [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],

    // [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    // [{ indent: "-1" }, { indent: "+1" }],
    // [{ direction: "rtl" }],

    [{ color: [] }, { background: [] }],
    // [{ font: [] }],
    [{ align: [] }],

    ["clean"],
    ["link", "image"],
  ],
  imageUpload: {
    // url: "", // server url. If the url is empty then the base64 returns
    // method: "POST", // change query method, default 'POST'
    // name: "image", // custom form name
    // withCredentials: false, // withCredentials
    // headers: {}, // add custom headers, example { token: 'your-token'}
    // csrf: { token: "token", hash: "" }, // add custom CSRF
    // customUploader: (a, b, c, d) => {
    //   console.log("a,b,c,d", a, b, c, d);
    //   b(a);
    // } // add custom uploader
    // personalize successful callback and call next function to insert new url to the editor
    // callbackOK: (serverResponse, next) => {
    //   next(serverResponse);
    // },
    // // personalize failed callback
    // callbackKO: serverError => {
    //   alert(serverError);
    // },
    // // optional
    // // add callback when a image have been chosen
    checkBeforeSend: (file, next) => {
      if (file.size > 5000000) {
        alert(trans("ui.text_editor.image.exceeds"));
      }
      else {
        next(file); // go back to component and send to the server
      }
    },
  },
};

const TextEditorWrapper = styled.div`
  word-break: break-word;
  position: relative;
  & .ql-toolbar.ql-snow .ql-formats span.ql-picker-label svg {
    transform: translateY(-4px);
  }
  & .ql-toolbar.ql-snow {
    border-radius: 4px 4px 0px 0px;
  }
  & .ql-container.ql-snow {
    border-radius: 0px 0px 4px 4px;
    border: 1p solid ${COLORS.GRAY3};
  }
  & .ql-container b,
  .ql-container strong {
    font-weight: bold;
  }
  & .ql-tooltip.ql-editing {
    z-index: 2;
  }
  ${(props) =>
    props.simple || props.disabled
      ? `
      & .ql-editor {
        padding: 9px 15px;
      }
  & .ql-toolbar {
    display: none;
  }
  & .ql-container.ql-snow {
    border-radius: 4px;
  }
  & .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid ${COLORS.GRAY3};
  }
  `
      : ""}
`;

type Props = {
  disabled?: boolean;
  value: string;
  placeholder?: string;
  label?: string;
  simple?: boolean;
  onChange: ((string: string) => void) | ((text: string, delta: any, source: string, editor: any) => void);
  wrapperClass?: string;
};

export const TextEditor: React.FC<Props> = (props) => {
  const {
    simple,
    disabled,
    value,
    placeholder,
    label,
    wrapperClass,
    onChange,
  } = props;

  return disabled ? (
    value ? (
      <TextEditorWrapper simple={props.simple} disabled={props.disabled}>
        {props.label && <EmbededLabel>{props.label}</EmbededLabel>}

        <RenderHtmlWithAbsoluteLinks
          className='ql-container ql-editor ql-snow'
          dangerouslySetInnerHTML={{
            __html: props.value,
          }}/>
      </TextEditorWrapper>
    ) : (
      <i className='ql-container ql-editor' style={{ color: COLORS.DISABLED }}>
        {props.label && <EmbededLabel>{props.label}</EmbededLabel>}
        {placeholder}
      </i>
    )
  ) : (
    <TextEditorWrapper simple={simple}>
      {label && <EmbededLabel>{label}</EmbededLabel>}
      <ReactQuill
        value={value}
        placeholder={placeholder}
        modules={modules}
        onChange={onChange}
        bounds={wrapperClass || ".main-panel"}/>
      <style>
        {`.ql-tooltip[data-mode=link]::before {
          content: "${trans('ui.text_editor.link_label')}" !important;
        }
        .ql-action::after {
          content: "${trans('ui.text_editor.save_btn')}" !important;
        }`}
      </style>
    </TextEditorWrapper>
  );
};
