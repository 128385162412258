import React, { memo, Children, ReactNode } from "react";
import { Header, Card } from "../BaseUI";
import { AffairList } from "./Table";
import { Pagination } from "@components/Shared/Pagination";
import { BreadCrumb } from '@components/Shared/BreadCrumb';
import { SPACINGS } from "@constants";

export const TableCardTitle = ({ children }: { children: ReactNode }) => (
  <div className='pl-3 pr-3 mb-1'>
    <span className='d-flex align-items-start'>
      <Header className='mr-2'>{Children.toArray(children)}</Header>
    </span>
  </div>
);

type $Props = $AffairTable_props & {
  config?: $AffairTable_config;
  searchType?: string;
  loading?: boolean;
  title?: string;
  children?: any;
  pagination?: any;
  breadcrumb?: any;
  className?: string;
  error?: boolean;
  sort?: any;
  actions?: $AffairTable_props["actions"];
  setRemoteSearchQuery?: Function;
}
const TableCard = ({
  data,
  searchType,
  user,
  config,
  loading,
  title,
  children,
  pagination,
  breadcrumb,
  className,
  setRemoteSearchQuery,
  ...props
}: $Props) => (
  <Card
    style={{ padding: 0 }}>
    {breadcrumb && <div style={{ padding: SPACINGS.X3, paddingBottom: 0 }}>
      <BreadCrumb links={breadcrumb} />
    </div>}
    {React.Children.toArray(children)}
    <AffairList
      setRemoteSearchQuery={setRemoteSearchQuery}
      data={data}
      searchType={searchType}
      user={user}
      config={config}
      loading={loading}
      pagination={pagination}
      title={title}
      {...props} />
    {pagination && (
      <div className='p-1'>
        <Pagination {...pagination} />
      </div>
    )}
  </Card>
);

export default memo(TableCard);