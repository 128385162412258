import React, {FC} from 'react';
import { trans } from "@trans";
import get from 'lodash/get';

export interface $Props {
  user: $User;
}

export const Footer: FC<$Props> = ({user}) => (
  <footer className='footer'>
    <div className='container-fluid'>
      <a href={get(user, 'settings.lang', 'De') === 'De' ?
        'https://politik.ch/impressum-datenschutz/' :
        'https://politique.ch/impressum-et-protection-des-donnees/'} target='_blank'>
        {trans('footer.label')}
      </a>
    </div>
  </footer>
);

