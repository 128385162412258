import { trans } from "@trans";
import get from "lodash/get";

export const columns: $AffairTable_Column[] = [
  "badges",
  "canton",
  "date",
  "title",
  "author",
  "topics",
  "status",
  "actions",
];
export const columnsOptional = [
  "topics",
  "status",
  "actions",
  "canton",
  "date",
  'author',
];
export const hiddenColumnsSmallScreen = [
  'author',
  'badges',
  'status',
  'topics'
];

export const baseConfig: $AffairTable_config = {
  uniqId: null,
  filters: {
    status: false,
    topics: false,
    author: false,
    year: false,
    timeRange: false,
  },
  sort: { title: false, date: false, status: false },
  labels: {
    badges: trans("affairGroup.export.header.id"),
    status: trans("national.affairs.list.header.status", "Status"),
  },
  columns: columns,
  hideColumns: [],
  search: false,
  pageSize: false,
  actions: false,
  dateFormat: "DD.MM.YYYY",
};

export const getConfig = (config, path) =>
  get(config, path, get(baseConfig, path));

export const baseStyles = {
  styles: {
    columns: {
      badges: { width: 90, padding: 4 },
      date: {
        width: "1%",
        whiteSpace: "nowrap",
        paddingLeft: 10,
        paddingRight: 10,
      },
      title: { textAlign: "left" },
      canton: { width: "1%", maxWidth: 50 },
      author: { width: 120 },
      topics: { width: 100 },
      status: { width: 120 },
      actions: { width: "1%", whiteSpace: "nowrap" },
    },
  },
};
