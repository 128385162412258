import axios from "./instance";
import { Routing } from "@backendRouter";
import { getAffairSection } from "@helpers";
import { GLOBALS } from "@constants";

export function getEvent(affair, eventSlug) {
  const url = Routing.generate(
    `api_${getAffairSection(affair)}_affair_event_custom_get`,
    {
      affairSlug: affair.slug,
      eventSlug
    }
  );

  return axios.get(url);
}

export function getCustomNote(id) {
  const url = Routing.generate("api_note_custom_events_get_item", {
    id
  });

  return axios.get(url);
}

export function getCustomEvent(id) {
  const url = Routing.generate("api_custom_events_get_item", {
    id
  });

  return axios.get(url);
}

export function deleteCustomEvent(id) {
  const url = Routing.generate("api_custom_events_delete_item", {
    id
  });

  return axios.delete(url);
}

export function putCustomEvent(id, body = {}) {
  const url = Routing.generate("api_custom_events_put_item", {
    id
  });

  return axios.put(url, body);
}

export function getEventReminders(itemId?, cls?, page = 1) {
  const url = Routing.generate(
    "api_event_reminders_get_collection",
    itemId || cls
      ? {
        itemId,
        itemClass: cls,
        page,
        pagination: false
      }
      : { pagination: false }
  );

  return axios.get(url);
}

export function editEventReminder(id, dateTime) {
  const url = Routing.generate("api_event_reminders_put_item", {
    id
  });

  return axios.put(url, { dateTime });
}

export function createEventReminder(itemId, itemClass, dateTime) {
  const url = Routing.generate("api_event_reminders_post_collection");

  return axios.post(url, { dateTime, itemClass, itemId });
}

export function shareEventToCalendar(event) {
  const url = Routing.generate("calendar_event_share");

  return axios.post(url, { event });
}

export function unshareEventFromCalendar(event) {
  const url = Routing.generate("calendar_event_unshare");

  return axios.post(url, { event });
}

export function deleteEventReminder(id) {
  const url = Routing.generate("api_event_reminders_delete_item", { id });

  return axios.delete(url);
}

export function getNationalVotes(page = 1, itemsPerPage = GLOBALS.DEFAULT_PAGE_SIZE) {
  const url = Routing.generate("api_national_council_votes_get_collection", {
    "order[voted]": "DESC",
    itemsPerPage,
    page
  });

  return axios.get(url);
}

// Please uncomment this when we resolve the CORS problem that causes https://politikch.atlassian.net/browse/POL-5935

// export function getTermineEvents(body = {from: '', to: ''}) {
//   return axios({
//     method: "GET",
//     headers: {
//       "Access-Control-Allow-Origin": "*",
//       "accept": "*/*",
//       "x-api-key": "20112020084336kmux3jsuzyhpqvuo2wcg06mshnuitv9s8zc2"
//     },
//     url: `https://termine.politik.ch/api/eventsByDateRange?${body.from ? `from=${body.from}&` : ''}${body.to ? `to=${body.to}` : ''}`
//   });
// }

export function getTermineEvents(body = {from: '', to: ''}) {
  return new Promise<any>(function(resolve, reject) {
    resolve({ data: {'hydra:member': [] }}); 
  });
}

export function getNationalCouncilVoteStats(params: {
  id: any,
  statType: string,
  type: string,
}) {
  const url = Routing.generate("api_national_council_vote_stats_custom_get",
  {
    id: params.id,
    statType: params.statType,
    voteType: params.type === "NRCouncilVote" ? "nr_council_votes" : "national_council_votes"
  });

  return axios.get(url);
};

export function getStatesCouncilVoteStats(params: {
  id: any,
  statType: string,
  type: string,
}) {
  const url = Routing.generate("api_council_states_vote_stats_custom_get",
  {
    id: params.id,
    statType: params.statType,
    voteType: params.type === "SRCouncilVote" ? "sr_council_votes" : "council_states_votes"
  });

  return axios.get(url);
};
