import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { Pagination } from "../Pagination";
import { LoadingCover } from "@components/Shared/BaseUI";
import { AffairItemList } from "./TableRow";
import { TableHead } from "./TableHead";
import { get, uniq, intersection, cloneDeep } from "lodash";
import { trans } from "@trans";
import {
  arrify,
  isAffair,
  isConsultation,
  isAffairEvent,
  orderBy,
  isCouncillor
} from '@helpers';
import { getters } from "./script";
import { baseConfig } from "./config";
import { ActionBar } from "./ActionBar";
import { ROLES, SEARCH_TYPES, SPACINGS } from "@constants";

const getAllAuthors = (affairs) =>
  uniq(affairs.map(getters.author).flat()).sort();
const getAllYears = (affairs) => uniq(affairs.map(getters.year).flat()).sort();
const getAllTopics = (affairs) =>
  uniq(affairs.map(getters.topics).flat()).sort();
const getAllStatuses = (affairs) => uniq(affairs.map(getters.status)).sort();
const getAllCantons = (affairs) =>
  uniq(affairs.map(getters.canton).flat()).sort();

const filterItems = (items: $AffairTable_props['data'], filters: $AffairTable_filters, config) => {
  const filteredItems: $AffairTable_props['data'] = Object.entries({ ...filters })
    .filter((f) => getters[f[0]])
    .reduce(
      (acc: $AffairTable_props['data'], [key, vals]) =>
        Array.isArray(vals) && vals.length > 0
          ? acc.filter(
            (item) =>
              intersection(vals, arrify(getters[key](item))).length > 0
          )
          : acc,
      items
    );

  const searchedAndFilteredItems = filters.search
    ? filteredItems.filter((item) =>
      filters.search
        .split(" ")
        .every((word) =>
          `${getters.badges(item)} ${getters.title(item)} ${getters.status(
            item
          )} ${getters.topics(item)} ${getters.author(item)} ${moment(
            getters.date(item)
          ).format(get(config, "dateFormat", "D.M.YYYY"))}`
            .toLowerCase()
            .includes(word.toLowerCase())
        )
    )
    : filteredItems;

  return filters.sort
    ? orderBy(
      searchedAndFilteredItems,
      Object.keys(filters.sort).map((k) => getters[k]),
      Object.values(filters.sort)
    )
    : searchedAndFilteredItems;
};

export const Table = styled.table`
  overflow: hidden;
  font-size: 13px;
  min-width: 100%;
  table-layout: fixed;
`;

export const TableWrapper = styled.div`
  position: relative;
  border-top: 1px solid gray;
`;

export function AffairList({
  data,
  searchType,
  user,
  actions,
  renderRowActions,
  sort = {},
  rowSorted,
  loading,
  config = baseConfig,
  timeRange,
  years = [],
  pagination,
  style = {},
  setRemoteSearchQuery,
  title,
}: $AffairTable_props) {
  const localStorageKey =
    (title || config.uniqId || "") &&
    location.pathname +
    user["@id"] +
    (config.uniqId || title || "").replaceAll(" ", "");
  const localStorageSavedConfig: $AffairTable_config | null = JSON.parse(
    localStorage.getItem(localStorageKey) || "null"
  );
  const pageSize =
    get(localStorageSavedConfig, "pageSize") ||
    config.pageSize ||
    get(user, "settings.list_size.value") ||
    30;

  const [filterOptions, setfilterOptions] = useState({});
  const [items, setItems] = useState<$AffairTable_props['data']>([]);
  const [filters, setFilters] = useState<$AffairTable_filters>({
    page: 1,
    search: "",
    pageSize,
  });

  const [checklist, setChecklist] = useState<($Affair | $Event["xPltkParent"])[]>([]);

  useEffect(() => {
    const availableYears = years || getAllYears(data);

    setfilterOptions({
      status: getAllStatuses(data),
      author: getAllAuthors(data),
      canton: getAllCantons(data) || [],
      topics: getAllTopics(data),
      date: availableYears,
    });

    setFilters((filters) => ({
      ...filters,
      sort: {},
      date: intersection(get(config, "filters.year", []), availableYears),
    }));
  }, [data]);

  useEffect(() => {

    const items = setRemoteSearchQuery ? data : filterItems(data, filters, config);

    setItems(items);
  }, [filters]);

  const canWrite = user && user.roles.includes(ROLES.ROLE_FULL_READWRITE);

  const showOptionalColumns = {
    canton: Boolean(items.find((a) => getters.canton || a['@type'] === SEARCH_TYPES.NATIONAL_AFFAIR) || searchType === SEARCH_TYPES.ALL_AFFAIRS),
    date: true,
    status: Boolean(items.find(getters.status) || searchType === SEARCH_TYPES.ALL_AFFAIRS),
    actions:
      canWrite &&
      Boolean(
        actions ||
        (user &&
          user.roles.includes(ROLES.ROLE_GROUPS_W) &&
          items.find(
            (a) => isAffair(a) || isConsultation(a) || isAffairEvent(a)
          ))
      ) && !config.hideColumns?.includes("actions"),
    topics:Boolean(items.find((a) => "topics" in a && a.topics && Array.isArray(a.topics) && a.topics.length > 0) !== undefined ||
     searchType === SEARCH_TYPES.ALL_AFFAIRS),
    author: Boolean(items.find(a => !isCouncillor(a)) || searchType === SEARCH_TYPES.ALL_AFFAIRS)
  };

  const actionsArray = actions ? Array.isArray(actions) ? actions : [actions] : [];
  const actionsFinal: $AffairTable_props["actions"] =
    showOptionalColumns.actions ? [
      ...actionsArray
    ] : [];

  // we need the renderRowActions prop in order to render row actions, but currently
  // too many components rely on the old actions, so we use renderRowActions only if it's present.
  // Otherwise we fallback to actions.
  // TODO: this has to be refactored, all across the application, we need complete
  // separation of actions (for the header) and renderRowActions (for the rows)!
  const rowActionsFinal = showOptionalColumns.actions ? renderRowActions : () => { };

  const showPageSizeSelect =
    get(pagination, "setItemsPerPage") || config.pageSize !== false;
  const itemsToShow =
    filters.pageSize && !get(pagination, "setItemsPerPage")
      ? items.slice(
        (filters.page - 1) * filters.pageSize,
        filters.page * filters.pageSize
      )
      : items;

  //Handling bulk assigning affairs/events to groups
  const handleOnSuccess = (selectedItems) => {
    const newItems: any = cloneDeep(items);

    selectedItems.forEach(item => {
      for (let i = 0; i < newItems.length; i++) {
        if (item["@id"] === newItems[i]["@id"]) {
          newItems[i].managed = true;
          break;
        }
      }
    });
    setItems(newItems);
    setChecklist([]);
  };

  return (
    <div className='politik-table' style={{ ...style, marginTop: SPACINGS.X3 }}>
      {!config.hideHeader && (actionsFinal || config.search || showPageSizeSelect) && (
        <div
          style={{
            padding: config.hideColumns?.includes('actions')
              ? `${SPACINGS.X3} ${SPACINGS.X3} ${SPACINGS.X2}`
              : `${SPACINGS.X3} ${SPACINGS.X1} ${SPACINGS.X2} ${SPACINGS.X3}`
          }}>
          <ActionBar
            setRemoteSearchQuery={setRemoteSearchQuery}
            user={user}
            localStorageKey={localStorageKey}
            checklist={checklist}
            actions={actionsFinal}
            config={config}
            filters={filters}
            pagination={pagination}
            showPageSizeSelect={showPageSizeSelect}
            setFilters={setFilters}
            setChecklist={setChecklist}
            items={items}
            title={title}
            onSuccess={handleOnSuccess}
            shouldShowDefaultActions={Boolean(showOptionalColumns.actions)} />
        </div>
      )}

      <TableWrapper>
        {loading && <LoadingCover className='animated-background' data-test='animated-background' />}
        <Table>
          {!config.hideHeader && <TableHead
            user={user}
            config={config}
            loading={loading}
            sort={sort}
            filters={filters}
            filterOptions={filterOptions}
            setFilters={setFilters}
            timeRange={timeRange}
            showOptionalColumns={showOptionalColumns} />}
          <tbody>
            {items.length > 0 ? (
              itemsToShow.map((item, index) => (
                <AffairItemList
                  key={index}
                  user={user}
                  item={item}
                  actions={renderRowActions ? rowActionsFinal : actionsFinal}
                  config={config}
                  // filterOptions={filterOptions}
                  showOptionalColumns={showOptionalColumns}
                  rowSorted={rowSorted}
                  checklist={checklist}
                  setChecklist={setChecklist} />
              ))
            ) : (
              <tr>
                <td className='text-center' colSpan={6}>
                  {loading ? "" : trans("affairs.list.empty")}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      {!pagination && (
        <div className='p-1'>
          <Pagination
            setPage={(page) => setFilters({ ...filters, page })}
            currentPage={filters.page}
            totalResults={items.length}
            itemsPerPage={filters.pageSize} />
        </div>
      )}
    </div>
  );
}
