import React from "react";
import { A as Link } from "@components/Shared/BaseUI";
import moment from "moment";
import styled from "styled-components";
import { Author } from "@components/Shared/Author";
import Badges from "@components/Affair/Badges";
import get from "lodash/get";
import { trans } from "@trans";
import {
  getItemUrl,
  getItemSearchAuthors,
  isConsultation,
  isCouncillor,
  isLinkedUser,
  getDateTime,
} from '@helpers';
import { COLORS, GLOBALS, SEARCH_TYPES, ROLES, GREYTONES } from '@constants';
import { getters, getAffairFromTableItem, getId } from "./script";
import { columns, columnsOptional, baseStyles } from "./config";
import {Checkbox} from "../ui";
import Sentry from "../../../script/sentry";

const AffairTitle = styled(Link)`
  color: ${COLORS.GRAY1};
`;

const CouncillorPortraitSmall = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
  -webkit-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
  -moz-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 50%;
`;

export const Td = styled.td``;

export const Tr = styled.tr`
  border-bottom: 1px #ddd solid;
  color: ${COLORS.GRAY1};
  &:nth-child(even) {
    background-color: ${(props) =>
    props.selected ? GREYTONES.GREY_MEDIUM : "rgba(220, 220, 220, 0.1)"};
  }
  ${(props) =>
    props.selected ? `background-color: ${GREYTONES.GREY_MEDIUM}` : ""}
  &:hover {
    background: ${(props) =>
    props.selected ? GREYTONES.GREY_MEDIUM : GREYTONES.GREY_LIGHT};
  }
  ${Td} {
    padding: 0px 5px 0px 5px;
    text-align: center;
    vertical-align: middle;
    &:nth-child(even) {
      background-color: rgba(220, 220, 220, 0.1);
    }
    ${(props) =>
    props.rowSorted
      ? `&:nth-child(${props.rowSorted}) {
        background-color: rgba(130,130,230,0.1);
    }`
      : ""}
  }
`;

const Col = ({ name, item, config, actions, selected, toggleCheckbox }: {
  name: $AffairTable_Column,
  item: $AffairTable_props['data'][0],
  config: $AffairTable_config,
  actions: $AffairTable_props["actions"],
  selected: boolean,
  toggleCheckbox: () => void
}) => {
  const affair: $Props["checklist"][0] | undefined = getAffairFromTableItem(item);

  if (name === "badges") {
    return (
      <Td style={baseStyles.styles.columns[name]} className='d-none d-md-table-cell'>
        {isCouncillor(item) ? (
          <CouncillorPortraitSmall
            src={item.smallPicturePath || item.picturePath || GLOBALS.DEFAULT_USER_IMG_URL}
            alt='' />
        ) : (
          affair ? <Badges affair={affair} /> : ""
        )}
      </Td>
    );
  }

  if (name === "date") {
    const dateFormat = get(config, "dateFormat");
    const startDate = getters.date(item) && dateFormat
      ? moment(getters.date(item)).format(
        get(config, "dateFormat", "DD.MM.YYYY")
      )
      : moment(getters.date(item)).format('DD.MM.YYYY');

    const endDate = getters.endDate(item) && (dateFormat
      ? moment(getters.endDate(item)).format(
        get(config, "dateFormat", "DD.MM.YYYY")
      )
      : moment(getters.endDate(item)).format('DD.MM.YYYY'));

    const dateString = endDate ? `${startDate} - ${endDate}` : startDate;

    return (
      <Td style={baseStyles.styles.columns[name]}>
        {dateString}
      </Td>
    );
  }

  if (name === "lastEventDate") {
    const dateFormat = get(config, "dateFormat");
    const lastEventDate = getters.lastEventDate(item) && (dateFormat
      ? moment(getters.lastEventDate(item)).format(
        get(config, "dateFormat", "DD.MM.YYYY")
      )
      : getDateTime(getters.lastEventDate(item)));

    return (
      <Td style={baseStyles.styles.columns[name]}>
        {lastEventDate}
      </Td>
    );
  }

  if (name === "title") {
    return (
      <Td style={baseStyles.styles.columns[name]}>
        {isCouncillor(item) && <Badges affair={item} />}
        <AffairTitle
          data-test={`affair-title`}
          className='table-cell-name'
          to={getItemUrl(item)}
          title={getters[name](item)}>
          {getters[name](item)}
        </AffairTitle>
      </Td>
    );
  }

  if (name === "author") {
    const authors = getItemSearchAuthors(item);

    if (isCouncillor(item)) {
      return (
        <Td style={baseStyles.styles.columns[name]} className='d-none d-md-table-cell'>
          {"-"}
        </Td>
      );
    }

    if (isLinkedUser(item.author)) {
      const author = item.author;

      return (
        <Td style={baseStyles.styles.columns[name]}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div style={{ marginRight: 5 }}>
              <CouncillorPortraitSmall src={get(author, 'profilePicture.contentUrl', GLOBALS.DEFAULT_USER_IMG_URL)} />
            </div>
            <div style={{ textAlign: 'center', width: '100%' }}>
              <div>
                {author.firstName}
              </div>
              <div>
                {author.lastName}
              </div>
            </div>
          </div>
        </Td>
      );
    }

    return (
      <Td style={baseStyles.styles.columns[name]} className='d-none d-md-table-cell'>
        <div>
          {authors.length > 0
            ? authors.map((author, i) => (
              <div key={author["@id"] || i} style={{ margin: '4px' }}>
                <Author author={author} affair={affair} />
              </div>
            ))
            : "-"}
        </div>
      </Td>
    );
  }

  if (name === "topics") {
    const topics = (affair && 'topics' in affair && affair.topics || []).map((t) => t.title).join(", ");

    return <Td style={baseStyles.styles.columns[name]} className='d-none d-md-table-cell'>{topics}</Td>;
  }

  if (name === "status") {
    return (
      <Td style={baseStyles.styles.columns[name]} className='d-none d-md-table-cell'>
        {isConsultation(affair) && affair.status && !affair.status.includes(" ")
          ? trans(
            `national.consultations.details.${affair.status}`,
            affair.status
          )
          : trans(getters[name](item))}
      </Td>
    );
  }

  if (name === "actions") {
    return (
      <Td style={baseStyles.styles.columns[name]}>
        {typeof actions === "function" ? (
          <div style={{display: 'flex', width: '150px'}}>
            {actions(item)}
            {affair && <Checkbox
              checked={selected}
              onChange={toggleCheckbox} />
            }
          </div>
        ) : (
          affair ? <Checkbox
            checked={selected}
            onChange={toggleCheckbox} /> : null
        )}
      </Td>
    );
  }

  if (name === "canton") {
    const canton: $Canton | null | undefined | string = "canton" in item ? item.canton : ((affair && "canton" in affair) ? affair.canton : null);
    const swissNationalPath: string = GLOBALS.SWISS_NATIONAL;
    const swissInternalPath: string = GLOBALS.SWISS_INTERNAL;
    let logoPath: string;

    if (item['@type'] === SEARCH_TYPES.NATIONAL_AFFAIR || item['@type'] === SEARCH_TYPES.NATIONAL_AFFAIR_CONSULTATION) {
      logoPath = swissNationalPath;
    }
    else if (item['@type'] === SEARCH_TYPES.CUSTOM_AFFAIR) {
      logoPath = swissInternalPath;
    }
    else  {
      logoPath = canton?.logoPath || "";
    }

    return (
      <Td style={baseStyles.styles.columns[name]}>
        <img
          src={logoPath}
          className='mr-1'
          style={{ maxHeight: "1.5em" }}
          alt={canton && canton.abbreviation || ""}
          title={canton && canton.title || ""} />
      </Td>
    );
  }

  return null;
};

type $Props = {
  item: $AffairTable_props['data'][0],
  actions: $AffairTable_props['actions'],
  rowSorted: number | undefined,
  config: $AffairTable_config,
  checklist: ($Affair | $Event["xPltkParent"])[],
  setChecklist: Function,
  user: $User,
  showOptionalColumns: {
    canton: boolean;
    date: boolean;
    status: boolean;
    actions: boolean;
    topics: boolean;
  }
}

export const AffairItemList = ({
  item,
  actions,
  rowSorted,
  config = {},
  checklist = [],
  setChecklist,
  user,
  showOptionalColumns,
}: $Props) => {
  /* eslint-disable no-console */
  const type = ("categories" in item ? item.categories : item["@type"]) || "";
  const affair: $Props["checklist"][0] | undefined = getAffairFromTableItem(item);
  const selected: boolean = Boolean(affair &&
    checklist.find(checked => getId(checked) === getId(affair))
  );

  const toggleCheckbox = () => {
    if (!affair) {
      return null;
    }

    const newChecklist = selected
      ? checklist.filter((item) => getId(item) !== getId(affair))
      : checklist.concat(affair);

    setChecklist(newChecklist);
  };

  if (
    type === SEARCH_TYPES.NATIONAL_AFFAIR||
    type === SEARCH_TYPES.CANTONAL_AFFAIR ||
    type === SEARCH_TYPES.CUSTOM_AFFAIR ||
    type === SEARCH_TYPES.NATIONAL_AFFAIR_CONSULTATION ||
    type === SEARCH_TYPES.CANTONAL_AFFAIR_CONSULTATION ||
    type === "NationalCommitteeAffair" ||
    type === "AffairGroup" ||
    type === "CustomMeeting" ||
    type === "CustomMeetingAgendaPoint" ||
    type === SEARCH_TYPES.NATIONAL_COUNCILLOR ||
    type === SEARCH_TYPES.CANTONAL_COUNCILLOR ||
    type === "NationalCommitteeProgramStartNationalAffairEvent" ||
    type === "NationalCommitteeProgramEndNationalAffairEvent" ||
    type === SEARCH_TYPES.CANTONAL_COMMITTEE_PROGRAM_START_CANTONAL_AFFAIR_EVENT ||
    type === "SRCouncilVote" ||
    type === "NRCouncilVote" ||
    type === "CustomAffairInternalVoteCustomEvent" ||
    type === "NationalAffairConsultationNoteCustomEvent" ||
    type === "NationalAffairNoteCustomEvent" ||
    type === "CantonalAffairNoteCustomEvent" ||
    type === "CustomAffairNoteCustomEvent" ||
    type === "NationalCommitteeProgramSpanNationalAffairEvent" ||
    type.includes("CustomSpeaker")
  ) {
    const tableColumns = config.columns || columns;

    return (
      <Tr key={item["@id"]} rowSorted={rowSorted} selected={selected}
        data-test='affair-row'>
        {tableColumns
          .filter(
            (col) =>
              !(config.hideColumns || [])
                .concat(
                  user.roles.includes(ROLES.ROLE_AREA_CANTONAL) ? [] : "canton"
                )
                .includes(col) &&
              (columnsOptional.includes(col) ? showOptionalColumns[col] : true)
          )
          .map((colName) => (
            <Col
              key={colName}
              name={colName}
              item={item}
              config={config}
              toggleCheckbox={toggleCheckbox}
              selected={selected}
              actions={actions} />
          ))}
      </Tr>
    );
  }

  Sentry.captureException(new Error(`Affair table item of type ${type} not shown on table: ${JSON.stringify(item, null, "\t")}`));

  return null;
};

