import React, { CSSProperties, useState } from "react";
import { intersection, orderBy, uniq , get }from "lodash";
import { Checkbox } from "@components/Shared/ui";

import { Pagination } from "../Pagination";
import { LoadingCover } from "../BaseUI";
import { ActionBar } from "./ActionBar";
import { ThSortable, Thead, Th } from "./TableHead";
import { Tr, Td } from "./TableRow";
import { Table, TableWrapper } from "./Table";

import { trans } from "@trans";
import { arrify } from '@helpers';
import { baseConfig } from "./config";
import { GLOBALS, ROLES, SPACINGS } from "@constants";
import { baseStyles } from "./baseStyles";


const getGetters = (columns) => {
  const obj = {};

  columns.forEach((col) => {
    if (col.get) {
      obj[col.name] = col.get;
    }
  });

  return obj;
};

const filterSort = (items: unknown[], filters: $customTableFilters, getters: { [key: string]: (any) => string }) => {
  const filteredItems = Object.entries({ ...filters })
    .filter((f) => getters[f[0]])
    .reduce(
      (acc, [key, vals]) =>
        //@ts-ignore
        vals.length > 0
          ? acc.filter(
            (item) =>
              //@ts-ignore
              intersection(vals, arrify(getters[key](item))).length > 0
          )
          : acc,
      items
    );

  const searchedAndFilteredItems = filters.search
    ? filteredItems.filter((item) =>
      filters.search.split(" ").every((word) =>
        Object.values(getters)
          .map((getter) => getter(item))
          .join(" ")
          .toLowerCase()
          .includes(word.toLowerCase())
      )
    )
    : filteredItems;

  return filters.sort
    ? orderBy(
      searchedAndFilteredItems,
      Object.keys(filters.sort).map((k) => getters[k]),
      Object.values(filters.sort)
    )
    : searchedAndFilteredItems;
};

const getId = (item) => get(item, '@id', item.uid);

const ActionCheckbox = ({ setChecklist, checklist, item }) => {
  const selected = Boolean(checklist.find((s) => getId(s) === getId(item)));

  return (
    <Checkbox
      checked={selected}
      onChange={() => {
        const newChecklist = selected
          ? checklist.filter((s) => getId(s) !== getId(item))
          : checklist.concat(item);

        setChecklist(newChecklist);
      }}
      value='affairs_selected'
      color='primary' />
  );
};

type $Props = {
  columns: ({
    name: string,
    Content: Function,
    get?: (any) => string,
    sort?: boolean,
    filter?: boolean
  })[],
  data: unknown[],
  sort?: object,
  loading?: boolean,
  config: $AffairTable_config,
  onRowClicked?: Function,
  rowClassName?: string,
  style?: CSSProperties,
  user?: $User
}
type $customTableFilters = { page: number, search: string, pageSize: number, sort?: { [key: string]: $Sort } }

export function CustomTable({
  columns = [],
  data,
  sort = {},
  loading,
  config = baseConfig,
  onRowClicked,
  rowClassName = "",
  style = {},
  user,
}: $Props) {
  const canWrite = user && user.roles.includes(ROLES.ROLE_FULL_READWRITE);

  const localStorageKey: string | null | undefined =
    config.uniqId &&
    user &&
    location.pathname + user["@id"] + config.uniqId.replaceAll(" ", "");
  const localStorageSavedConfig = localStorage.getItem(localStorageKey || "") ? JSON.parse(
    localStorage.getItem(localStorageKey || "") || ""
  ) : null;
  const pageSize =
    get(localStorageSavedConfig, "pageSize") ||
    config.pageSize ||
    get(user, "settings.list_size.value") ||
    GLOBALS.DEFAULT_PAGE_SIZE;

  const [filterOptions, setFilterOptions] = useState({});
  const [filters, setFilters] = useState<$customTableFilters>({
    page: 1,
    search: "",
    pageSize,
  });
  const [getters] = useState(getGetters(columns));
  const [checklist, setChecklist] = useState([]);

  React.useEffect(() => {
    const optionsAvailable = Object.fromEntries(
      columns.map((col) => [
        col.name,
        col.get && col.filter
          ? uniq(data.map((item) => col.get && col.get(item))).sort()
          : [],
      ])
    );

    setFilterOptions(optionsAvailable);
    setFilters((filters) => ({
      ...filters,
      sort: {},
      page: 1,
      pageSize,
    }));
  }, [data]);
  const showPageSizeSelect = config.pageSize !== false;
  const items = filterSort(data || [], filters, getters);

  return (
    <div style={style}>
      {(config.search || showPageSizeSelect) && user && (
        <div style={{ padding: `${SPACINGS.X3} ${SPACINGS.X2} ${SPACINGS.X2} ${SPACINGS.X3}` }}>
          <ActionBar
            user={user}
            checklist={checklist}
            localStorageKey={localStorageKey}
            config={config}
            filters={filters}
            setFilters={setFilters}
            pagination={filters.pageSize}
            showPageSizeSelect={showPageSizeSelect}
            setChecklist={setChecklist}
            items={items}/>
        </div>
      )}
      <TableWrapper>
        {loading && <LoadingCover className='animated-background' data-test='animated-background' />}
        <Table>
          {get(config, "showHeader", true) && (
            <Thead>
              <Tr>
                {columns.map((col, i) => (
                  <ThSortable
                    key={i}
                    sort={col.sort
                      ? () => {
                        const newFilters: $customTableFilters = {
                          ...filters,
                          sort: {
                            [col.name]:
                              filters.sort && filters.sort[col.name]
                                ? filters.sort[col.name] === "desc"
                                  ? "asc"
                                  : "desc"
                                : "desc",
                          },
                        };

                        setFilters(newFilters);
                      }
                      : null}
                    sortState={sort[col.name]
                      ? sort[col.name].state
                      : filters.sort && filters.sort[col.name]}
                    filter={col.filter ? {
                      options: filterOptions[col.name],
                      func: (optionsSelected) =>
                        setFilters({
                          ...filters,
                          [col.name]: optionsSelected,
                        }),
                      currentSelection: filters[col.name],
                    } : undefined}>
                    {col.name}
                  </ThSortable>
                ))}
                {config.actions && (
                  <Th>{trans("ui.table.header.actions", "Aktionen")}</Th>
                )}
              </Tr>
            </Thead>
          )}
          <tbody>
            {items.length > 0 ? (
              (filters.pageSize
                ? items.slice(
                  (filters.page - 1) * filters.pageSize,
                  filters.page * filters.pageSize
                )
                : items
              ).map((item, index) => (
                <Tr
                  className={rowClassName}
                  key={index}
                  onClick={() => onRowClicked && onRowClicked(item)}>
                  {columns.map((column, i) => (
                    <Td key={i} style={{ width: column.name === 'Parliamentary group' || column.name === 'Party' ? '184px' : '' }}>
                      {column.Content ? (
                        <column.Content item={item} index={index} />
                      ) : (
                        column.get && column.get(item)
                      )}
                    </Td>
                  ))}
                  {config.actions && canWrite && (
                    <Td style={baseStyles.styles.columns[6]}>
                      <ActionCheckbox
                        setChecklist={setChecklist}
                        checklist={checklist}
                        item={item} />
                    </Td>
                  )}
                </Tr>
              ))
            ) : (
              <tr>
                <td className='text-center' colSpan={columns.length}>
                  {loading ? "" : trans("affairs.list.empty")}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableWrapper>
      <div className='p-1'>
        <Pagination
          setPage={(page) => setFilters({ ...filters, page })}
          currentPage={filters.page}
          totalResults={items.length}
          itemsPerPage={filters.pageSize} />
      </div>
    </div>
  );
}
