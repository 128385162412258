import React, { Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Element, scroller } from "react-scroll";
import { trans } from "@trans";
import { getItemUrl } from '@helpers';
import { A } from "../Shared/BaseUI";
import { COLORS, SEARCH_TYPES } from "@constants";

const config = {
  animationDuration: 0.5,
  collapseHeight: 800,
};

export const ShowDate = ({ time }: { time: string }) => (
  <small className='ml-1 text-nowrap'>
    <span className='profile-notifications__date'>
      {new Date(time).toLocaleDateString()}
    </span>
    <span className='profile-notifications__time'>
      , {new Date(time).toLocaleTimeString()}
    </span>
  </small>
);

class NotificationsList extends Component<{
  data: $Notification[],
  markRead: Function,
  index: number
} & RouteComponentProps, {
  openedIndex: number | null,
  closing: boolean
}> {
  constructor(props) {
    super(props);
    const indexFromUrl = props.index;

    this.state = {
      openedIndex: indexFromUrl ? Number(indexFromUrl) : null,
      closing: false,
    };
  }

  componentDidMount() {
    const indexFromUrl = new URLSearchParams(this.props.location.search).get(
      "index"
    );

    if (indexFromUrl !== null) {
      this.scrollTo(`scrollTo_${indexFromUrl}`);
    }
  }

  componentDidUpdate(prevProps) {
    const urlIndexChanged = prevProps.index !== this.props.index;

    if (urlIndexChanged) {
      this.setState({ openedIndex: this.props.index });
    }

    return null;
  }

  toggle = (i) => {
    if (i === this.state.openedIndex) {
      this.setState({ openedIndex: null });
    }
    else {
      this.setState({ openedIndex: i });
    }
  };

  scrollTo = (name) =>
    scroller.scrollTo(name, {
      duration: config.animationDuration * 1000,
      smooth: true,
      isDynamic: true,
    });

  render() {
    return (
      <>
        <div className='accordion' id='notificationsAccordion'>
          {this.props.data.map((n, i) => {
            const isOpened = this.state.openedIndex === i;

            return (
              <div className='card' key={n.id}>
                <Element name={`scrollTo_${String(i)}`}> </Element>
                <div
                  className={`card-header card-header-info ${isOpened ? "open" : ""
                  } ${n.seen ? "" : "not-seen"}`}
                  id={`heading_${n.id}`}>
                  <h5 className='mb-0'>
                    <button
                      className='btn btn-link'
                      type='button'
                      onClick={() => {
                        this.toggle(i);
                        this.props.markRead(n, i);
                        setTimeout(
                          () => this.scrollTo(`scrollTo_${String(i)}`),
                          config.animationDuration * 1000
                        );
                      }}>
                      <span>{trans(n.subject, {}, "email")}</span>
                      {n.createdAt && <ShowDate time={n.createdAt} />}
                    </button>
                  </h5>
                </div>

                <div
                  className={`notifications-collapse ${this.state.closing
                    ? "closing"
                    : isOpened
                      ? "open"
                      : "closed"
                  }`}
                  aria-labelledby={`heading_${n.id}`}>
                  <div className='card-body p-0'>
                    <div className='list-group'>
                      {n.notificationItems.map((item) => {
                        if (!item.item) {
                          return null;
                        }

                        const isCantonal =
                          item.item["@type"] === SEARCH_TYPES.CANTONAL_AFFAIR;
                        const isReminder =
                          item.item["@type"] === "EventReminder";

                        if (isReminder && !item.item.item) {
                          /* eslint-disable no-console */
                          console.log(`Reminder without item found: ${JSON.stringify(item, null, "\t")}`);

                          return null;
                        }

                        const url = getItemUrl(item.item);
                        const title = isReminder
                          ? item?.item?.item?.title
                          : item.item.title;

                        return (
                          <A
                            to={url}
                            key={item.id}
                            className='list-group-item list-group-item-action flex-column align-items-start'>
                            <div className='d-flex w-100 justify-content-between pl-1 pr-1'>
                              <div style={{ width: "100%" }}>
                                {isCantonal && (
                                  <img
                                    className='canton_logo'
                                    src={item.item.canton?.logoPath}
                                    alt='' />
                                )}
                                <span className='badge badge-primary mr-1'>
                                  {item.item.slug}
                                </span>
                                {title}
                              </div>
                              <ShowDate
                                time={(isReminder
                                  ? item.item.dateTime
                                  : item.item.updatedAt) || ""} />
                            </div>
                          </A>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <style>{`
          #notificationsAccordion {
            position: relative;
          }
          #notificationsAccordion .fas {
            margin-right: 10px;
          }
          #notificationsAccordion .list-group-item {
            cursor: pointer;
          }
          #notificationsAccordion .date {
            margin-left: 10px;
            font-size: x-small;
          }
          #notificationsAccordion .card {
            margin: 2px;
            padding: 0;
          }
          #notificationsAccordion
            .card
            [class*="card-header-"]:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
            margin-top: 0px;
            padding: 0px;
          }
          #notificationsAccordion .card [class*="card-header-"] {
            margin: 0px 0px 0;
          }
          #notificationsAccordion .btn.btn-link {
            width: 100%;
            text-align: left;
            color: #fff;
            background: ${COLORS.PRIMARY};
            margin: 0;
            display: flex;
            justify-content: space-between;
          }
          #notificationsAccordion .not-seen .btn.btn-link {
            background: ${COLORS.INTERACTIVE};
          }
          #notificationsAccordion .open .btn.btn-link,
          #notificationsAccordion .btn.btn-link:hover {
            background: ${COLORS.ACTIVE};
          }
          #notificationsAccordion .notifications-collapse {
            max-height: 0px;
            overflow: auto;
            transition: max-height ${config.animationDuration}s;
          }

          #notificationsAccordion .notifications-collapse.open {
            max-height: ${config.collapseHeight}px;
          }

          #notificationsAccordion .canton_logo {
            height: 22px;
            margin-right: 2px;
          }
        `}</style>
      </>
    );
  }
}

export const List = withRouter(NotificationsList);
