import { FETCH_NATIONAL_COMMITTEES_WITH_PROGRAMS } from "@redux/actionTypes";
import { Reducer } from "redux";

const defaultReducer: $Reducer_nationalCommitteesWithPrograms = { data: [], loading: false };

const nationalCommitteesWithProgramsReducer: Reducer<$Reducer_nationalCommitteesWithPrograms, any> = (state = defaultReducer, action) => {
  switch (action.type) {
  case FETCH_NATIONAL_COMMITTEES_WITH_PROGRAMS:
    return action.payload;
  default:
    return state;
  }
};

export default nationalCommitteesWithProgramsReducer;