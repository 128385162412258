export const ROLES = {
    ROLE_AREA_NATIONAL: "ROLE_AREA_NATIONAL",
    ROLE_AREA_CANTONAL: "ROLE_AREA_CANTONAL",
    ROLE_LINKS_R: "ROLE_LINKS_R",
    ROLE_LINKS_W: "ROLE_LINKS_W",
    ROLE_CUSTOMLINKS_R: "ROLE_CUSTOMLINKS_R",
    ROLE_CUSTOMLINKS_W: "ROLE_CUSTOMLINKS_W",
    ROLE_ASSIGNEDAFFAIRS_R: "ROLE_ASSIGNEDAFFAIRS_R",
    ROLE_ASSIGNEDAFFAIRS_W: "ROLE_ASSIGNEDAFFAIRS_W",
    ROLE_FULL_READONLY: "ROLE_FULL_READONLY",
    ROLE_FULL_READWRITE: "ROLE_FULL_READWRITE",
    ROLE_GROUPS_W: "ROLE_GROUPS_W",
    ROLE_ADMIN: "ROLE_ADMIN",
    ROLE_COMPANY_ADMIN: "ROLE_COMPANY_ADMIN",
    ROLE_INTERNALFIELDS_W: "ROLE_INTERNALFIELDS_W",
    ROLE_CUSTOMEVENTS_W: "ROLE_CUSTOMEVENTS_W",
    ROLE_COMPANYSETTINGS_W: "ROLE_COMPANYSETTINGS_W"
}