import React from 'react';
import styled from 'styled-components';
import { COLORS } from '@constants';
import { LoadingCover } from './BaseUI';
import { findPaginationNumbersToShow } from '@helpers';

const paginationSize = 10;
const basicRange = Array(paginationSize)
  .fill(null)
  .map((i, index) => index + 1);

const PaginationItem = styled.span`
  display: inline-block;
  padding: 5px;
  min-width: 30px;
  color: ${(props) => (props.selected ? '#000' : COLORS.TABLE)};
  cursor: ${(props) => (props.selected ? 'initial' : 'pointer')};
  border-left: 1px solid ${COLORS.TABLE};
  &:hover {
    color: ${(props) => (props.selected ? '#000' : COLORS.ACTIVE)};
    background: ${(props) => (props.selected ? COLORS.INTERACTIVE : COLORS.TABLE)};
  }
  &:nth-child(1) {
    border-left: initial;
  }
  & i {
    color: ${COLORS.PRIMARY};
  }
`;

const PaginationContainer = styled.div`
  text-align: center;
  margin: auto;
  position: relative;
  border: 1px solid ${COLORS.TABLE};
  border-radius: 8px;
  overflow: hidden;
  width: fit-content;
`;

export const Pagination = ({
  setPage,
  currentPage,
  totalResults,
  itemsPerPage,
  loading,
}: {
  setPage: Function;
  currentPage: number;
  totalResults: number;
  itemsPerPage: number;
  loading?: boolean;
}) => {
  const pagesTotal = Math.ceil(totalResults / itemsPerPage);
  const page = currentPage;

  const numbersToShow = findPaginationNumbersToShow(page, paginationSize, pagesTotal, basicRange);

  return (
    <PaginationContainer className='mt-1 mb-1'>
      {loading && <LoadingCover className='animated-background' data-test='animated-background' />}
      {page > 1 && pagesTotal > 10 && (
        <PaginationItem onClick={() => setPage(1)}>
          <i className='fas fa-angle-double-left' />
        </PaginationItem>
      )}
      {page > 10 && (
        <PaginationItem onClick={() => setPage(Math.max(page - 10, 1))}>
          <i className='fas fa-angle-left' />
        </PaginationItem>
      )}
      {/* {1 < page - 10 && "..."} */}
      {numbersToShow.map((n) => (
        <PaginationItem key={n} onClick={() => n !== page && setPage(n)} selected={n === page}>
          {n}
        </PaginationItem>
      ))}
      {/* {pagesTotal > page + 10 && "..."} */}

      {page + 10 < pagesTotal && (
        <PaginationItem onClick={() => setPage(Math.min(page + 10, pagesTotal))}>
          <i className='fas fa-angle-right' />
        </PaginationItem>
      )}
      {page !== pagesTotal && pagesTotal > 10 && (
        <PaginationItem onClick={() => setPage(pagesTotal)}>
          <i className='fas fa-angle-double-right' />
        </PaginationItem>
      )}
    </PaginationContainer>
  );
};
