import { LOCAL_STORAGE_LANGUAGE_KEY } from './constants/shared';
import Translator from 'bazinga-translator';
global.Translator = Translator;
// fos_js_routes.json is the output file for the fos:js-routing:dump command
require('./translations/config.js');
require('./translations/de.js');
require('./translations/fr.js');
require('./translations/it.js');
require('./translations/en.js');

const localLanguage = localStorage.getItem(LOCAL_STORAGE_LANGUAGE_KEY) || 'de';
Translator.locale = localLanguage;

export default Translator;

