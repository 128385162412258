import { Routing } from '@backendRouter';
import moment from 'moment';
import { trans } from "@trans";
import { omitBy, get, last } from 'lodash';
import Translator from '@translator';
import { $SessionOptions } from './react/js/Pages/Rankings/RankingType';
import { LanguageKey } from '@redux/helpers/translations/Types';
import { SEARCH_TYPES } from './constants/searchTypes';
import { ROLES } from './constants/roles';
import { historyOptions, linkedAffairsOptions, formats, REPORT_TYPES } from './constants/reports';
import { generateVisualReportPublicLink } from '@api/reports';

/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 */

export function detectIE() {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf('MSIE ');

  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }

  var trident = ua.indexOf('Trident/');

  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf('rv:');

    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }

  var edge = ua.indexOf('Edge/');

  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }

  // other browser
  return false;
}

export const getAffairUrl = (affair, event?) => {
  const section = (affair['@type'] || affair.categories).replace('Affair', '').toLowerCase();
  const id = affair.id || last((affair['@id'] || affair.iri).split('/'));
  const type = affair['@type'] || affair.categories;

  if (
    type === SEARCH_TYPES.NATIONAL_AFFAIR_CONSULTATION ||
    type === SEARCH_TYPES.CANTONAL_AFFAIR_CONSULTATION
  ) {
    return getConsultationUrl(affair, event);
  }
  else if (section === 'national') {
    return Routing.generate('show_affair', {
      affairSlug: affair.slug || affair.shortIdParlService,
      eventSlug: event !== undefined ? event.slug : null,
    });
  }
  else if (section === 'custom') {
    return Routing.generate('custom_affair_show', {
      id,
      eventSlug: event !== undefined ? event.slug : null,
    });
  }
  else {
    return Routing.generate('cantonal_show_affair', {
      cantonSlug:
        affair.cantonSlug || affair.canton.slug || affair.canton.abbreviation.toLowerCase(),
      affair: id,
      eventSlug: event !== undefined ? event.slug : null,
    });
  }
};

export function getConsultationUrl(item, event?: $Event | $OldEvent) {
  if (event && 'url' in event && event.url) {
    return event.url;
  }

  if (item.url) {
    return item.url;
  }

  if (item['@type'] === SEARCH_TYPES.CANTONAL_AFFAIR_CONSULTATION) {
    return Routing.generate('cantonal_affair_consultation_detail', {
      consultationSlug: item.slug,
      eventSlug: event ? ('uid' in event ? event.uid : event.slug) : null,
      cantonSlug: item.canton.slug || (item.canton.abbreviation || '').toLowerCase(),
    });
  }

  return Routing.generate('national_affair_consultation_detail', {
    consultationSlug: item.slug,
    eventSlug: event ? ('uid' in event ? event.uid : event.slug) : null,
  });
}

export function getItemUrl(item) {
  if (item.url) {
    return item.url;
  }

  if (item.itemUrl) {
    return item.itemUrl;
  }

  if (item && (item['@type'] || item.categories)) {
    switch (item['@type'] || item.categories) {
    case 'EventReminder':
      return Routing.generate('event_reminders', {
        eventReminderId: item.id,
      });
    case 'NationalAffairCustomSpeaker':
      return getAffairUrl(
        item.cantonalAffair ||
          item.nationalAffair ||
          item.customAffair ||
          item.nationalAffairConsultation ||
          item.cantonalAffairConsultation,
        item
      );
    case 'NationalAffairNoteCustomEvent':
    case 'NationalAffairConsultationNoteCustomEvent':
    case 'NationalAffairConsultationEndEvent':
    case 'NationalAffairConsultationStartEvent':
    case 'CantonalAffairNoteCustomEvent':
    case 'CustomAffairNoteCustomEvent':
    case 'NationalAffairInternalVoteCustomEvent':
    case 'NationalAffairConsultationInternalVoteCustomEvent':
    case 'CantonalAffairInternalVoteCustomEvent':
    case 'CustomAffairInternalVoteCustomEvent':
    case 'CouncilStatesVote':
    case 'NRCouncilVote':
    case 'SRCouncilVote':
    case 'CantonalAffairConsultationStartEvent':
    case 'CantonalAffairConsultationEndEvent':
    case 'CantonalAffairConsultationNoteCustomEvent':
    case 'CantonalAffairConsultationInternalVoteCustomEvent':
    case 'NationalSessionProgramNationalAffairEvent':
    case 'NationalAffairDraftReference':
    case 'NationalAffairDraftResolution':
    case 'NationalCommitteePressReleaseNationalAffairEvent':
    case 'StatusChangeNationalAffairEvent':
    case 'FederalCouncilProposalNationalAffairEvent':
    case 'FederalCouncilResponseNationalAffairEvent':
    case 'PreConsultationNationalAffairEvent':
    case 'StatusChangeCantonalAffairEvent':
    case 'NationalCommitteeProgramStartNationalAffairEvent':
    case 'CantonalSessionProgramCantonalAffairEvent':
    case 'NationalCommitteeProgramEndNationalAffairEvent':
    case 'CantonalSessionProgramStartCantonalAffairEvent':
    case 'CantonalSessionProgramEndCantonalAffairEvent':
    case 'NationalDebateEvent':
      return getAffairUrl(item.mainAffair || item.affair, item);
    case 'NationalCouncilVote':
      return getAffairUrl(item.affair || item.mainAffair, item);
    case SEARCH_TYPES.CANTONAL_AFFAIR:
    case SEARCH_TYPES.NATIONAL_AFFAIR:
    case SEARCH_TYPES.CUSTOM_AFFAIR:
      return getAffairUrl(item);
    case 'AffairGroup':
      return Routing.generate('user_affairgroup_overview', {
        affairGroupId: item.id,
      });
    case 'CustomMeeting':
      return Routing.generate('user_custom_meeting_overview', {
        customMeetingId: item.id || last(item['@id'].split('/')),
      });

    case 'CustomMeetingAgendaPoint':
      if (item.parent && item.parent.id) {
        return Routing.generate('user_custom_meeting_overview', {
          customMeetingId: item.parent.id,
        });
      }
      else {
        return null;
      }

    case SEARCH_TYPES.NATIONAL_AFFAIR_CONSULTATION:
    case SEARCH_TYPES.CANTONAL_AFFAIR_CONSULTATION:
      return getConsultationUrl(item);
    case SEARCH_TYPES.NATIONAL_COUNCILLOR:
      return Routing.generate('show_councillors', {
        slug: item.slug,
      });
    case 'DistrictCanton':
      return Routing.generate('show_cantons', {
        slug: item.slug,
      });
    case SEARCH_TYPES.CANTONAL_COUNCILLOR:
      return Routing.generate('cantonal_show_councillor', {
        cantonSlug: get(item, 'canton.slug'),
        councillorSlug: item.slug,
      });
    case 'CantonalCommittee':
      return Routing.generate('cantonal_show_committee', {
        cantonSlug: get(item, 'canton.slug'),
        committeeSlug: item.slug,
      });
    case 'CantonalMiscAuthor':
      return Routing.generate('cantonal_show_miscauthor', {
        cantonSlug: get(item, 'canton.slug'),
        authorSlug: item.slug,
      });
    case 'CantonalFaction':
      return Routing.generate('cantonal_show_faction', {
        cantonSlug: get(item, 'canton.slug'),
        factionSlug: item.slug,
      });
    case 'CantonalParty':
      return Routing.generate('cantonal_show_party', {
        cantonSlug: get(item, 'canton.slug'),
        partySlug: item.slug,
      });
    case 'AffairGroupNationalAffairLeaf':
    case 'AffairGroupCantonalAffairLeaf':
      return null;
    case 'NationalParty':
      return Routing.generate('show_parties', {
        slug: item.slug,
      });
    case 'NationalFaction':
      return Routing.generate('show_factions', {
        slug: item.slug,
      });
    default:
      return null;
    }
  }

  return null;
}

export const getAffairEventUrl = (affair, event) => {
  const affairId = affair.id || last(affair['@id'].split('/'));

  if (affair['@type'] === SEARCH_TYPES.CANTONAL_AFFAIR) {
    return Routing.generate('cantonal_show_affair', {
      affair: affairId,
      cantonSlug: affair.canton.abbreviation.toLowerCase(),
      eventSlug: event.slug,
    });
  }
  else if (affair['@type'] === SEARCH_TYPES.NATIONAL_AFFAIR) {
    return Routing.generate('show_affair', {
      affairSlug: affair.slug,
      eventSlug: event.slug,
    });
  }
  else if (affair['@type'] === SEARCH_TYPES.NATIONAL_AFFAIR_CONSULTATION) {
    return Routing.generate('national_affair_consultation_detail', {
      consultationSlug: affair.slug,
      eventSlug: event.slug,
    });
  }
  else if (event['@type'] === 'CustomAffairDepositEvent') {
    return Routing.generate('custom_affair_show', {
      id: affairId,
    });
  }
  else {
    return Routing.generate('custom_affair_show', {
      id: affairId,
      eventSlug: event.slug,
    });
  }
};

export const getDate = (dateString) => moment(dateString).format('DD.MM.YYYY');
export const getDateTime = (dateString) =>
  dateString.substr(11, 5) === '00:00'
    ? getDate(dateString)
    : new Date(dateString).toLocaleString('de-ch');
export const getTime = (dateString) => new Date(dateString).toLocaleTimeString('de-ch');
export const limitLength = (str, limit) =>
  str.length <= limit ? str : str.slice(0, limit - 3) + '...';

export const isNull = (i) => i === null || i === 'null';

export const getCouncillorLink = ({ author, canton }) => {
  if (canton) {
    return Routing.generate('cantonal_show_councillor', {
      cantonSlug: canton.abbreviation.toLowerCase(),
      councillorSlug: author.slug,
    });
  }

  return 'update getCouncillorLink function please';
};

export const getEventTitle = (e, settings = {}) => {
  if (e.summary) {
    return e.summary;
  }

  const type = e['@type'] || e.type || e.categories;

  if (
    type === 'CouncilStatesVote' ||
    type === 'NationalCouncilVote' ||
    type === 'NRCouncilVote' ||
    type === 'SRCouncilVote' ||
    type.match(/NoteCustomEvent$/) ||
    type === 'NationalCommitteeAffair'
  ) {
    return e.title;
  }

  if (type === 'PreConsultationNationalAffairEvent') {
    return e.committee ? e.committee.abbr || e.committee.title : '';
  }

  if (type === 'nationalAffairDraftResolution') {
    return e.committee && !isNull(e.committee)
      ? e.committee.abbr || e.committee.title
      : e.council && !isNull(e.council)
        ? e.council.name
        : null;
  }

  if (type === 'NationalSessionProgramNationalAffairEvent') {
    const councilName = get(e.council || get(e, 'xPltkAuthorities[0]'), 'name');

    return `${councilName ? `${councilName} - ` : ''}${get(e, 'status.title', e.summary)}`;
  }

  if (type === 'NationalAffairDraftReference') {
    return get(e, 'referenceType.name', e.summary);
  }

  if (
    type === 'NationalSessionProgramNationalAffairEvent' ||
    type === 'CantonalSessionProgramStartCantonalAffairEvent'
  ) {
    return get(e, 'status.title', e.summary);
  }

  if (type.match(/InternalVoteCustomEvent%/)) {
    //TODO dont get it from backend
    return e.title;
  }

  if (type === 'CustomAffairDepositEvent' || type === 'NationalDebateEvent') {
    return getEventName(e);
  }

  if (type === 'NationalAffairConsultationEndEvent') {
    return trans('national.consultations.events.end');
  }

  if (type === 'NationalAffairConsultationStartEvent') {
    return trans(
      `national.consultations.events.start${get(settings, 'timespans', true) ? '' : '.timespan'}`
    );
  }

  if (type === 'CantonalSessionProgramCantonalAffairEvent') {
    return e.status.title;
  }

  if (type === 'NationalCommitteePressReleaseNationalAffairEvent') {
    return (
      e.summary ||
      `${e.status.title}${e.pressRelease && e.pressRelease.nationalCommittee
        ? ` - ${e.pressRelease.nationalCommittee.abbr}`
        : ''
      }`
    );
  }

  if (type === 'FederalCouncilProposalNationalAffairEvent') {
    return `${e.title}${e.description ? ` : ${e.description}` : ''}`;
  }

  return (
    e.title || e.eventTitle || trans(`affair.${makeFirstLetterLowerCase(type)}.tooltip.caption`)
  );
};

export const getEventName = (e: $Event | $CalendarEvent | $OldEvent | $NationalCommitteeAffair) => {
  const type = 'categories' in e ? e.categories : e['@type'] || '';
  const names = {
    CustomAffairDepositEvent: trans('customAffairs.deposited.tooltip.title'),
    CouncilStatesVote: trans('affair.CouncilStatesVote.tooltip.caption'),
  };

  return (
    names[type] ||
    ('categoryId' in e
      ? trans(`events.termine.categories.${e.categoryId}`)
      : trans(`affair.${type}.tooltip.caption`))
  );
};

export function roundToTwo(num) {
  return +(Math.round(Number(num + 'e+2')) + 'e-2');
}

export const isNational = (item) =>
  (item.categories || item['@type'] || '').indexOf('National') === 0;
export const isCantonal = (item) =>
  (item.categories || item['@type'] || '').indexOf('Cantonal') === 0;
export const isCouncil = (item) =>
  (item.categories || item['@type'] || '').split(',').includes('NationalCouncil');
export const getAffairSection = (affair) =>
  isNational(affair) ? 'national' : isCantonal(affair) ? 'cantonal' : 'custom';

export const sanitizeHtml = (str) => (str ? str.replace(/<[^>]*>?/gm, '') : '');

export const formatDate = (dateString) => moment(dateString).format('YYYY-MM-DDTHH:mm:sszzz');

export const isAffair = (anything): anything is $Affair =>
  typeof anything === 'object' &&
  anything !== null &&
  [SEARCH_TYPES.CANTONAL_AFFAIR, SEARCH_TYPES.NATIONAL_AFFAIR, SEARCH_TYPES.CUSTOM_AFFAIR].includes(
    'categories' in anything ? anything.categories : anything['@type']
  );

export const isEvent = (anything: unknown): anything is $Event => {
  const type = get(anything, 'categories', '').toLowerCase();

  return type.includes('event') || type.includes('vote');
};

export const isParty = (anything: unknown): anything is $Party => {
  const type = get(anything, 'categories', get(anything, '@type')).toLowerCase();

  return type.includes('party');
};

export const isFaction = (anything: unknown): anything is $Faction => {
  const type = get(anything, 'categories', get(anything, '@type')).toLowerCase();

  return type.includes('faction');
};

export const isAffairEvent = (anything) => {
  const affair =
    get(anything, 'affair') || get(anything, 'mainAffair') || get(anything, 'xPltkParent');

  if (!affair) {
    return false;
  }

  return true;
};

// A type guard
export const isConsultation = (anything: unknown): anything is $Consultation =>
  typeof anything === 'object' &&
  anything !== null &&
  [
    SEARCH_TYPES.CANTONAL_AFFAIR_CONSULTATION,
    SEARCH_TYPES.NATIONAL_AFFAIR_CONSULTATION,
    'CustomAffairConsultation',
  ].includes(anything['@type']);

// A type guard
export function isCouncillor(anything: unknown): anything is $Councillor {
  return (
    typeof anything === 'object' &&
    anything !== null &&
    [SEARCH_TYPES.CANTONAL_COUNCILLOR, SEARCH_TYPES.NATIONAL_COUNCILLOR].includes(anything['@type'])
  );
}

export function getAllUrlParams(url): { [key: string]: any } {
  // get query string from url (optional) or window
  var queryString = url ? url.split('?')[1] : window.location.search.slice(1);

  // we'll store the parameters here
  var obj = {};

  // if query string exists
  if (queryString) {
    // stuff after # is not part of query string, so get rid of it
    queryString = queryString.split('#')[0];

    // split our query string into its component parts
    var arr = queryString.split('&');

    for (var i = 0; i < arr.length; i++) {
      // separate the keys and the values
      var a = arr[i].split('=');

      // set parameter name and value (use 'true' if empty)
      var paramName: string = a[0];
      var paramValue = decodeURIComponent(typeof a[1] === 'undefined' ? true : a[1]);

      // (optional) keep case consistent
      paramName = decodeURIComponent(paramName);
      // if (typeof paramValue === "string") paramValue = paramValue.toLowerCase();

      // if the paramName ends with square brackets, e.g. colors[] or colors[2]
      if (paramName.match(/\[(\d+)?\]$/)) {
        // create key if it doesn't exist
        var key = paramName.replace(/\[(\d+)?\]/, '');

        if (!obj[key]) obj[key] = [];

        // if it's an indexed array e.g. colors[2]
        if (paramName.match(/\[\d+\]$/)) {
          // get the index value and add the entry at the appropriate position
          const tmp = /\[(\d+)\]/.exec(paramName);
          var index = tmp && tmp[1];

          obj[key][index] = paramValue;
        }
        else {
          // otherwise add the value to the end of the array
          obj[key].push(paramValue);
        }
      }
      else {
        // we're dealing with a string
        if (!obj[paramName]) {
          // if it doesn't exist, create property
          obj[paramName] = paramValue;
        }
        else if (obj[paramName] && typeof obj[paramName] === 'string') {
          // if property does exist and it's a string, convert it to an array
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        }
        else {
          // otherwise add the property
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
}

export const arrify = (obj) => (Array.isArray(obj) ? obj : [obj]);

export const getPartyAbbr = (party) => (party ? party.abbr || party.fullName || party.slug : '');

export const getFactionName = (faction) => (faction.name || faction.abbr || faction.slug || '');

export const getAffairCantonSlug = (affair) =>
  affair.cantonSlug || (affair.canton && affair.canton.slug);

export const getGroupTitle = (groups, id) => {
  const group = groups.find((g) => g.id === id || g['@id'] === id);

  if(group){
    if (group.parentId || group.parent) { 
      return `${getGroupTitle(groups, group.parentId || group.parent.id) || group.parent.title}  / ${group.title}`;
    }

    return group.title;
  }
};

export const getPath = ({ id, treeData, getNodeKey }) => {
  if (!treeData || treeData.length === 0) {
    return [];
  }

  for (let i = 0; i < treeData.length; i++) {
    const child = treeData[i];
    const key = getNodeKey({ node: child });

    if (key === id) {
      return [key];
    }

    const path = child.children ? getPath({ id, treeData: child.children, getNodeKey }) : [];

    if (path.length) {
      return [key, ...path];
    }
  }

  return [];
};

export const removeElement = (el) => (el.remove ? el.remove() : el.parentNode.removeChild(el));

export const getDays = (events) => {
  const days = {};

  events.forEach((e) => {
    const day = getDate(get(e, 'dtstart', e.dateTime));
    const dayTimeString = moment(day, 'DD.MM.YYYY').toISOString();

    if (Array.isArray(days[dayTimeString])) {
      days[dayTimeString].push(e);
    }
    else {
      days[dayTimeString] = [e];
    }
  });

  return days;
};

export const getItemAuthors = (
  item:
    | $Affair
    | $Event
    | $OldEvent
    | $PressRelease
    | $Councillor
    | $Debate
    | $NationalCommitteeAffair
): $Author[] => {
  if (
    !isAffair(item) &&
    !('categories' in item) &&
    'pressRelease' in item &&
    item['@type'] === 'NationalCommitteePressReleaseNationalAffairEvent'
  ) {
    return getItemAuthors(item.pressRelease);
  }

  const authorList = ('authors' in item ? item.authors || [] : []).concat(
    'xPltkAuthorities' in item ? item.xPltkAuthorities : []
  );

  return authorList
    .map((a) => (a['@type'] === 'CantonalAffairAuthor' ? get(a, 'author') : a))
    .concat('author' in item ? item.author : [])
    .concat('authorCouncillor' in item ? item.authorCouncillor : [])
    .concat('councillor' in item ? item.councillor : [])
    .concat('authorCommittee' in item ? item.authorCommittee : [])
    .concat('nationalCommittee' in item ? item.nationalCommittee : [])
    .concat('nationalCommittees' in item ? item.nationalCommittees : [])
    .concat('authorFaction' in item ? item.authorFaction : [])
    .concat('nationalFaction' in item ? item.nationalFaction : [])
    .concat('party' in item ? item.party : [])
    .concat('faction' in item ? item.faction : [])
    .concat('committee' in item ? item.committee : [])
    .concat(isConsultation(item) ? item.department : [])
    .filter((a) => typeof a === 'object' && a !== null);
};

export const getItemSearchAuthors = (
  item:
    | $Affair
    | $Event
    | $OldEvent
    | $PressRelease
    | $Councillor
    | $Debate
    | $NationalCommitteeAffair
): $Author[] => {
  const authorList = 'authors' in item ? item.authors || [] : [];

  return authorList
    .map((a) => (a['@type'] === 'CantonalAffairAuthor' ? get(a, 'author') : a))
    .concat(isConsultation(item) ? item.departments : [])
    .filter((a) => typeof a === 'object' && a !== null);
};

export const getCouncilType = (iri: string) => {
  if (iri) {
    return Number(iri[iri.length - 1]);
  }

  return null;
};

export const range = (start: number, end: number) =>
  new Array(end - start + 1).fill(null).map((v, i) => start + i);

export const isLinkAbsolute = (url) => {
  const pat = /^https?:\/\//i;

  return pat.test(url);
};

export const comparePrimitiveArrays = (a1, a2) => {
  if (a1.length !== a2.length) {
    return false;
  }

  const a1Sorted = [...a1].sort();
  const a2Sorted = [...a2].sort();

  return a1Sorted.every((item, i) => item === a2Sorted[i]);
};

export const compareArrays = (a1: unknown[], a2: unknown[]) => {
  if (a1.length !== a2.length) {
    return false;
  }

  return a1.every((item, i) => item === a2[i]);
};

export const capitalize = (str: string) =>
  str.length === 0 ? '' : str[0].toUpperCase().concat(str.slice(1));

export const makeFirstLetterLowerCase = (str: string) =>
  str.length === 0 ? '' : str[0].toLowerCase().concat(str.slice(1));

export const isMidnight = (dateTime) => dateTime.substr(11, 5) === '00:00';

export const makeElementLinksAbsolute = (el) => {
  const anchors = el.querySelectorAll('a');

  anchors.forEach((a) => {
    if (!isLinkAbsolute(a.getAttribute('href'))) {
      a.setAttribute('href', `//${a.getAttribute('href')}`);
    }
  });
};

export const getParentAffair = (event) =>
  event.nationalAffair ||
  event.cantonalAffair ||
  event.customAffair ||
  event.nationalAffairConsultation ||
  event.cantonalAffairConsultation ||
  event.mainAffair ||
  event.affair ||
  event.xPltkParent;

export const isISOString = (date) => date && date.length === 24;

export const getNumbersFromDate = (dateString, config = { withNulls: false, string: false }) => {
  if (!dateString) {
    return [];
  }

  const string = dateString || moment().toISOString();
  const isISO = isISOString(string);

  return (isISO ? getDate(string) : string)
    .split('.')
    .map((str) => (str ? (config.string ? str : Number(str)) : config.withNulls ? null : 0));
};

export const getTimestamp = (dateString) => {
  const numbers = getNumbersFromDate(dateString);

  return moment().date(numbers[0]).month(numbers[1]).year(numbers[2]).valueOf();
};

export const mapToOptions = (array) =>
  array.map((item) => ({
    value: item.value,
    label: item.label,
    id: item.id,
    title: item.title,
    disabled: item.disabled,
    key: item.key,
  }));

export const getAge = (date) => {
  return moment().diff(moment(date, 'YYYYMMDD'), 'years');
};

export const filterCouncillors = (councillors, gender, fromAge, toAge?) =>
  councillors.filter((c) => {
    if (fromAge && getAge(c.birthDate) <= fromAge) {
      return false;
    }
    else if (toAge && getAge(c.birthDate) > toAge) {
      return false;
    }

    return c.gender === gender;
  }).length;

const deepCompare = (a: object, b: object, iteratees, orders, locale = 'de') => {
  if (!iteratees.length || !orders.length) {
    return 0;
  }

  const iterate = iteratees[0];
  const orderDir = orders[0] === 'asc';
  const valueA = iterate(a);
  const valueB = iterate(b);

  if (valueA === valueB) {
    return deepCompare(
      a,
      b,
      iteratees.slice(1, iteratees.length),
      orders.slice(1, orders.length),
      locale
    );
  }
  else if (typeof valueA === 'number') {
    return (valueA - valueB) * (orderDir ? 1 : -1);
  }

  return new Intl.Collator(locale).compare(iterate(orderDir ? a : b), iterate(orderDir ? b : a));
};

export const orderBy = (collection, iteratees, orders, locale = 'de') => {
  let ordered = [...collection];

  ordered.sort((a, b) => deepCompare(a, b, iteratees, orders, locale));

  return ordered;
};

export const getUserAccessObject = (user) => ({
  national: user.roles.includes(ROLES.ROLE_AREA_NATIONAL),
  cantonal: user.roles.includes(ROLES.ROLE_AREA_CANTONAL),
  custom: true,
  links: {
    read: user.roles.includes(ROLES.ROLE_LINKS_R),
    write: user.roles.includes(ROLES.ROLE_LINKS_W),
  },
  customLinks: {
    read: user.roles.includes(ROLES.ROLE_CUSTOMLINKS_R),
    write: user.roles.includes(ROLES.ROLE_CUSTOMLINKS_W),
  },
  readOnly:
    user.roles.includes(ROLES.ROLE_FULL_READONLY) &&
    !user.roles.includes(ROLES.ROLE_FULL_READWRITE),
  assignedUsers: {
    read: user.roles.includes(ROLES.ROLE_ASSIGNEDAFFAIRS_R),
    write: user.roles.includes(ROLES.ROLE_ASSIGNEDAFFAIRS_W),
  }
});

export function isLinkedUser(anything: unknown): anything is $LinkedUser {
  return typeof anything === 'object' && anything !== null && anything['@type'] === 'User';
}

export const getObjectOfTypes = <Obj extends any>(array: Obj[]): { [key: string]: any[] } =>
  array.reduce((acc, cur) => {
    if (acc[cur['@type']]) {
      return { ...acc, [cur['@type']]: acc[cur['@type']].concat(cur) };
    }
    else {
      return { ...acc, [cur['@type']]: [cur] };
    }
  }, {});
export const getSessionListCantonalOptions = (sessionListCantonal, filters) =>
  sessionListCantonal
    .filter(({ key }) =>
      filters.search.cantons !== 'none' && filters.search.cantons.length > 0
        ? filters.search.cantons.find((canton) => key === canton)
        : true
    )
    .map((o) => ({ label: o.label, value: o.value }));

export function measureText(str: string, fontSize: number = 10) {
  const widths = [
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875, 0.8890625, 0.665625, 0.190625, 0.3328125,
    0.3328125, 0.3890625, 0.5828125, 0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875,
    0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875,
    0.5546875, 0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625, 0.665625,
    0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625, 0.721875, 0.2765625, 0.5, 0.665625,
    0.5546875, 0.8328125, 0.721875, 0.7765625, 0.665625, 0.7765625, 0.721875, 0.665625, 0.609375,
    0.721875, 0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875, 0.2765625,
    0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875, 0.5546875, 0.2765625,
    0.5546875, 0.5546875, 0.221875, 0.240625, 0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875,
    0.5546875, 0.5546875, 0.3328125, 0.5, 0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5,
    0.3546875, 0.259375, 0.353125, 0.5890625,
  ];
  const avg = 0.5279276315789471;

  return (
    str
      .split('')
      .map((c) => (c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg))
      .reduce((cur, acc) => acc + cur) * fontSize
  );
}

export function getNationalLegislativePeriodInformation(data) {
  const periods = data['hydra:member'].map((p) => p['@id']);

  return {
    period: periods[periods.length - 1],
    periodOptions: data['hydra:member']
      .map((lp) => ({ label: lp.title, value: lp['@id'] }))
      .reverse(),
  };
}

export function getLastStartedSessionOptionIndex(sessions: $SessionOptions[]) {
  const now = moment();
  const sortedStartedSessions = sessions
    .map((a, i) => ({
      index: i,
      value: a,
    }))
    .filter(({ value }) => now.isAfter(value.sessionTo, 'day'));

  return sortedStartedSessions.length ? sortedStartedSessions[0].index : 0;
}

export function getAllNotEmptyFields(obj = {}) {
  const result = Object.entries(obj).reduce((acc, [key, value]) => {
    if (value && (Array.isArray(value) ? value.length : true)) {
      return { ...acc, [key]: value };
    }

    return acc;
  }, {});

  return result;
}

export const getOrderEntries = (
  params: any
): {
  orderBy: string;
  orderDirection: 'ASC' | 'DESC';
} => {
  const orderBodyKey = Object.keys(params).find((key) => key.startsWith('order'));
  const orderBy = orderBodyKey
    ? orderBodyKey.substring(orderBodyKey.indexOf('[') + 1, orderBodyKey.indexOf(']'))
    : 'none';
  const orderDirection = orderBodyKey ? params[orderBodyKey] : 'DESC';

  return {
    orderBy,
    orderDirection,
  };
};

// Those are the fields we send as a payload to the search API:

// @type - mandatory
// affairStates: - this is sent by default now, but it's "undefined" initially (I have to fix this, just discovered it).........
// api: 1 - mandatory
// cantonalCustomSpeakers: [] - added by default when NOT using the Councillor API
// cantons: [] - mandatory
// councilStatesCustomSpeakers: [] -  added by default when NOT using the Councillor API
// depositDate: {before: null, after: null} - mandatory
// itemsPerPage: 30 - mandatory
// nationalCouncilCustomSpeakers: [] - added by default when NOT using the Councillor API
// order: {none: "DESC"} - only if we have order and orderDirection set on the front end
// page: 1 - mandatory
// query: "" - mandatory
// statuses: [] - only added if we are NOT using the Councillor API and if the value is present
// subscribed - mandatory
// affairTypes - only added if the value is present, the code is spaghetti
// affairGroups - only added if the value is present
// nationalCommitteesWithPrograms - only added if the value is present
// customEventTypes - as far as I see: an array, only added if the value is "note" or "internalVote"
// internalFields - only added if the value is present
export const constructSearchPayload = (bodyFormData) => {
  const {
    type,
    page,
    query,
    affairTypes,
    affairStates,
    cantons,
    affairGroups,
    customEventTypes,
    lastStatuses,
    depositDate,
    internalFields,
    itemsPerPage,
    nationalCommitteesWithPrograms,
    subscribed,
    cantonalCustomSpeakers,
    nationalCouncilCustomSpeakers,
    councilStatesCustomSpeakers,
    nationalSessions,
    assignedUsers,
    authors,
  } = bodyFormData;

  const { orderBy, orderDirection } = getOrderEntries(bodyFormData);

  const body: any = {
    '@type': type === SEARCH_TYPES.ALL_AFFAIRS ? [SEARCH_TYPES.NATIONAL_AFFAIR,
      SEARCH_TYPES.CANTONAL_AFFAIR,
      SEARCH_TYPES.CUSTOM_AFFAIR,
      SEARCH_TYPES.NATIONAL_AFFAIR_CONSULTATION,
      SEARCH_TYPES.CANTONAL_AFFAIR_CONSULTATION
    ]
      :[type],
    page,
    query,
    depositDate,
    subscribed,
    cantons,
    affairStates,
    itemsPerPage: parseInt(itemsPerPage),
  };

  // Councillor's API doesn't support cantonalCustomSpeakers, nationalCouncilCustomSpeakers,
  // councilStatesCustomSpeakers and statuses, so we add them conditionally
  if (![SEARCH_TYPES.NATIONAL_COUNCILLOR, SEARCH_TYPES.CANTONAL_COUNCILLOR].includes(type)) {
    body.cantonalCustomSpeakers = cantonalCustomSpeakers;
    body.nationalCouncilCustomSpeakers = nationalCouncilCustomSpeakers;
    body.councilStatesCustomSpeakers = councilStatesCustomSpeakers;

    if (lastStatuses) {
      body.lastStatuses = lastStatuses;
    }
  }

  if (nationalSessions) {
    body.nationalSessions = nationalSessions;
  }

  if (orderBy && orderDirection) {
    body.order = { [orderBy]: arrify(orderDirection)[0].toUpperCase()};
  }

  if (affairTypes && affairTypes.length > 0) {
    body.affairTypes = affairTypes.map((n) =>
      Routing.generate(
        type === SEARCH_TYPES.NATIONAL_AFFAIR
          ? 'api_national_affair_types_get_item'
          : 'api_cantonal_affair_types_get_item',
        {
          id: Number(n),
        }
      )
    );
  }

  if (affairGroups && affairGroups.length > 0) {
    body.affairGroups = affairGroups;
  }

  if (nationalCommitteesWithPrograms && nationalCommitteesWithPrograms.length > 0) {
    body.nationalCommitteesWithPrograms = nationalCommitteesWithPrograms;
  }

  if (customEventTypes === 'note') {
    body.customEventTypes = ['note'];
  }
  else if (customEventTypes === 'internalVote') {
    body.customEventTypes = ['internalVote'];
  }

  if (internalFields && internalFields.length > 0) {
    body.internalFields = internalFields;
  }

  if (assignedUsers) {
    body.assignedUsers = assignedUsers;
  }

  if (authors) {
    body.authors = authors;
  }

  return body;
};

export const getSearchBodyFormData = (filters) => {
  const {
    query,
    type,
    affairOrderBy,
    councillorOrderBy,
    consultationOrderBy,
    orderDirection,
    affairTypes,
    affairStates,
    cantons,
    page,
    itemsPerPage,
    affairGroups,
    customEventTypes,
    lastStatuses,
    nationalSessions,
    depositDate,
    internalFields,
    nationalCommitteesWithPrograms,
    subscribed,
    cantonalCustomSpeakers,
    nationalCouncilCustomSpeakers,
    councilStatesCustomSpeakers,
    assignedUsers,
    authors,
  } = filters;

  let order;

  if (
    type === SEARCH_TYPES.ALL_AFFAIRS ||
    type === SEARCH_TYPES.NATIONAL_AFFAIR ||
    type === SEARCH_TYPES.CANTONAL_AFFAIR ||
    type === SEARCH_TYPES.CUSTOM_AFFAIR
  ) {
    order = affairOrderBy;
  }
  else if (
    type === SEARCH_TYPES.NATIONAL_COUNCILLOR ||
    type === SEARCH_TYPES.CANTONAL_COUNCILLOR
  ) {
    order = councillorOrderBy;
  }
  else if (
    type === SEARCH_TYPES.NATIONAL_AFFAIR_CONSULTATION ||
    type === SEARCH_TYPES.CANTONAL_AFFAIR_CONSULTATION
  ) {
    order = consultationOrderBy;
  }

  // We return only the fields that don't have "none" as a value
  return omitBy(
    {
      query,
      type,
      page,
      [`order[${order}]`]: orderDirection || 'DESC',
      affairTypes,
      affairStates,
      cantons,
      itemsPerPage,
      affairGroups,
      nationalSessions,
      customEventTypes,
      lastStatuses,
      depositDate,
      internalFields: internalFields || 'none',
      nationalCommitteesWithPrograms: nationalCommitteesWithPrograms || 'none',
      subscribed,
      cantonalCustomSpeakers,
      nationalCouncilCustomSpeakers,
      councilStatesCustomSpeakers,
      assignedUsers,
      authors,
    },
    (i) => i === 'none'
  );
};

export const getLanguageKey = (languageKey: any): LanguageKey => {
  switch (languageKey) {
  case 'en':
  case 'de':
  case 'fr':
  case 'it':
    return languageKey;
  default:
    return Translator.fallback;
  }
};

export const getDataLanguageKey = (user: $User, prefix: string = 'slug') => {
  // Fallback for english for the data is deautch
  // english is not a valid slug key

  const dataLanguage = get(user, 'settings.data_language.value');
  const languageValue = get(user, 'settings.language.value');
  const userLang = capitalize(dataLanguage || languageValue || 'De');
  const lang = userLang !== 'En' ? userLang : 'De';

  return `${prefix}${lang}`;
};

export const getShortId = (element: { '@id': string }) => {
  const longId = element['@id'];
  const indexOfLastSlash = longId.lastIndexOf('/');

  return longId.substring(indexOfLastSlash + 1, longId.length);
};

export const hasTranslation = (key: string) => trans(key) !== key;

export const getReportFormValue = (filters: $ReportFilters, type = 'all') => {
  const search = { ...filters.search };

  const nationalAffairTypes = ['all', 'national'].includes(type) ? filters.nationalAffairTypes : [];
  const cantonalAffairTypes = ['all', 'cantonal'].includes(type) ? filters.cantonalAffairTypes : [];

  search.affairTypes = Array.from(new Set([...nationalAffairTypes, ...cantonalAffairTypes]));

  return JSON.stringify(omitBy(search, (i) => i === 'none'));
};

export const getDefaultFilters1 = (user, type): $ReportFilters => {
  const internal_fieldsState = Object.fromEntries(
    get(user, 'company.settings.internal_fields.value', []).map((s) => [
      `outputInternalFields_${last(s['@id'].split('/'))}`,
      true,
    ])
  );

  return {
    groups: [],
    nationalAffairTypes: [],
    cantonalAffairTypes: [],
    search: {
      query: '',
      affairGroups: [],
      lastEventDate: { before: null, after: null },
      depositDate: { before: null, after: null },
      nationalSessions: [],
      cantonalSessionPrograms: [],
      nationalCommitteesWithPrograms: [],
      cantonalCustomSpeakers: [],
      nationalCouncilCustomSpeakers: [],
      councilStatesCustomSpeakers: [],
      assignedUsers: [],
      internalFields: [],
      ['@type']:
        type === REPORT_TYPES.CANTONAL_SESSION_PROGRAM
          ? [SEARCH_TYPES.CANTONAL_AFFAIR as any]
          : type === REPORT_TYPES.NATIONAL_SESSION_PROGRAM
            ? [SEARCH_TYPES.NATIONAL_AFFAIR]
            : [],
      affairType: [],
      affairTypes: [],
      affairStates: 'none',
      customEventType: 'none',
      cantons: [],
      lastStatuses: [],
      affairOrderBy: 'none',
      councillorOrderBy: 'none',
      consultationOrderBy: 'none',
      orderDirection: 'DESC',
      customEventTypes: 'none',
      subscribed: false,
    },
    affair: null,
    title: '',
    date: null,
    author: '',
    subtitle: '',
    format: formats[0].value,
    outputEmptyGroups: 1,
    outputId: 1,
    outputCanton: 1,
    outputTitle: 1,
    outputAuthors: 1,
    outputInternalAffairAuthors: 1,
    outputDepartment: 1,
    outputDepositDate: 1,
    outputLastEventDate: 1,
    outputStatus: 1,
    outputDescription: 1,
    outputSpeaker: 1,
    outputTableOfContents: 1,
    numberingTableOfContents: 1,
    outputLink: 1,
    outputInternalLink: 1,
    outputAssignedGroups: 1,
    outputAssignedUsers: 1,
    outputHistory: historyOptions[0].value,
    outputLinkedAffairs: linkedAffairsOptions[0].value,
    ...internal_fieldsState,
  };
};

export const getAdvancedCouncillor = ({
  councillorsNo,
  councillorsYes,
  councillorsP,
  councillorsNt,
  councillorsEs,
  councillorsEh,
  councillorsFactions,
}) => {
  const decisionMapper = {
    councillorsNo: {
      '@id': '/api/decision_types/2',
      '@type': 'DecisionType',
      valueParl: 'No',
    },
    councillorsYes: {
      '@id': '/api/decision_types/1',
      '@type': 'DecisionType',
      valueParl: 'Yes',
    },
    councillorsP: {
      '@id': '/api/decision_types/6',
      '@type': 'DecisionType',
      valueParl: 'P',
    },
    councillorsNt: {
      '@id': '/api/decision_types/4',
      '@type': 'DecisionType',
      valueParl: 'NT',
    },
    councillorsEs: {
      '@id': '/api/decision_types/5',
      '@type': 'DecisionType',
      valueParl: 'ES',
    },
    councillorsEh: {
      '@id': '/api/decision_types/3',
      '@type': 'DecisionType',
      valueParl: 'EH',
    },
  };

  const descisionObject = {
    councillorsNo,
    councillorsYes,
    councillorsP,
    councillorsNt,
    councillorsEs,
    councillorsEh,
  };

  const mappedCouncillors = Object.entries(descisionObject)
    .map(([key, value]) =>
      (value || []).map((councillor) => {
        const mappedCouncillor = { ...councillor };

        if (councillorsFactions) {
          const id = councillor['@id'];
          const factionKey = id.substring(id.lastIndexOf('/') + 1, id.length);

          if (councillorsFactions[factionKey]) {
            mappedCouncillor.faction = councillorsFactions[factionKey];
          }
        }

        return {
          councillor: mappedCouncillor,
          decision: decisionMapper[key],
          party: councillor.party,
        };
      })
    )
    .flat();

  return mappedCouncillors;
};

export const getLangRoleType = (lang: string) => {
  switch (lang) {
  case 'de':
    return {
      cosigner: 'Mitunterzeichner(-in)',
      opponent: 'Bekämpfer(-in)',
      adapters: 'Übernehmer(-in)',
    };
  case 'fr':
    return {
      cosigner: 'Cosignataire',
      opponent: 'Opposant(e)',
      adapters: 'Repreneur',
    };
  case 'it':
    return {
      cosigner: 'Cofirmatario',
      opponent: 'Opposto/a',
      adapters: 'Assumente',
    };
  default:
    return {
      cosigner: 'Mitunterzeichner(-in)',
      opponent: 'Bekämpfer(-in)',
      adapters: 'Übernehmer(-in)',
    };
  }
};

export const findPaginationNumbersToShow = (
  currentPage: number,
  paginationSize: number,
  pagesTotal: number,
  range: number[]
) => {
  let nubmersArray: number[] = [];

  if (currentPage < Math.ceil(paginationSize / 2)) {
    nubmersArray = range;
  }
  else if (pagesTotal - Math.floor(paginationSize / 2) < currentPage) {
    nubmersArray = range.map((i) => pagesTotal - paginationSize + i);
  }
  else {
    nubmersArray = range.map((i) => currentPage - Math.ceil(paginationSize / 2) + i);
  }

  return nubmersArray.filter((n) => n > 0 && n <= pagesTotal);
};

export const createVisualizerPublicLink = (visualReportId: string) => {
  const publicLinkPathName = generateVisualReportPublicLink(visualReportId);
  const { origin } = document.location;

  return `${origin}${publicLinkPathName}`;
};