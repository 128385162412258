import React, { Component, ReactText } from "react";
import { showDangerAlert } from "../NotificationPopup";
import { Spinner } from "../Spinner";
import FormControl from "@material-ui/core/FormControl";
import {IntegrationReactSelect} from "./MaterialMultipleSelect";
import { getAvailableParticipants } from "@api/customMeetings";
import { trans } from "@trans";

export class CouncillorSelect extends Component<{
  value?: string | number | ReactText[] | string[],
  onChange: Function,
  margin?: "none" | "dense" | "normal",
  placeholder?: string,
}, {
  loading: boolean,
  data: $UI_SelectOption[],
  error: boolean
}> {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      error: false
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loading: true });

    getAvailableParticipants()
      .then(({ data }) =>
        this.setState(
          () => ({
            data: data["hydra:member"],
            loading: false
          }),
          () =>
            this.props.value === undefined &&
            this.props.onChange({
              target: { value: data["hydra:member"].map(c => c['@id']) }
            })
        )
      )
      .catch(() => {
        this.setState({ loading: false, error: true });
        showDangerAlert(trans("councillors.error.get"));
      });
  };

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }

    return (
      <FormControl
        className='mr-1'
        margin={this.props.margin || "normal"}
        style={{ zIndex: 2, minWidth: '247px', marginBottom: '0px' }}>
        <IntegrationReactSelect
          {...this.props}
          onChange={this.props.onChange}
          value={this.props.value}
          itemLabel={item => item.fullName}
          label={trans(`ui.select.councillors.label`)}
          options={this.state.data} />
      </FormControl>
    );
  }
}
