import { Reducer } from "redux";
import { cloneDeep } from "lodash";
import { FETCH_USER, UPDATE_GROUP_SORTING_SETTING, UPDATE_USER_DASHBOARD_QUICKLINKS_SETTING } from "../actionTypes";

const document = global.document;
const root = document && (document.getElementById("root") || document.getElementById("react_sidebar_root"));
const user = root?.dataset.user && JSON.parse(root.dataset.user);

if (user) {
  user.roles = Object.values(user.roles);
  const lang =
        document.documentElement.lang[0].toUpperCase() +
        document.documentElement.lang.slice(1);

  user.settings.lang = lang;
}

const defaultReducer = { data: user, loading: false };

const userReducer: Reducer<$Reducer_user, any> = (state = defaultReducer, action) => {
  switch (action.type) {
  case FETCH_USER:
    return { data: action.payload, loading: false };

  case UPDATE_GROUP_SORTING_SETTING: {
    const { payload } = action;
    const updatedUserData = cloneDeep(state.data);
    const updatedUserSetting = {
      "@id": payload["@id"],
      "@type": payload["@type"],
      value: payload.value,
      name: payload.name
    };

    updatedUserData.settings.affair_groups_sorting = updatedUserSetting;

    return {
      ...state,
      data: updatedUserData
    };
  }

  case UPDATE_USER_DASHBOARD_QUICKLINKS_SETTING: {
    const { payload } = action;
    const updatedUserData = cloneDeep(state.data);
    const updatedUserSettings = {
      ...updatedUserData.settings.dashboard_quicklinks_setting,
      value: payload.value,
    };

    updatedUserData.settings.dashboard_quicklinks_setting = updatedUserSettings;

    return {
      ...state,
      data: updatedUserData,
    };
  }

  default:
    return state;
  }
};

export default userReducer;
