export const FETCH_USER = "fetch_user";
export const UPDATE_GROUP_SORTING_SETTING = "update_group_sorting_setting";
export const UPDATE_USER_DASHBOARD_QUICKLINKS_SETTING = "update_user_dashboard_setting";
export const FETCH_GROUPS = "fetech_groups";
export const FETCH_AFFAIR_TYPES = "fetch_affair_types";
export const FETCH_AFFAIR_STATES = Symbol("fetch_affair_states");
export const FETCH_NATIONAL_SESSIONS = "fetch_national_sessions";
export const FETCH_NATIONAL_COMMITTEES_WITH_PROGRAMS = "fetch_national_committees_with_programs";
export const FETCH_TIMELINE = Symbol("FETCH_TIMELINE");
export const FETCH_GROUP_TREE = "fetch_group_tree";
