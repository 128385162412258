import React, { Component } from "react";
import axios from "axios";
import { SimpleSelect } from "../ui";
import MultipleSelect from "../SelectPickers/MultipleSelect";
import { FormControl } from '../BaseUI';
import { showDangerAlert } from "../NotificationPopup";
import { trans } from "@trans";
import { getUserAccessObject } from '@helpers';
import { searchAffairs, cancel } from "@api/search";
import { uniqBy, debounce } from "lodash";

type $Props = {
  user: $User;
  value: $Affair["@type"];
  loading?: boolean;
  disabled: boolean;
  error: boolean;
  onChange?: Function
}
type $State = {
  selected: $Affair["@type"][],
  filters: {
    query: string;
    type: "National" | "Cantonal";
    contentType: "Affair" | "AffairConsultation"
  },
  searchResults: $Affair[],
  loading: boolean;
}
export class AffairSelect extends Component<$Props, $State> {
  constructor(props: $Props) {
    super(props);
    this.allowedTypes = getUserAccessObject(props.user);
    this.state = {
      selected: [],
      filters: {
        query: "",
        type: this.allowedTypes["national"] ? "National" : "Cantonal",
        contentType: "Affair",
      },
      searchResults: [],
      loading: false,
    };
  }

  componentDidMount() {
    this.updateSearch();
  }

  private allowedTypes: $Access;

  updateSearch = () => {
    this.setState({
      loading: true,
    });
    this.search();
  };

  search = debounce(() => {
    const { filters, searchResults } = this.state;

    if (cancel) {
      cancel();
    }

    const body = {
      "@type": [`${filters.type}${filters.contentType}`],
      query: filters.query,
    };

    searchAffairs(body)
      .then(({ data }: { data: { "hydra:member": $Affair[] } }) => {
        const newSearchResults = uniqBy(
          data["hydra:member"]
            .map((i) => ({
              ...i,
              // id: last(i["@id"].split("/")),
              title: `${i.canton ? `[${i.canton.slug || i.canton.abbr}]` : ""}${i.shortIdParlService || i.slug
                ? `${i.shortIdParlService || i.slug}, `
                : ""
              }${i.code ? `[${i.code}]` : ""}${i.title}`,
            }))
            .concat(searchResults),
          "id"
        );

        this.setState({
          searchResults: newSearchResults,
          loading: false,
        });
      })
      .catch((thrown) => {
        if (!axios.isCancel(thrown)) {
          showDangerAlert(trans("api.search.error"));
        }
      });
  }, 500);

  getSearchOptions = (searchResults) =>
    searchResults.filter(
      (res) =>
        res["@type"].includes(this.state.filters.contentType) &&
        res["@type"].includes(this.state.filters.type)
    );

  render() {
    const allowedTypes = this.allowedTypes;
    const { filters, loading, searchResults } = this.state;
    const { error, disabled, onChange, value } = this.props;
    const options = this.getSearchOptions(searchResults);

    return (
      <>
        <div className='row'>
          <FormControl
            margin='normal'
            className='col-md-4 col-sm-6'
            style={{ zIndex: 2 }}>
            <MultipleSelect
              onChange={(e) => {
                if (onChange) {
                  onChange(e);
                }

                this.setState({ selected: e.target.value });
              }}
              onTypeText={(e) =>
                this.setState(
                  { filters: { ...filters, query: e.target.value } },
                  this.updateSearch
                )}
              value={value}
              placeholder={trans(`reports.affairSelect.placeholder`)}
              label={trans(`reports.affairSelect.label`)}
              loading={loading}
              error={error}
              options={options}
              disabled={this.props.loading}
              multi={false}
              style={{ width: "100%" }} />
          </FormControl>
          <FormControl margin='normal' className='col-md-4 col-sm-6'>
            <SimpleSelect
              label={trans("layout.search.type")}
              value={filters.type}
              disabled={disabled}
              onChange={(e) => {
                this.setState(
                  {
                    filters: {
                      ...filters,
                      type: e.target.value,
                      contentType: e.target.value === "Custom" ? "Affair" : filters.contentType,
                    },
                  },
                  this.updateSearch
                );
              }}
              options={[
                {
                  label: trans("layout.search.type.national"),
                  value: "National",
                  disabled: !allowedTypes["national"],
                },
                {
                  label: trans("layout.search.type.cantonal"),
                  value: "Cantonal",
                  disabled: !allowedTypes["cantonal"],
                },
                {
                  label: trans("layout.search.type.custom"),
                  value: "Custom",
                },
              ]} />
          </FormControl>
          <FormControl margin='normal' className='col-md-4 col-sm-6'>
            <SimpleSelect
              label={trans("layout.search.contentType")}
              value={filters.contentType}
              onChange={(e) => {
                this.setState(
                  {
                    filters: {
                      ...filters,
                      contentType: e.target.value,
                      type: filters.type,
                    },
                  },
                  this.updateSearch
                );
              }}
              options={[
                [trans("layout.search.contentType.affair"), "Affair"],
                [
                  trans("layout.search.contentType.affair_consultation"),
                  "AffairConsultation",
                ],
              ].map(([label, value]) => ({ label, value }))} />
          </FormControl>
        </div>
      </>
    );
  }
}
