import { FETCH_AFFAIR_TYPES } from "../actionTypes";
import { Reducer } from "redux";

const defaultReducer: $Reducer_affairTypes = { data: { national: [], cantonal: [] }, loading: false };

const affairTypesReducer: Reducer<$Reducer_affairTypes, any> = (state = defaultReducer, action) => {
  switch (action.type) {
  case FETCH_AFFAIR_TYPES:
    return action.payload;
  default:
    return state;
  }
};

export default affairTypesReducer;
