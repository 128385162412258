import get from "lodash/get";
import { arrify } from '@helpers';
import { GLOBALS } from "@constants";
import { getAllUrlParams } from '@helpers';
import { RouteComponentProps } from 'react-router';
import { SEARCH_TYPES, SEARCH_SORT_FIELDS, SEARCH_SORT_ORDER_BY, ROLES } from '@constants';

export const getFiltersFromUrl = (location: RouteComponentProps['location'], user: $User): $Search_Filters => {
  const isNationalAllowed = user.roles.includes(ROLES.ROLE_AREA_NATIONAL);
  const isCantonalAllowed = user.roles.includes(ROLES.ROLE_AREA_CANTONAL);
  
  const allowedTypes = {
    NationalAffair: isNationalAllowed,
    NationalCouncillor: isNationalAllowed,
    NationalAffairConsultation: isNationalAllowed,

    CantonalAffair: isCantonalAllowed,
    CantonalCouncillor: isCantonalAllowed,
    CantonalAffairConsultation: isCantonalAllowed,

    CustomAffair: true,
  };
  const params: { get: (any: any) => any } = new URLSearchParams(location.search);
  const params2: { [key: string]: any } = getAllUrlParams(get(location, "href"));
  const typeFromQueryParams = params.get("type");
  
  // checking if the user is allowed to see the type we got from the query params
  const type = allowedTypes[typeFromQueryParams] ? typeFromQueryParams : SEARCH_TYPES.ALL_AFFAIRS;
  
  const cantonal_types = params2["cantonalAffairType"];
  const national_types = params2["nationalAffairType"];
  const nationalSessions = params2["nationalSessions"] || [];
  const itemsPerPage =
    params.get("itemsPerPage") ||
    get(user, "settings.list_size.value") ||
    GLOBALS.DEFAULT_PAGE_SIZE;
  const groups = params2["affairGroups"];
  const affairGroups: $Search_Filters['affairGroups'] =
    type === SEARCH_TYPES.NATIONAL_COUNCILLOR || type === SEARCH_TYPES.CANTONAL_COUNCILLOR
      ? []
      : (groups ? arrify(groups) : []).map(String);
  const statuses: string[] = arrify(params2["statuses"] || []).map(String);
  const internalFields = params2.internalFields
    ? JSON.parse(params2.internalFields).map((f) => ({
      ...f,
      value: f.value || 0,
    }))
    : [];
  const subscribed = Boolean(JSON.parse(params.get("subscribed") || "false"));
  const affairOrderBy: "canton" | "depositDate" | "title" | "none" =
    params.get(SEARCH_SORT_ORDER_BY.AFFAIR) || SEARCH_SORT_FIELDS.BEST_RESULT;
  const councillorOrderBy = params.get(SEARCH_SORT_ORDER_BY.COUNCILLOR) || SEARCH_SORT_FIELDS.BEST_RESULT;
  const depositDate:
    | "none"
    | {
      before: string | null;
      after: string | null;
    }
    | null =
    type === SEARCH_TYPES.NATIONAL_COUNCILLOR || type === SEARCH_TYPES.CANTONAL_COUNCILLOR
      ? "none"
      : {
        before: params.get("depositDate[before]") || null,
        after: params.get("depositDate[after]") || null,
      };

  const cantonalCustomSpeakers = params.get("cantonalCustomSpeakers") || [];
  const nationalCouncilCustomSpeakers = params.get("nationalCouncilCustomSpeakers") || [];
  const councilStatesCustomSpeakers = params.get("councilStatesCustomSpeakers") || [];
  const assignedUsers = params.get("assignedUsers") || "none";
  const authors = params.get("authors") || "none";

  return {
    query: params.get("query") || "",
    type,
    page: Number(params.get("page")) || 1,
    affairOrderBy,
    councillorOrderBy,
    consultationOrderBy: params.get(SEARCH_SORT_ORDER_BY.CONSULTATION) || SEARCH_SORT_FIELDS.BEST_RESULT,
    orderDirection: params.get("orderDirection") || "DESC",
    affairTypes: cantonal_types || national_types || "none",
    affairStates: "none",
    cantons: params2.cantons || "none",
    itemsPerPage,
    affairGroups,
    customEventTypes:
      type === SEARCH_TYPES.NATIONAL_COUNCILLOR || type === SEARCH_TYPES.CANTONAL_COUNCILLOR
        ? "none"
        : params.get("customEventTypes") || "none",
    depositDate,
    lastStatuses: statuses,
    internalFields,
    nationalSessions,
    subscribed,
    cantonalCustomSpeakers,
    nationalCouncilCustomSpeakers,
    councilStatesCustomSpeakers,
    assignedUsers,
    authors
  };
};