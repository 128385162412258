import React, { useState } from "react";
import styled from "styled-components";
import Popper from "@material-ui/core/Popper";
import { IconButton } from "@components/Shared/IconButton";
import { ClickAwayListener}  from "@components/Shared/ClickAwayListener";
import Paper from "@material-ui/core/Paper";

const PopoverWithStyles = styled(Popper)`
  & .MuiPaper-root {
    overflow: visible;
  }
`;
const PopoverContent = styled.div`
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;

export const ActionBarItem = function Action({
  onClick,
  children,
  icon,
  title,
  disabled,
  selectedItems,
  color
}: { onClick?: Function, children?, icon?: string, title?: string, disabled?: boolean, selectedItems: ($Affair | $Event["xPltkParent"])[], color?: string }) {
  if (!icon) {
    return null;
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div title={title}>
      <IconButton
        data-test='group-assign'
        aria-describedby={id}
        color={color || 'primary'}
        onClick={e => {
          if (children) {
            handleClick(e);
          }

          if (onClick) {
            onClick(selectedItems);
          }
        }}
        disabled={disabled}
        icon={icon} />

      {children && (
        <PopoverWithStyles
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}>
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <PopoverContent>
                {React.Children.toArray(children).map((el: any) =>
                  React.cloneElement(el, { close: handleClose })
                )}
              </PopoverContent>
            </ClickAwayListener>
          </Paper>
        </PopoverWithStyles>
      )}
    </div>
  );
};
