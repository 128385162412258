import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, withStyles } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { SPACINGS } from '@constants';

const CustomAccordion = withStyles({
  root: {
    padding: `${SPACINGS.X3} ${SPACINGS.X3} ${SPACINGS.X6} ${SPACINGS.X3}`,
    borderRadius: "6px",
    margin: `${SPACINGS.X3} auto`,
    "&:before": {
      display: "none"
    },
    "&.Mui-expanded": {
      margin: `${SPACINGS.X3} auto`,
      minHeight: 0,
    },
    "& .MuiAccordionSummary-root": {
      padding: 0,
      minHeight: 0,
      margin: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      "&.Mui-expanded": {
        margin: 0,
      }
    },
    "& .MuiAccordionDetails-root": {
      display: 'block',
      margin: 'auto',
      padding: 0,
      paddingTop: SPACINGS.X6,
    },
    "& .MuiIconButton-edgeEnd": {
      margin: 0,
      padding: 0,
      paddingLeft: '12px',
      paddingRight: '12px',
    },
    "& .MuiAccordionSummary-expandIcon": {
      color: 'black',
      cursor: "pointer"
    }
  },
  expanded: {}
})(Accordion);


export const AccordionCard = ({ summary, details, expanded = true, style = {} }) => {
  return (
    <CustomAccordion
      style={{ ...style }}
      elevation={0}
      defaultExpanded={expanded}>
      <AccordionSummary
        expandIcon={<ExpandMore/>}
        aria-controls='panel1a-content'
        id='panel1a-header'>
        {summary}
      </AccordionSummary>
      <AccordionDetails style={{paddingTop: SPACINGS.X5}}>
        {details}
      </AccordionDetails>
    </CustomAccordion>
  );
};