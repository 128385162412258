import React, { ChangeEvent, ReactNode } from 'react';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MaterialCheckbox from "@material-ui/core/Checkbox";
import Icon from '@components/Shared/InventoryIcon';
import { COLORS } from '@constants';
import styled from "styled-components";

const NotCheckedIcon = styled.div`
  border-radius: 6px;
  border: 1px solid ${COLORS.GRAY4};
  width: 20px;
  height: 20px;
`;

const DisabledIcon = styled.div`
  border-radius: 6px;
  background-color: ${COLORS.LIGHTGRAY};
  width: 20px;
  height: 20px;
`;

const CheckedIcon = styled.div`
  border-radius: 6px;
  border: 1px solid ${COLORS.PRIMARY};
  width: 20px;
  height: 20px;
  background-color: ${COLORS.PRIMARY};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const DisabledCheckedIcon = styled.div`
  border-radius: 6px;
  width: 20px;
  height: 20px;
  background-color: ${COLORS.LIGHTGRAY};
  display: flex;
  justify-content: center;
  align-items: center;
`;

type $Props = {
  checked: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  label?: string | ReactNode;
  disabled?: boolean,
  style?: object,
  title?: string,
  name?: string,
  color?: string,
  indeterminate?: boolean,
  value?: any
}

export const Checkbox = ({
  checked,
  onChange,
  label,
  name,
  disabled = false,
  style = {},
  title,
  color,
  value,
  indeterminate
}: $Props) => {
  return (
    <>
      <FormControlLabel
        style={{
          margin: 0,
          color: disabled ? COLORS.MEDIUM_GREY : COLORS.DARK_GREY,
          width: 'fit-content',
          cursor: disabled ? 'not-allowed' : '',
          ...style
        }}
        color={color}
        title={title}
        value={value}
        name={name}
        control={<MaterialCheckbox
          name={name}
          title={title}
          style={{ zIndex: 0 }}
          checked={checked}
          indeterminate={indeterminate}
          onChange={onChange}
          icon={disabled ? <DisabledIcon /> : <NotCheckedIcon />}
          checkedIcon={disabled
            ? (
              <DisabledCheckedIcon>
                <Icon icon='ui.check' style={{
                  color: COLORS.WHITE,
                  fontSize: 12,
                }} />
              </DisabledCheckedIcon>
            )
            : (
              <CheckedIcon>
                <Icon icon='ui.check' style={{
                  color: COLORS.WHITE,
                  fontSize: 12,
                }} />
              </CheckedIcon>
            )}
          indeterminateIcon={<CheckedIcon>
            <Icon
              icon='ui.indeterminate'
              style={{
                color: COLORS.WHITE,
                fontSize: 12
              }} />
          </CheckedIcon>}
          value='checkedB'
          color='primary'
          data-test={label || 'checkbox'}
          disabled={disabled}/>}
        label={label} />
    </>
  );
};
