import { API_CALL_SUCCESS, CLEAR_API_CACHE } from "./actionTypes";

const cacheReducer = (state = {}, action) => {
  switch(action.type) {
  case API_CALL_SUCCESS: {
    const {
      key,
      value
    } = action.payload;

    return {...state, [key]: value};
  }

  case CLEAR_API_CACHE: {
    return {};
  }

  default: {
    return {...state};
  }
  }
};

export default cacheReducer;