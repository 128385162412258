export const SPACINGS = {
  X1: "8px",
  X2: "16px",
  X3: "24px",
  X4: "32px",
  X5: "40px",
  X6: "48px",
  X7: "56px",
  X8: "64px",
  X9: "72px",
  X10: "80px",
  X11: "88px",
  X12: "96px",
  X13: "104px",
  X14: "112px",
  X15: "120px",
  X16: "128px",
  X17: "136px",
  X18: "144px",
  X19: "152px",
  X20: "160px",
  X30: "240px",
  X40: "320px",
  X50: "400px",
};