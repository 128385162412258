import React, { PureComponent, Suspense, lazy, useEffect, useState } from "react";
import {
  Route as ReactRoute,
  Switch,
  BrowserRouter,
  withRouter,
  Redirect
} from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from "react-ga";
import get from "lodash/get";

import { Routing } from '@backendRouter';
import { GLOBALS, ROLES, SPACINGS } from '@constants';
import { componentLoader } from "./js/script/componentLoader";
import { useLocation } from "react-router";

import { isLoggedIn } from '@api/auth';
import { Spinner, ErrorBoundary } from '@components/Shared';
import { Page } from '@components/Page';
import { withTranslation } from "@redux/helpers/translations";
import { withTranslationProps } from "@redux/helpers/translations/Types";


const NationalConsultations = lazy(() => componentLoader(() => import("./js/Pages/National/Consultations")));
const NationalSessionVotes = lazy(() => componentLoader(() => import("./js/Pages/National/SessionVotes")));
const NationalSessionPrograms = lazy(() => componentLoader(() => import("./js/Pages/National/SessionPrograms")));
const NationalCommittees = lazy(() => componentLoader(() => import("./js/Pages/National/Committees")));
const NationalCommittee = lazy(() => componentLoader(() => import("./js/Pages/National/Committee")));
const NationalCommitteePressRelease = lazy(() => componentLoader(() => import("./js/Pages/National/CommitteePressRelease")));
const NationalCouncillor = lazy(() => componentLoader(() => import("./js/Pages/National/Councillor")));
const CantonalCanton = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/Canton")));
const CantonalOverview = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/Overview")));
const CantonalAffairs = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/Affairs")));
const CantonalCouncillors = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/Councillors")));
const CantonalCouncillor = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/CantonalCouncillor")));
const CantonalCommittees = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/Committees")));
const CantonalCommittee = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/Committee")));
const CantonalMiscAuthors = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/MiscAuthors")));
const CantonalMiscAuthor = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/MiscAuthor")));
const CantonalFactions = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/Factions")));
const CantonalFaction = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/Faction")));
const CantonalParties = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/Parties")));
const CantonalParty = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/Party")));
const CantonalSessions = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/Sessions")));
const CantonalSession = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/Session")));
const CantonalConsultations = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/Consultations")));
const CantonalMediaReleases = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/MediaReleases")));
const CantonalMediaRelease = lazy(() => componentLoader(() => import("./js/Pages/Cantonal/CantonalMediaRelease")));
const Meetings = lazy(() => componentLoader(() => import("./js/Pages/Meetings")));
const MeetingsArchived = lazy(() => componentLoader(() => import("./js/Pages/MeetingsArchived")));
const Meeting = lazy(() => componentLoader(() => import("./js/Pages/Meeting")));
const Groups = lazy(() => componentLoader(() => import("./js/Pages/Groups")));
const GroupsArchived = lazy(() => componentLoader(() => import("./js/Pages/GroupsArchived")));
const GroupOverview = lazy(() => componentLoader(() => import("./js/Pages/GroupOverview")));
const CustomAffairs = lazy(() => componentLoader(() => import("./js/Pages/CustomAffairs")));
const Notifications = lazy(() => componentLoader(() => import("./js/Pages/Notifications")));
const Dashboard = lazy(() => componentLoader(() => import("./js/Pages/Dashboard/Dashboard")));
const Calendar = lazy(() => componentLoader(() => import("./js/Pages/Calendar")));
const Affair = lazy(() => componentLoader(() => import("./js/Pages/Affair")));
const Consultation = lazy(() => componentLoader(() => import("./js/Pages/Consultation")));
const Search = lazy(() => componentLoader(() => import("./js/Pages/Search")));
const SettingsProfile = lazy(() => componentLoader(() => import("./js/Pages/Settings/Profile")));
const SettingsPasswordEdit = lazy(() => componentLoader(() => import("./js/Pages/Settings/PasswordEdit")));
const SettingsAuth = lazy(() => componentLoader(() => import("./js/Pages/Settings/Auth")));
const SettingsCalendar = lazy(() => componentLoader(() => import("./js/Pages/Settings/SharedCalendar")));
const SettingsSearchTerms = lazy(() => componentLoader(() => import("./js/Pages/Settings/SearchTerms")));
const SettingsCompany = lazy(() => componentLoader(() => import("./js/Pages/Settings/Company/Company")));
const Reports = lazy(() => componentLoader(() => import("./js/Pages/Reports")));
const NotFoundPage = lazy(() => componentLoader(() => import("./js/Pages/NotFound")));
const CouncillorCantons = lazy(() => componentLoader(() => import("./js/Pages/Councillors/Cantons")));
const CouncillorFactions = lazy(() => componentLoader(() => import("./js/Pages/Councillors/Factions")));
const CouncillorParties = lazy(() => componentLoader(() => import("./js/Pages/Councillors/Parties")));
const CouncillorPartyDetails = lazy(() => componentLoader(() => import("./js/Pages/Councillors/PartyDetails")));
const CantonDetails = lazy(() => componentLoader(() => import("./js/Pages/Councillors/CantonDetails")));
const FactionDetails = lazy(() => componentLoader(() => import("./js/Pages/Councillors/FactionDetails")));
const NationalCouncilRankingsIndex = lazy(() => componentLoader(() => import("./js/Pages/Rankings/NationalCouncil/RankingsIndex")));
const NationalCouncilRankingsCoalitionForm = lazy(() => componentLoader(() => import("./js/Pages/Rankings/NationalCouncil/RankingsCoalitionForm")));
const NationalCouncilRankingsCouncillorForm = lazy(() => componentLoader(() => import("./js/Pages/Rankings/NationalCouncil/RankingsCouncillorForm")));
const RankingsFaction = lazy(() => componentLoader(() => import("./js/Pages/Rankings/NationalCouncil/RankingsFaction")));
const RankingsCouncillor = lazy(() => componentLoader(() => import("./js/Pages/Rankings/NationalCouncil/RankingsCouncillor")));
const CouncilStatesRankingsIndex = lazy(() => componentLoader(() => import("./js/Pages/Rankings/CouncilStates/RankingsIndex")));
const CouncilStatesRankingsCoalitionForm = lazy(() => componentLoader(() => import("./js/Pages/Rankings/CouncilStates/RankingsCoalitionForm")));
const CouncilStatesRankingsCouncillorForm = lazy(() => componentLoader(() => import("./js/Pages/Rankings/CouncilStates/RankingsCouncillorForm")));
const CouncilStatesRankingsFaction = lazy(() => componentLoader(() => import("./js/Pages/Rankings/CouncilStates/RankingsFaction")));
const CouncilStatesRankingsCouncillor = lazy(() => componentLoader(() => import("./js/Pages/Rankings/CouncilStates/RankingsCouncillor")));
const Login = lazy(() => componentLoader(() => import("./js/Pages/Login")));
const Login2fa = lazy(() => componentLoader(() => import("./js/Pages/Login2fa")));
const LoginSaml = lazy(() => componentLoader(() => import("./js/Pages/LoginSaml")));
const PasswordResetRequest = lazy(() => componentLoader(() => import("./js/Pages/PasswordResetRequest")));
const PasswordReset = lazy(() => componentLoader(() => import("./js/Pages/PasswordReset")));
const EmailCheck = lazy(() => componentLoader(() => import("./js/Pages/EmailCheck")));
const AccessRestricted = lazy(() => componentLoader(() => import("./js/Pages/AccessRestricted")));

const root = document.getElementById("root");

const cantons =
  root?.dataset.cantons && Object.values(JSON.parse(root.dataset.cantons));
const nationalCouncils =
  root?.dataset.nationalcouncils && JSON.parse(root.dataset.nationalcouncils);
// TODO remove cantons from withProps and use cantons from reducer on every page
const withProps = (Component, extraProps = {}) => (props) => (
  <Component
    cantons={cantons}
    nationalCouncils={nationalCouncils}
    {...props}
    {...extraProps} />
);

const Route = (props) => {
  return (
    <Suspense
      fallback={<div style={{ margin: `${SPACINGS.X2} auto auto` }} />}>
      <ReactRoute {...props} />
    </Suspense>
  );
};

const RoutePrivate = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    isLoggedIn().then(isLogged => {
      setLoggedIn(isLogged);
      setLoaded(true);
    });

  }, []);

  if (!loaded) {
    return <ReactRoute {...props} render={() => <Spinner />} />;
  }

  if (loggedIn) {
    return (
      <Suspense
        fallback={<div className='mt-3' style={{ margin: "auto" }}>
          <Spinner />
        </div>}>
        {
          props.rolesRequired && !(props.rolesRequired).every((r) => props.user.roles.includes(r))
            ? <AccessRestricted />
            : <ReactRoute {...props} />
        }

      </Suspense> 
    );
  }

  return <Redirect to='/login' />;
};

const AnalyticsListener = withRouter(() => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
});

type $Props = { user?: $User | null, env: "dev" | "prod" } & withTranslationProps

class AppContent extends PureComponent<$Props> {
  constructor(props) {
    super(props);
  }

  render() {
    const { user, trans } = this.props;

    return (
      <BrowserRouter>
        {!GLOBALS.UNOFFICIAL_COMPANY_IDS.includes(get(user, 'company.id')) && <AnalyticsListener />}
        <ErrorBoundary>
          <Page>
            <Switch>
              <Route
                exact={true}
                path={Routing.generate("user_security_login")}
                render={withProps(Login, {
                  pageTitle: trans("profile.login"),
                })} />
              <Route
                exact={true}
                path={Routing.generate("user_security_login_2fa")}
                render={withProps(Login2fa, {
                  pageTitle: trans("profile.login"),
                  user
                })} />
              <Route
                exact={true}
                path={Routing.generate("saml_discovery")}
                render={withProps(LoginSaml, {
                  pageTitle: trans("profile.login"),
                  user
                })} />
              <Route
                exact={true}
                path={Routing.generate('user_resetting_request')}
                render={withProps(PasswordResetRequest, {
                  user
                })} />
              <Route
                exact={true}
                path={Routing.generate('user_resetting_reset', {
                  token: ':token'
                })}
                render={withProps(PasswordReset, {
                  user
                })} />
              <Route
                exact={true}
                path={Routing.generate('user_resetting_check_email')}
                render={withProps(EmailCheck, {
                  user
                })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("user_dashboard")}
                render={withProps((Dashboard), {
                  pageTitle: trans("profile.dashboard"),
                  user,
                })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("show_calendar")}
                render={withProps(Calendar, {
                  pageTitle: trans("event.calendar.title"),
                  user,
                })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("affairs_overview")}
                render={withProps(Search, {
                  pageTitle: trans("affairs.overview.title"),
                  user,
                  hideCouncillors: true
                })} />

              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                exact={true}
                path={Routing.generate(
                  "national_affair_consultations_status",
                  {
                    consultationStatus: "planned",
                  }
                )}
                render={withProps(NationalConsultations, {
                  status: "planned",
                  pageTitle: trans("national.consultations.list"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                exact={true}
                path={Routing.generate(
                  "national_affair_consultations_status",
                  {
                    consultationStatus: "in_progress",
                  }
                )}
                render={withProps(NationalConsultations, {
                  status: "in_progress",
                  pageTitle: trans("national.consultations.list"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                exact={true}
                path={Routing.generate(
                  "national_affair_consultations_status",
                  {
                    consultationStatus: "closed",
                  }
                )}
                render={withProps(NationalConsultations, {
                  status: "closed",
                  pageTitle: trans("national.consultations.list"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                exact={true}
                path={Routing.generate(
                  "national_affair_consultations_status",
                  {
                    consultationStatus: "cancelled",
                  }
                )}
                render={withProps(NationalConsultations, {
                  status: "cancelled",
                  pageTitle: trans("national.consultations.list"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                path={decodeURIComponent(
                  Routing.generate(
                    "national_affair_consultation_detail",
                    {
                      consultationSlug: ":consultationSlug",
                      eventSlug: ":eventSlug",
                    }
                  )
                )}
                render={withProps(Consultation, {
                  pageTitle: trans(
                    "national.consultations.details.title"
                  ),
                  user,
                  section: "national"
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                path={decodeURIComponent(
                  Routing.generate(
                    "national_affair_consultation_detail",
                    {
                      consultationSlug: ":consultationSlug",
                      eventSlug: "",
                    }
                  )
                )}
                render={withProps(Consultation, {
                  pageTitle: trans(
                    "national.consultations.details.title"
                  ),
                  user,
                  section: "national"
                })} />
              <RoutePrivate
                path={decodeURIComponent(
                  Routing.generate("user_affairgroup_overview", {
                    affairGroupId: ":id",
                  })
                )}
                render={withProps(GroupOverview, { user })} />
              <RoutePrivate
                exact={true}
                path={decodeURIComponent(
                  Routing.generate("user_custom_meeting_overview", {
                    customMeetingId: ":id",
                  })
                )}
                render={withProps(Meeting, { user })} />
              <RoutePrivate
                exact={true}
                path={decodeURIComponent(
                  Routing.generate("custom_meetings_list")
                )}
                render={withProps(Meetings, {
                  pageTitle: trans("profile.customMeetings"),
                  user,
                })} />
              <RoutePrivate
                exact={true}
                path={decodeURIComponent(
                  Routing.generate("custom_meetings_list_archived")
                )}
                render={withProps(MeetingsArchived, {
                  pageTitle: trans("profile.customMeetings.archived"),
                  user,
                })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("user_affairgroup_list")}
                render={withProps(Groups, {
                  pageTitle: trans("profile.affairGroups"),
                  user,
                })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("user_archived_affairgroup_list")}
                render={withProps(GroupsArchived, {
                  pageTitle: trans("profile.affairGroups.archived"),
                  user,
                })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("custom_affairs_index")}
                render={withProps(CustomAffairs, {
                  pageTitle: trans("custom.affairs.list.pagetitle"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                path={decodeURIComponent(
                  Routing.generate("show_sessions_votes", {
                    council_slug: ":councilSlug",
                    slug: ":sessionSlug",
                  })
                )}
                render={withProps(NationalSessionVotes, { user })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                path={decodeURIComponent(
                  Routing.generate("show_sessions_votes", {
                    council_slug: ":councilSlug",
                    slug: "",
                  })
                )}
                render={withProps(NationalSessionVotes, { user })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                path={decodeURIComponent(
                  Routing.generate("show_sessions_programs", {
                    council_slug: ":councilSlug",
                    slug: ":sessionSlug",
                  })
                )}
                render={withProps(NationalSessionPrograms, { user })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                path={decodeURIComponent(
                  Routing.generate("show_sessions_programs", {
                    council_slug: ":councilSlug",
                    slug: "",
                  })
                )}
                render={withProps(NationalSessionPrograms, { user })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                path={decodeURIComponent(
                  Routing.generate("show_sessions_programs", {
                    council_slug: "",
                  })
                )}
                render={withProps(NationalSessionPrograms, { user })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                exact={true}
                path={decodeURIComponent(
                  Routing.generate("show_sessions_votes", {
                    council_slug: "",
                  })
                )}
                render={withProps(NationalSessionVotes, {
                  pageTitle: trans("sessions.nocouncil.title"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                path={decodeURIComponent(
                  Routing.generate("show_committee_press_releases", {
                    id: ":id",
                  })
                )}
                render={withProps(NationalCommitteePressRelease, {
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                path={decodeURIComponent(
                  Routing.generate("show_committees", {
                    councilSlug: ":councilSlug",
                    committeeSlug: ":committeeSlug",
                  })
                )}
                render={withProps(NationalCommittee, {
                  pageTitle: trans("national.committees.show"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                exact={true}
                path={decodeURIComponent(
                  Routing.generate("show_committees", {
                    councilSlug: ":councilSlug",
                  })
                )}
                render={withProps(NationalCommittees, {
                  pageTitle: trans("national.committees.list"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                path={decodeURIComponent(
                  Routing.generate("show_councillors", {
                    slug: ":slug",
                  })
                )}
                render={withProps(NationalCouncillor, {
                  pageTitle: trans(
                    "national.pages.councillor.detail.title"
                  ),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_list_canton_affairs", {
                    slug: ":cantonSlug",
                  })
                )}
                render={withProps(CantonalAffairs, {
                  pageTitle: trans("cantonal.affairs"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_show_councillor", {
                    cantonSlug: ":cantonSlug",
                    councillorSlug: ":councillorSlug",
                  })
                )}
                render={withProps(CantonalCouncillor, { user })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_list_canton_councillors", {
                    slug: ":cantonSlug",
                  })
                )}
                render={withProps(CantonalCouncillors, {
                  pageTitle: trans("cantonal.listCouncillors.title"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_show_committee", {
                    cantonSlug: ":cantonSlug",
                    committeeSlug: ":committeeSlug",
                  })
                )}
                render={withProps(CantonalCommittee, { user })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_list_canton_committees", {
                    slug: ":cantonSlug",
                  })
                )}
                render={withProps(CantonalCommittees, {
                  pageTitle: trans("cantonal.listCommissions.title"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_show_miscauthor", {
                    cantonSlug: ":cantonSlug",
                    authorSlug: ":authorSlug",
                  })
                )}
                render={withProps(CantonalMiscAuthor, { user })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_list_canton_miscauthors", {
                    slug: ":cantonSlug",
                  })
                )}
                render={withProps(CantonalMiscAuthors, {
                  pageTitle: trans("cantonal.listMiscAuthors.title"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_show_faction", {
                    cantonSlug: ":cantonSlug",
                    factionSlug: ":factionSlug",
                  })
                )}
                render={withProps(CantonalFaction, { user })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_list_canton_factions", {
                    slug: ":cantonSlug",
                  })
                )}
                render={withProps(CantonalFactions, {
                  pageTitle: trans("cantonal.listFactions.title"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_show_party", {
                    cantonSlug: ":cantonSlug",
                    partySlug: ":partySlug",
                  })
                )}
                render={withProps(CantonalParty, { user })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_list_canton_parties", {
                    slug: ":cantonSlug",
                  })
                )}
                render={withProps(CantonalParties, {
                  pageTitle: trans("cantonal.listParties.title"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_show_session", {
                    sessionSlug: ":sessionSlug",
                    cantonSlug: ":cantonSlug",
                  })
                )}
                render={withProps(CantonalSession, {
                  pageTitle: trans("cantonal.sessions.title"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_list_canton_sessions", {
                    slug: ":cantonSlug",
                  })
                )}
                render={withProps(CantonalSessions, {
                  pageTitle: trans("cantonal.listSessions.title"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                exact={true}
                path={decodeURIComponent(
                  Routing.generate(
                    "cantonal_affair_consultations_status",
                    {
                      cantonSlug: ":cantonSlug",
                      consultationStatus: ":status",
                    }
                  )
                )}
                render={withProps(CantonalConsultations, { user })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate(
                    "cantonal_affair_consultation_detail",
                    {
                      consultationSlug: ":consultationSlug",
                      eventSlug: ":eventSlug",
                      cantonSlug: ":cantonSlug",
                    }
                  )
                )}
                render={withProps(Consultation, {
                  user,
                  section: "cantonal",
                  pageTitle: trans(
                    "cantonal.consultations.details.title"
                  ),
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate(
                    "cantonal_affair_consultation_detail",
                    {
                      consultationSlug: ":consultationSlug",
                      eventSlug: "",
                      cantonSlug: ":cantonSlug",
                    }
                  )
                )}
                render={withProps(Consultation, {
                  pageTitle: trans(
                    "cantonal.consultations.details.title"
                  ),
                  user,
                  section: "cantonal"
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_list_canton_media_releases", {
                    slug: ":cantonSlug",
                  })
                )}
                render={withProps(CantonalMediaReleases, {
                  pageTitle: trans("cantonal.mediaReleases.title"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("show_cantonal_media_release", {
                    id: ":id",
                    slug: ":cantonSlug"
                  })
                )}
                render={withProps(CantonalMediaRelease, {
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                exact={true}
                path={decodeURIComponent(
                  Routing.generate("cantonal_show_canton", {
                    slug: ":cantonSlug",
                  })
                )}
                render={withProps(CantonalCanton, { user })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("cantonal_list_cantons")}
                render={withProps(CantonalOverview, { user })} />

              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                path={decodeURIComponent(
                  Routing.generate("show_affair", {
                    affairSlug: ":id",
                    eventSlug: ":eventSlug",
                  })
                )}
                render={withProps(Affair, {
                  section: "national",
                  pageTitle: trans("national.affair.overview.title"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_NATIONAL]}
                path={decodeURIComponent(
                  Routing.generate("show_affair", {
                    affairSlug: ":id",
                    eventSlug: "",
                  })
                )}
                render={withProps(Affair, {
                  section: "national",
                  pageTitle: trans("national.affair.overview.title"),
                  user,
                })} />
              <RoutePrivate
                path={decodeURIComponent(
                  Routing.generate("custom_affair_show", {
                    id: ":id",
                    eventSlug: ":eventSlug",
                  })
                )}
                render={withProps(Affair, {
                  section: "custom",
                  pageTitle: trans("custom.affairs.overview.title"),
                  user,
                })} />
              <RoutePrivate
                path={decodeURIComponent(
                  Routing.generate("custom_affair_show", {
                    id: ":id",
                    eventSlug: "",
                  })
                )}
                render={withProps(Affair, {
                  section: "custom",
                  pageTitle: trans("custom.affairs.overview.title"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_show_affair", {
                    cantonSlug: ":cantonSlug",
                    affair: ":id",
                    eventSlug: ":eventSlug",
                  })
                )}
                render={withProps(Affair, {
                  section: "cantonal",
                  pageTitle: trans("cantonal.affair.overview.title"),
                  user,
                })} />
              <RoutePrivate
                user={user}
                rolesRequired={[ROLES.ROLE_AREA_CANTONAL]}
                path={decodeURIComponent(
                  Routing.generate("cantonal_show_affair", {
                    cantonSlug: ":cantonSlug",
                    affair: ":id",
                    eventSlug: "",
                  })
                )}
                render={withProps(Affair, {
                  section: "cantonal",
                  pageTitle: trans("cantonal.affair.overview.title"),
                  user,
                })} />
              <RoutePrivate
                path={Routing.generate("user_notifications")}
                render={withProps(Notifications, {
                  pageTitle: trans("profile.notifications"),
                  user,
                })} />
              <RoutePrivate
                path={Routing.generate("search")}
                render={withProps(Search, {
                  pageTitle: trans("affairs.search.title"),
                  user,
                })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("show_cantons")}
                render={withProps(CouncillorCantons, { user })} />
              <RoutePrivate
                exact={true}
                path={decodeURIComponent(Routing.generate("show_cantons",
                  { slug: ":cantonSlug" }))}
                render={withProps(CantonDetails, { user })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("show_factions")}
                render={withProps(CouncillorFactions, { user })} />
              <RoutePrivate
                exact={true}
                path={decodeURIComponent(Routing.generate("show_factions",
                  { slug: ":factionSlug" }))}
                render={withProps(FactionDetails, { user })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("show_parties")}
                render={withProps(CouncillorParties, { user })} />
              <RoutePrivate
                exact={true}
                path={decodeURIComponent(Routing.generate("show_parties",
                  { slug: ":partySlug" }))}
                render={withProps(CouncillorPartyDetails, { user })} />

              <RoutePrivate
                exact={true}
                path={Routing.generate("national_council_rankings_index")}
                render={withProps(NationalCouncilRankingsIndex, { user, pageTitle: trans("rankings.title.nr") })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("national_council_factions_rankings")}
                render={withProps(NationalCouncilRankingsCoalitionForm, { user, pageTitle: trans("rankings.title.nr") })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("national_council_councillors_rankings")}
                render={withProps(NationalCouncilRankingsCouncillorForm, { user, pageTitle: trans("rankings.title.nr") })} />
              <RoutePrivate
                path={Routing.generate("national_council_factions_rankings")}
                render={withProps(RankingsFaction, { user, pageTitle: trans("layout.menu.nationalcouncil.rankings.factions") })} />
              <RoutePrivate
                path={decodeURIComponent(Routing.generate("national_council_rankings_show_councillors", { type: ":type" }))}
                render={withProps(RankingsCouncillor, { user, pageTitle: trans("layout.menu.nationalcouncil.rankings.councillors") })} />




              <RoutePrivate
                exact={true}
                path={Routing.generate("council_states_rankings_index")}
                render={withProps(CouncilStatesRankingsIndex, { user, pageTitle: trans("rankings.title.sr") })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("council_states_factions_rankings")}
                render={withProps(CouncilStatesRankingsCoalitionForm, { user, pageTitle: trans("rankings.title.sr") })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("council_states_councillors_rankings")}
                render={withProps(CouncilStatesRankingsCouncillorForm, { user, pageTitle: trans("rankings.title.sr") })} />
              <RoutePrivate
                path={Routing.generate("council_states_factions_rankings")}
                render={withProps(CouncilStatesRankingsFaction, { user, pageTitle: trans("layout.menu.councilstates.rankings.factions") })} />
              <RoutePrivate
                path={decodeURIComponent(Routing.generate("council_states_rankings_show_councillors", { type: ":type" }))}
                render={withProps(CouncilStatesRankingsCouncillor, { user, pageTitle: trans("layout.menu.councilstates.rankings.councillors") })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("user_profile_show")}
                render={withProps(SettingsProfile, { user })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("user_change_password")}
                render={withProps(SettingsPasswordEdit, { user })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("user_settings_auth")}
                render={withProps(SettingsAuth, { user })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("user_settings_calendar")}
                render={withProps(SettingsCalendar, { user })} />
              <RoutePrivate
                exact={true}
                path={Routing.generate("reports")}
                render={withProps(Reports, { user })} />
              <RoutePrivate
                user={user}
                exact={true}
                rolesRequired={[ROLES.ROLE_FULL_READWRITE]}
                path={Routing.generate("user_settings")}
                render={withProps(SettingsSearchTerms, { user })} />
              <RoutePrivate
                user={user}
                exact={true}
                rolesRequired={[ROLES.ROLE_COMPANYSETTINGS_W]}
                path={Routing.generate("user_company_settings")}
                render={withProps(SettingsCompany, { form: "user_company_settings" })} />
              <RoutePrivate
                user={user}
                exact={true}
                rolesRequired={[ROLES.ROLE_COMPANYSETTINGS_W]}
                path={Routing.generate("user_company_custom_fields")}
                render={withProps(SettingsCompany, { form: "user_company_custom_fields" })} />
              <RoutePrivate
                user={user}
                exact={true}
                rolesRequired={[ROLES.ROLE_COMPANYSETTINGS_W]}
                path={Routing.generate("user_company_user_groups")}
                render={withProps(SettingsCompany, { form: "user_company_user_groups" })} />
              <Route
                exact={true}
                path={Routing.generate("user_security_logout")}
                render={() => {
                  location.reload();
                }} />
              <RoutePrivate
                exact={true}
                path='/'>
                <Redirect to='/dashboard' />
              </RoutePrivate>
              <Route
                path='*'
                render={() => <NotFoundPage />} />
            </Switch>
          </Page>
        </ErrorBoundary>
      </BrowserRouter>
    );
  }
}

function mapStateToProps(state) {
  return { user: state.user.data };
}

export const Content = connect<{ user: $User | null }>(mapStateToProps)(withTranslation(AppContent));
