import React, { useState, useEffect, Fragment, Component } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import {NotificationsDropdown} from "./Notifications/NotificationsDropdown";
import {
  showDangerAlert,
  showSuccessAlert,
  Spinner,
  Modal,
  SimpleMenu,
  IconButton,
} from "./Shared";
import { Form , TextInput, SimpleSelect } from "./Shared/ui";
import { Button } from "./Shared/BaseUI";
import { trans } from "@trans";
import { getUserAccessObject } from '@helpers';
import { Routing } from '@backendRouter';
import { COLORS } from "@constants";
import { getCompanies, setUserCompany } from "@api/companies";
import { SEARCH_TYPES } from '@constants';
import { getFiltersFromUrl } from "./Search/script";

const CompanySelectForm = ({ user, close }) => {
  const [state, setState] = useState({
    companies: [],
    loading: false,
    company: user.company["@id"],
  });

  useEffect(() => {
    setState({ ...state, loading: true });
    getCompanies(user.id)
      .then(({ data }) =>
        setState({
          ...state,
          companies: data["hydra:member"].map((c) => ({
            label: c.name,
            value: c["@id"],
          })),
          loading: false,
        })
      )
      .catch(() => showDangerAlert(trans("api.user.companies.get.error")));
  }, []);

  const submit = () => {
    setUserCompany(user.id, state.company)
      .then(() => {
        showSuccessAlert(trans("api.user.companies.put.success"));
        location.reload();
        close();
      })
      .catch(() => showDangerAlert(trans("api.user.companies.put.error")));
  };

  return state.loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <SimpleSelect
        value={state.company}
        label={trans("user.company.select_input.label")}
        onChange={(e) => setState({ ...state, company: e.target.value })}
        options={state.companies} />
      <div className='d-flex justify-content-end'>
        <Button type='button' onClick={submit}>
          {trans("ui.submit")}
        </Button>
      </div>
    </Fragment>
  );
};

type $Props = {
  user: $User;
  legacy?: boolean;
  pageTitle?: string;
} & RouteComponentProps
type $State = {
  type: "AllAffairs" | "NationalAffair" | "CantonalAffair";
  query: string;
  modalOpened: boolean;
  cantonAbbreviation?: string;
  affairType?: string;
  isChanged: boolean;
}

class NavBar extends Component<$Props, $State> {
  constructor(props: $Props) {
    super(props);
    this.access = getUserAccessObject(props.user);

    this.isExcluded =
      location.pathname.indexOf(Routing.generate("user_affairgroup_list")) ===
      0;

    const type =
      (!this.access.readOnly || this.access.national) ? "AllAffairs" : "CantonalAffair";

    this.state = {
      type,
      query: getFiltersFromUrl(this.props.location, props.user).query || "",
      modalOpened: false,
      isChanged: false
    };
  }

  private access: $Access;
  private isExcluded: boolean;

  onSubmit = () => {
    const url = Routing.generate("search", {
      type: this.state.type,
      query: this.state.query,
      'order[none]': 'DESC'
    });

    if (this.props.legacy) {
      location.assign(url);
    }
    else {
      this.props.history.push(url);
    }

    this.setState({isChanged: false});
  };

  toggleModal = () =>
    this.setState((state) => ({ modalOpened: !state.modalOpened }));

  render() {
    const { user, legacy } = this.props;
    const { modalOpened, type, cantonAbbreviation, query, isChanged } = this.state;


    if (!user) {
      return null;
    }

    const menuOptions = [
      {
        text: trans("menu.company.choose"),
        func: this.toggleModal,
        show: user.moreCompanies,
      },
    ].filter((m) => m.show !== false);

    return (
      <>
        {modalOpened && (
          <Modal close={this.toggleModal}>
            <Form title={trans("user.company.set.form.title")}>
              <CompanySelectForm
                user={user}
                close={this.toggleModal} />
            </Form>
          </Modal>
        )}
        <nav
          className='navbar navbar-expand-lg navbar-transparent'
          style={{ background: "white" }}>
          <div className='container-fluid pl-0 pr-0'>
            <button
              className='navbar-toggler'
              type='button'
              data-toggle='collapse'
              aria-controls='navigation-index'
              aria-expanded='false'
              aria-label='Toggle navigation'
              data-target='#navigation-example'>
              <span className='sr-only'>Toggle navigation</span>
              <span className='navbar-toggler-icon icon-bar' />
              <span className='navbar-toggler-icon icon-bar' />
              <span className='navbar-toggler-icon icon-bar' />
            </button>
            <div
              className='collapse navbar-collapse justify-content-end'
              style={{ fontSize: "x-large" }}>
              {(this.access.cantonal || this.access.national) && (
                <React.Fragment>
                  {this.access.national && this.access.cantonal && (
                    <SimpleSelect
                      className='mr-1'
                      value={type}
                      name='type'
                      id='type'
                      onChange={(e) => {
                        this.setState({
                          type: e.target.value,
                          cantonAbbreviation:
                            e.target.value === 1
                              ? "none"
                              : cantonAbbreviation,
                          affairType: "none",
                        });
                      }}
                      options={[
                        {
                          value: SEARCH_TYPES.ALL_AFFAIRS,
                          label: trans(
                            "layout.search.type.allAffairs"
                          ),
                        },
                        {
                          value: SEARCH_TYPES.NATIONAL_AFFAIR,
                          label: trans(
                            "layout.search.type.national"
                          ),
                        },
                        {
                          value: SEARCH_TYPES.CANTONAL_AFFAIR,
                          label: trans("layout.search.type.cantonal"),
                        },
                      ]} />
                  )}
                  <TextInput
                    label={trans("layout.search.query")}
                    name='query'
                    value={isChanged ? query : getFiltersFromUrl(this.props.location, user).query}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        this.onSubmit();
                      }
                    }}
                    endicon='affair_list.search'
                    onSubmit={this.onSubmit}
                    onChange={(e) => this.setState({ isChanged: true, query: e.target.value })} />
                </React.Fragment>
              )}
              <ul className='navbar-nav'>
                <NotificationsDropdown legacy={legacy} />
                {menuOptions.length > 0 && (
                  <SimpleMenu
                    ButtonOptional={(props) => (
                      <IconButton
                        {...props}
                        icon='ui.menu'
                        color={COLORS.GRAY2} />
                    )}
                    options={menuOptions} />
                )}
              </ul>
            </div>
          </div>
        </nav>
        <style>{`
            .navbar .navbar-minimize button#minimizeSidebar, .navbar .navbar-minimize button#minimizeSidebar:hover {
              background: transparent;
              color: black;
            }
            .navbar .navbar-minimize {
              position: initial;
            }
            .navbar .notification {
              background: ${COLORS.NOTIFICATION};
            }
            .navbar.navbar-transparent {
              background-color: #fff !important;
            }
            .navbar {
              min-height: 52px;
            }
            nav.navbar {
              padding: 0px!important;
            }
        `}</style>
      </>
    );
  }
}

export const Navbar =  withRouter(NavBar);
