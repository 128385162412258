import React, { MouseEventHandler } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import { SPACINGS, COLORS, GREYTONES } from '@constants';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

type $ToggleProps = {
  checked: boolean;
  title: string;
  onClick: MouseEventHandler;
  style?: CSSProperties
  side?: 'left' | 'right';
  disabled?: boolean;
}

const spacingToInt = (value) => {
  return parseInt(value, 10);
};

const CustomToggle = withStyles((theme) =>
  createStyles({
    root: {
      width: spacingToInt(SPACINGS.X4),
      height: spacingToInt(SPACINGS.X2),
      padding: 0,
      margin: theme.spacing(1),
      // I couldn't find any other way to force the track to
      // have the disabled color, MUI's way of styling that
      // they show in their documentation simply doesn't work...
      '& .Mui-disabled + .MuiSwitch-track': {
        background: `${GREYTONES.GREY_MEDIUM}!important`
      }
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: COLORS.PRIMARY,
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: spacingToInt(SPACINGS.X2) - 2,
      height: spacingToInt(SPACINGS.X2) - 2,
    },
    track: {
      borderRadius: spacingToInt(SPACINGS.X2) / 2,
      backgroundColor: COLORS.DARK_GREY,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border', 'opacity']),
    },
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      fontWeight: 400,
      fontSize: 16,
    },
    checked: {},
    focusVisible: {},
  }),
)(({ classes, ...props }: Props) => (
  <Switch
    focusVisibleClassName={classes.focusVisible}
    disableRipple={true}
    classes={{
      root: classes.root,
      switchBase: classes.switchBase,
      thumb: classes.thumb,
      track: classes.track,
      checked: classes.checked,
    }}
    {...props} />
));

const divStyle = {
  display: 'flex',
  alignItems: 'center',
  fontWeight: 400,
  fontSize: 16,
};

export const Toggle = ({
  checked,
  title,
  onClick,
  style,
  side = 'left',
  disabled = false
}: $ToggleProps) => (
  <span>
    <div style={{
      ...style,
      ...divStyle
    }}>
      {side === 'left' && title}
      <CustomToggle checked={checked} {...(!disabled && { onClick })} disabled={disabled} />
      {side !== 'left' && title}
    </div>
  </span>
);
