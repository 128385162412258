export default {
  DEFAULT_PAGE_SIZE: 30,
  PAGE_SIZES: [15, 30, 90],
  DEFAULT_USER_IMG_URL:
    "https://storage.googleapis.com/politikch-prod/images/nationalCouncillors/1004.jpg",
  SWISS_FLAG_URL: "/images/Flag_of_Switzerland.svg.png",
  COUCILLOR_DEFAULT_IMG: "/images/null.jpg",
  DUMB_SEARCH: "/images/dumb_search.svg",
  GOV_AFFAIR_TYPE_ID: 34,
  UNOFFICIAL_COMPANY_IDS: [40, 34, 33, 26, 22, 8, 1],
  MAX_FILE_UPLOAD_SIZE: 5 * 1024 * 1024,
  SWISS_BANKING_LOGO_URL: "/images/Logo_poweredBy_SwissBanking.png",
  SESSION_LENGTH: 1000 * 60 * 30,
  ERROR_401: "Request failed with status code 401",
  ERROR_404: "Request failed with status code 404",
  SWISS_NATIONAL : "/images/Swiss-national.png",
  SWISS_INTERNAL : "/images/Swiss-internal.png",
  LINKED_ICON : "/images/linked-icon.png",
  DEFAULT_COMPANY_LOGO_URL: "/images/politik.ch-schweiz-logo.png"
};
