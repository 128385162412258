import React, { useRef, useEffect } from "react";
import { makeElementLinksAbsolute} from '@helpers';

export const RenderHtmlWithAbsoluteLinks= ({ ...props }) => {
  const ref = useRef(null);

  useEffect(() => {
    const el = ref.current;

    makeElementLinksAbsolute(el);

  }, [ref]);

  return <div {...props} ref={ref} />;
};
