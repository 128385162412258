import React, { Component } from "react";
import { ErrorBoundary as EB } from "react-error-boundary";
import { trans } from "@trans";
import { Error, Button, Card, WarningIcon, A } from "./BaseUI";
import { Routing } from '@backendRouter';
import Sentry from "../../script/sentry";

function ErrorFallback({ resetErrorBoundary }) {

  return (
    <Card role='alert' className='m-3'>
      <Error style={{ fontSize: 20 }}>
        <WarningIcon />
        {trans("ui.error.crash.title")}
      </Error>
      <span className='mt-2 d-block'>{trans("ui.error.crash.message")}</span>

      <div className='d-flex justify-content-end'>
        <A to={Routing.generate("user_dashboard")}>
          <Button className='mr-1' onClick={resetErrorBoundary}>
            {trans("ui.error.crash.goto_dashboard")}
          </Button>
        </A>
        <Button onClick={resetErrorBoundary}>
          {trans("ui.error.crash.reload")}
        </Button>
      </div>
    </Card>
  );
}

export class ErrorBoundary extends Component {
  render() {
    return (
      <EB
        FallbackComponent={ErrorFallback}
        onError={(error) => {
          Sentry.captureException(error);
        }}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}>
        {React.Children.toArray(this.props.children)}
      </EB>
    );
  }
}
