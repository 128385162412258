import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Icon from '@components/Shared/InventoryIcon';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Spinner } from './Spinner';
import { COLORS, GREYTONES, PRIMARY_COLORS, SECONDARY_COLORS, SPACINGS } from '@constants';
import { Routing } from '@backendRouter';
import { trans } from "@trans";
import { detectIE } from '@helpers';
import { InputContainer } from "./ui/TextInput";

export const Content = styled.div`
  padding: ${SPACINGS.X3};
`;

export const SectionTitle = styled.span`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-top: ${SPACINGS.X3};
  margin-bottom: ${SPACINGS.X1};
  color: ${COLORS.GRAY1};
  display: block;
`;

export const LineSeparator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${COLORS.BACKGROUND};
`;

export const InteractiveIcon = styled.span`
  width: max-content;
  height: max-content;
  color: ${(props) => (props.activated ? COLORS.SUCCESS : COLORS.PRIMARY)};
  cursor: pointer;
  text-align: right;
  &:hover {
    color: ${(props) =>
    props.editable
      ? COLORS.SUCCESS_DARKER
      : props.activated
        ? COLORS.DANGER
        : SECONDARY_COLORS.TURQ3};
  }
`;

export const IBSpan = styled.span`
  white-space: nowrap;
  font-size: x-small;
  padding: 3px 11px;
  margin: 2px;
  vertical-align: middle;
  line-height: 111%;
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth}` : 'max-width: 200px;')}
  overflow: hidden;
  text-overflow: ellipsis;
  ${(props) =>
    props.interactive
      ? `
    background-color: #2c6777;
    cursor: pointer;
    &:hover {
      background-color: #123456;
    }
  `
      : ''}

  ${(props) =>
    props.large
      ? `font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  padding: 6px 15px;
  border-radius: 4px;
  margin-right: 10px;`
      : ''}
`;

export const Section = ({ children, title, isLast = false }) => (
  <>
    <section style={{ paddingBottom: SPACINGS.X5 }}>
      <SectionTitle>{title}</SectionTitle>
      {children}
    </section>
    {!isLast && <LineSeparator />}
  </>
);

export const CustomTooltip = withStyles({
  tooltip: {
    color: 'white',
    backgroundColor: COLORS.DARK_GREY,
    borderRadius: 0,
    borderColor: COLORS.MEDIUM_GREY,
    borderWidth: '2px',
    borderStyle: 'inset',
    padding: '2px 4px',
    margin: '0px -10px',
  },
})(Tooltip);

export const InlineBadge = ({
  text,
  icon,
  interactive = false,
  title = '',
  background,
  className = '',
  large = false,
  maxWidth,
  children,
  style = {
    color: 'white',
    width: '100%',
    maxWidth: '100px',
  },
}: {
  text?: string;
  icon?: string;
  interactive?: boolean;
  title?: string;
  background?: string;
  className?: string;
  large?: boolean;
  maxWidth?: string;
  children?;
  style?: {};
}) => (
  <IBSpan
    maxWidth={maxWidth}
    interactive={interactive}
    title={title || text}
    data-test={React.Children.toArray(children)}
    large={large}
    className={`${className} badge text-nowrap`}
    style={background ? { background, ...style } : { background: COLORS.PRIMARY, ...style }}>
    {text}
    {icon && <Icon icon={icon} />}
    {React.Children.toArray(children)}
  </IBSpan>
);

export const ButtonLight = styled.button`
  color: ${COLORS.INTERACTIVE};
  background: unset;
  transition: background-color 0.2s linear;
  border: unset;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    color: ${COLORS.ACTIVE};
    background: #e2e2e2;
  }
`;

export const StyledButton = styled.button`
  color: white;
  background: ${(props) =>
    props.toggle ? (props.selected ? COLORS.PRIMARY_DARK : COLORS.PRIMARY) : COLORS.PRIMARY};
  transition: background-color 0.2s linear, color 0.2s linear, box-shadow 0.2s linear;
  border: unset;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  padding: 6px 40px;
  line-height: 2em;
  &:hover {
    ${(props) =>
    props.disabled
      ? `
      cursor: not-allowed;
    `
      : `
      background: ${SECONDARY_COLORS.DARK_GREEN};
      color: white;
    `}
  }
  &:disabled {
    background: ${COLORS.MEDIUM_GREY};
  }
  ${(props) => (props.small ? 'font-size:13px;padding: 7px 20px;' : '')}
`;

export const Button = (props) =>
  props.loading ? (
    <StyledButton
      {...props}
      style={{ position: 'relative', ...props.style }}
      loading={String(props.loading)}>
      <Spinner
        color='white'
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          height: '100%',
        }}/>
      <span style={{ visibility: 'hidden' }}>{React.Children.toArray(props.children)}</span>
    </StyledButton>
  ) : (
    <StyledButton {...props} loading={String(props.loading)} />
  );

export const DangerButton = styled(Button)`
  ${(props) =>
    !props.disabled &&
    `
  background: ${COLORS.DANGER};
  &:hover{
    background: ${GREYTONES.GREY_DARK}
  }
  `};
`;

export const ButtonBordered = styled(Button)`
  background: white;
  color: ${COLORS.PRIMARY};
  box-shadow: inset 0 0 0 2px ${COLORS.PRIMARY};
  transition: background-color 0.2s linear, color 0.2s linear, box-shadow 0.2s linear;
  &:hover {
    ${(props) =>
    props.disabled
      ? `
      cursor: not-allowed;
    `
      : `
      color: ${SECONDARY_COLORS.DARK_GREEN};
      background: white;
    -webkit-box-shadow: inset 0 0 0 2px ${SECONDARY_COLORS.DARK_GREEN};
    -moz-box-shadow: inset 0 0 0 2px ${SECONDARY_COLORS.DARK_GREEN};
    box-shadow: inset 0 0 0 2px ${SECONDARY_COLORS.DARK_GREEN};
    `}
  }
  &:disabled {
    background: white;
    color: ${COLORS.MEDIUM_GREY};
    box-shadow: inset 0 0 0 2px ${COLORS.MEDIUM_GREY};
  }
`;

export const ButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const LoadingCover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.5;
  z-index: 99;
  border-radius: 8px;
`;

export const Logo = styled.img`
  max-width: 200px;
  max-height: 200px;
  display: inline-block;
  background-color: #eee;
  background-image: linear-gradient(45deg, #ccc 25%, transparent 0, transparent 75%, #ccc 0, #ccc),
    linear-gradient(45deg, #ccc 25%, transparent 0, transparent 75%, #ccc 0, #ccc);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
`;

export const ManagedBadge = (props) => (
  <InlineBadge
    background={COLORS.PRIVATE}
    title={trans('ui.affair.managed')}
    icon='groups.main'
    {...props}/>
);

export const PrivateBadge = (props) => (
  <InlineBadge
    background={COLORS.PRIVATE}
    title={trans('ui.private')}
    icon='ui.private'
    {...props}/>
);

export const IconButton = styled.i`
  flex: 0 0 auto;
  padding: 12px;
  overflow: visible;
  cursor: pointer;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

export const Selector = styled.div`
  position: relative;
  font-size: 16px;
  font-weight: 900;
  display: inline-block;
  padding-bottom: 10px;
  cursor: pointer;
  color: ${(props) => (props.selected ? 'black' : COLORS.GRAY3)}
  transition: color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover {
    color: black;
    &:before {
      border-bottom: 2px solid black;
    }
  }

  &: after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(${(props) => (props.selected ? 1 : 0)});
    transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #4dbdc6;
    pointer-events: none;
  }

  &:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid ${COLORS.GRAY3};
    pointer-events: none;
  }
`;

export const Card = styled.div`
  background: #ffffff;
  border-radius: 6px;
  padding: ${(props) =>
    props.mini
      ? `${SPACINGS.X3}`
      : `${SPACINGS.X3} ${SPACINGS.X3} ${SPACINGS.X6}`};
  position: relative;
  color: ${COLORS.FONTDARK};
  ${(props) =>
    props.loading && `
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: ${COLORS.BACKGROUND_LOADING};
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 24%, #eeeeee 44%);
    background-size: cover;
  `}
`;

export const CardActions = styled.div`
  position: absolute;
  top: ${SPACINGS.X1};
  right: ${SPACINGS.X1};
`;

export const ReportsCardActions = styled(CardActions)`
  right: 1rem;
`;

export const Panel = styled.div`
  border-radius: 6px;
  border: 1px solid ${COLORS.GRAY4};
  padding: 1rem;
  margin-top: ${SPACINGS.X3};
  position: relative;
`;

export const Label = styled.span`
  font-size: 13px;
  color: ${COLORS.FONTDARK};
`;

export const InputLabel = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin-top: ${SPACINGS.X2};
  display: inline-block;
  color: ${COLORS.FONTDARK};
`;

export const EmbededLabel = styled.label`
  color: rgb(60, 72, 88);
  font-size: 12px;
  font-style: normal;
  position: absolute;
  width: max-content;
  top: ${(props) => (props.lower ? '-8px' : '-14px')}
  left: 9px;
  background: white;
  z-index: 0;
  padding-left: 6px;
  padding-right: 12px;
`;

export const Header = styled.span`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: ${COLORS.FONTDARK};
  display: block;
`;

export const SectionHeader = styled(Header)`
  margin-bottom: 40px;
`;

export const SmallHeader = styled.span`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  margin-top: 10px;
  color: ${COLORS.GRAY1};
  display: block;
`;

const LinkDisabled = styled.span`
  color: ${COLORS.DISABLED};
`;

export const A = (props) =>
  props.to ? (
    ![
      Routing.generate('event_reminders', {
        eventReminderId: '',
      }),
      Routing.generate('admindashboard'),
    ].find((r) => props.to.indexOf(r) === 0) ? (
        <Link {...props}>{props.children && React.Children.toArray(props.children)}</Link>
      ) : (
        <a {...props} href={props.to}>
          {' '}
          {props.children && React.Children.toArray(props.children)}
        </a>
      )
  ) : (
    <LinkDisabled {...props}>
      {props.children && React.Children.toArray(props.children)}
    </LinkDisabled>
  );

export const TitleHeader = styled.h1`
  white-space: break-spaces;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: 5px;
`;

export const HugeTitle = styled.h1`
  font-size: 28px;
  font-style: normal;
  color: black;
  font-weight: bold;
  text-align: center;
`;

export const HugeSubTitle = styled.h1`
  font-size: 22px;
  font-style: normal;
  color: black;
  text-align: center;
`;

export const PageTitle = styled.div`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 22px;
  margin-bottom: ${SPACINGS.X5};
`;

export const Error = styled.span`
  display: block;
  font-size: 12px;
  color: ${COLORS.DANGER};
`;

export const LinkButton = styled.button`
  background: initial;
  border: none;
  font-size: 12px;
  text-decoration: underline;
  color: ${COLORS.PRIMARY};
  &:hover {
    color: ${COLORS.PRIMARY_LIGHT};
  }
`;

export const Alert = styled.div`
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(237, 53, 23, 0.4);
  background-color: #ee4125;
  color: #fff;
  border: 0;
  border-radius: 3px;
  position: relative;
  padding: 20px 15px;
  line-height: 20px;
`;

const StyledWarningIcon = styled.div`
  margin-right: 1em;
  margin-left: 0.5em;
  color: white;
  display: inline-block;
  position: relative;
  z-index: 0;
  &:before {
    content: '';
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    top: 50%;
    left: 50%;
    z-index: -1;
    transform: translate(-50%, -50%);
    background: ${(props) => props.color || COLORS.DANGER};
    display: block;
    position: absolute;
  }
`;

export const WarningIcon = () => (
  <StyledWarningIcon>
    <i className='fas fa-exclamation' />
  </StyledWarningIcon>
);

export const FormControl = styled.span`
  display: inline-block;
  ${(props) =>
    props.fullWidth
      ? `
    width:100%;
    & ${InputContainer}:not(.calendar-end-date-picker) {
      width: 100%
    }
  `
      : ''}
  ${(props) =>
    props.margin === 'normal'
      ? `

    margin-top: ${SPACINGS.X2};
    margin-bottom: 8px;
    `
      : ''}
      ${() =>
    detectIE()
      ? `
      max-width: 100%;
      `
      : ``}
      & .MuiFormControl-root {
    width: 100%;
  }
`;

export const MenuItem = styled.div`
  padding: 6px ${SPACINGS.X2};
  font-size: 1rem;
  color: ${COLORS.GRAY1};
  cursor: pointer;
  font-weight: 400;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    background-color: ${COLORS.LIGHTGRAY2};
  }
`;

export const TextSeparator = styled.span`
  margin-left: 1rem;
  margin-right: 1rem;
  border-right: ${COLORS.BACKGROUND} 1px solid;
`;

export const Field = styled.div`
  display: flex;
  flex-wrap: nowrap;
  background-color: white;
  padding-top: ${SPACINGS.X2};
  padding-bottom: ${SPACINGS.X2};
`;

export const ReportConfigsMenuButton = styled.div`
  display: flex;
  ${(props) =>
    props.isDisabled
      ? `
    color: ${GREYTONES.GREY_MEDIUM};
    cursor: default
  `
      : `
    color: ${PRIMARY_COLORS.TURQUOISE};
    cursor: pointer;
      &:hover {
      color: ${SECONDARY_COLORS.DARK_GREEN};
      }
  `}
`;
