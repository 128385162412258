import axios from "./instance";
import { Routing } from "@backendRouter";

export function getNotifications(page = 1, params = {}) {
  const url = Routing.generate("api_notifications_get_collection", {
    page,
    channel: "ui",
    "order[createdAt]": "DESC",
    "properties[]": ["seen", "sent", "id", "subject", "createdAt"],
    ...params
  });

  return axios.get(url);
}

export function setNotificationSeen(id) {
  const url = Routing.generate("api_notifications_put_item", { id });

  return axios({ method: "PUT", url, data: { seen: true } });
}

export function setAllNotificationsSeen() {
  const url = Routing.generate("api_notifications_mark_all_as_read_collection");

  return axios.post(url, {});
}
