import React, { PureComponent } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {SuccessSVG} from "./svg/Success";
import Icon from '@components/Shared/InventoryIcon';
import { COLORS } from "@constants";
import { editFlashes } from "@redux/actions/flashes";
import store from "@redux/store";

const NotificationContainer = styled.div`
  position: fixed;
  z-index: 9999;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  right: 15px;
  top: 20px;
  pointer-events: none;
`;

const AlertContainer = styled.div`
  position: relative;
  z-index: 9999;
  // top: 5px;
  // right: 5px;
  margin: 10px;
  border-radius: 25px;
  color: #fff;
  background: ${(props) =>
    props.type === "success" ? COLORS.SUCCESS : COLORS.ERROR};
  padding: 15px;
  display: inline-flex;
  flex-direction: column;
  white-space: nowrap;
  align-items: center;
  animation-duration: 1s;
  animation-name: slideInRight;
  pointer-events: auto;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CloseIcon = styled.i`
  position: absolute;
  z-index: 9999999;
  top: 15px;
  color: #fff;
  right: 15px;
  cursor: pointer;
  &:hover {
    color: ${COLORS.ACTIVE};
  }
`;

class Alert extends React.Component<{ key: string, text: string, index: number, type: $Flash["type"] }, { open: boolean }> {

  constructor(props) {
    super(props);
    this.state = { open: true };
  }
  componentDidMount() {
    setTimeout(this.close, 5000);
  }
  close = () => {
    store.dispatch(
      editFlashes(
        store
          .getState()
          .flashes.data.map((f, i) =>
            i === this.props.index ? { ...f, closed: true } : f
          )
      )
    );
  };

  render() {
    return (
      this.state.open && (
        <AlertContainer
          className={`politik-alert politik-alert__${this.props.type}`}
          type={this.props.type}>
          <CloseIcon onClick={this.close}>
            <Icon icon='ui.close' />
          </CloseIcon>
          {this.props.type === "success" ? (
            <SuccessSVG size={40} color='#fff' time={1.5} />
          ) : (
            <i
              className='fas fa-exclamation'
              aria-hidden='true'
              data-notify='icon'
              style={{
                padding: "9px 14px",
                borderRadius: "50%",
                border: "3px solid white",
              }} />
          )}
          <span className='mt-2'>{this.props.text}</span>
        </AlertContainer>
      )
    );
  }
}

export const showDangerAlert = (message) =>
  store.dispatch(
    editFlashes(store.getState().flashes.data.concat({ message, type: "danger" }))
  );

export const showSuccessAlert = (message) =>
  store.dispatch(
    editFlashes(store.getState().flashes.data.concat({ message, type: "success" }))
  );

function mapStateToProps(state) {
  return { flashes: state.flashes.data };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

class NotificationPopup extends PureComponent<{ flashes: $Flash[] }> {
  render() {
    return (
      <NotificationContainer id='notifications-container'>
        {this.props.flashes.map(
          (flash, index) =>
            !flash.closed && (
              <Alert
                key={String(index)}
                text={flash.message}
                index={index}
                type={flash.type} />
            )
        )}
      </NotificationContainer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPopup);
