import React, { useState, Children, ReactNode } from "react";
import styled from "styled-components";
import { without, get } from "lodash";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import { Tr } from "./TableRow";
import { ButtonLight, Button, LinkButton } from "@components/Shared/BaseUI";
import { Spinner } from "@components/Shared/Spinner";
import { DatePicker } from "@components/Shared/SelectPickers/DatePicker";
import { trans } from "@trans";
import { getConfig, columns, columnsOptional, hiddenColumnsSmallScreen } from "./config";
import { COLORS, ROLES } from "@constants";
import { SortingBox } from "../SortingBox";

const PopoverContent = styled.div`
  padding: 5px;
  border-radius: 4px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;

export const Th = styled.th`
  background: ${(props) =>
    props.onClick
      ? props.sorting
        ? COLORS.LIGHTGRAY
        : COLORS.LIGHTGRAY
      : COLORS.LIGHTGRAY};
  ${(props) =>
    props.onClick
      ? `cursor: pointer;
  &:hover {
    background: gray;
  }`
      : ""}
`;

export const Thead = styled.thead`
  border-bottom: 1px #ddd solid;
  background: ${COLORS.TABLE};
  color: #000;

  ${Th} {
    border: #ddd solid 1px;
    padding: 0px 5px 0px 5px;
    text-align: center;
  }
`;

const FilterOption = styled(Button)`
  color: ${(props) => (props.selected ? "#fff" : "black")};
  font-weight: ${(props) => (props.selected ? "700" : "initial")};
  width: 100%;
  display: block;
  background: ${(props) =>
    props.selected ? COLORS.PRIMARY : "rgba(0, 0, 0, 0.1)"};
  font-size: 10px;
  line-height: initial;
  margin: 1px;
  &:hover {
    box-shadow: 0 1px 4px 5px rgba(0, 0, 0, 0.14);
    background: ${(props) =>
    props.selected ? COLORS.PRIMARY : "rgba(0, 0, 0, 0.1)"};
  }
`;
const FilterSubmitButton = styled(ButtonLight)`
  display: block;
  margin: auto;
  margin-top: 10px;
`;
const FilterIcon = styled.i`
  cursor: pointer;
  color: ${(props) => (props.selected ? "inherit" : COLORS.DISABLED)};
`;
const FilterPopup = styled.div`
  text-align: center;
  border-radius: 5px;
  background: #fff;
  max-width: 400px;
`;

const HeadTool = styled.div`
  margin-left: 10px;
`;

const TimeRangeFilter = ({ onSubmit, currentSelection }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <FilterIcon
        className='fas fa-calendar-alt mr-1'
        onClick={handleClick}
        selected={Boolean(currentSelection)}
        title={trans("national.affairs.list.header.filter", "Filter")} />
      <Popper
        style={{ zIndex: 2, maxWidth: "100%" }}
        open={open}
        anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handleClose}>
          <DatePicker
            type='range'
            value={currentSelection[0]}
            value_end={currentSelection[1]}
            close={handleClose}
            onChange={onSubmit}
            headless={true} />
        </ClickAwayListener>
      </Popper>
    </>
  );
};

const Filter = ({ options, onSubmit, currentSelection = [] }: { options: unknown[], onSubmit: Function, currentSelection: unknown[] }) => {
  if (!options) {
    return null;
  }

  const [selected, select] = useState(currentSelection);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    select(currentSelection);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <FilterIcon
        className='fas fa-filter mr-1'
        onClick={handleClick}
        selected={selected.length > 0}
        title={trans("national.affairs.list.header.filter", "Filter")} />

      <Popper
        style={{ zIndex: 102, maxWidth: "100%" }}
        open={open}
        anchorEl={anchorEl}>
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <PopoverContent>
              <FilterPopup totalOptions={options.length}>
                <div className='d-flex justify-content-left'>
                  <LinkButton onClick={() => select(options)}>
                    {trans("ui.table.filter.all")}
                  </LinkButton>
                  <LinkButton onClick={() => select([])}>
                    {trans("ui.table.filter.clear")}
                  </LinkButton>
                </div>
                <div
                  style={{
                    maxHeight: 256,
                    overflow: "auto",
                    overflowX: "hidden",
                  }}>
                  {options.map((o, i) => (
                    <FilterOption
                      key={i}
                      option={o}
                      totalOptions={options.length}
                      type='button'
                      selected={selected.includes(o)}
                      onClick={() => {
                        const newSelection = selected.includes(o)
                          ? without(selected, o)
                          : selected.concat(o);

                        select(newSelection);
                      }}>
                      {o || trans("ui.table.filter.empty")}
                    </FilterOption>
                  ))}
                </div>
                <FilterSubmitButton
                  type='button'
                  onClick={() => {
                    onSubmit(selected);

                    setAnchorEl(null);
                  }}>
                  {trans("ui.submit")}
                </FilterSubmitButton>
              </FilterPopup>
            </PopoverContent>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  );
};

export const ThSortable = ({
  children,
  sortState,
  sort,
  filter,
  timeRangeFilter,
  colName,
}: {
  filter?: { options: unknown[], func: Function, currentSelection: unknown[] },
  children: ReactNode,
  sortState: $Sort,
  sort?: (() => void) | null,
  timeRangeFilter?: $AffairTable_props["timeRange"],
  colName?: string
}) => (
  <Th
    className={(hiddenColumnsSmallScreen.indexOf(colName || "") !== -1) ? 'd-none d-md-table-cell' : ''}>
    <div style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: 50
    }}>
      {Children.toArray(children)}
      {sort && (
        <span
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          className='politik-table__head__sort cursor-pointer'
          onClick={(e) => {
            e.stopPropagation();
            sort();
          }}>
          <HeadTool>
            <SortingBox
              //@ts-ignore
              sortDirection={sortState && sortState.toLowerCase()}
              sortFunc={() => { }}
              tableHead={true} />
          </HeadTool>
        </span>
      )}
      {filter && (
        <HeadTool>
          <Filter
            options={filter.options}
            onSubmit={filter.func}
            currentSelection={filter.currentSelection} />
        </HeadTool>
      )}
      {timeRangeFilter && (
        <HeadTool>
          <TimeRangeFilter
            onSubmit={timeRangeFilter.func}
            currentSelection={timeRangeFilter.currentSelection} />
        </HeadTool>
      )}
    </div>
  </Th>
);

export const TableHead= ({
  user,
  config,
  loading,
  sort,
  filters,
  filterOptions,
  setFilters,
  timeRange,
  showOptionalColumns,
}: {
  user: $User,
  config: $AffairTable_config,
  loading?: boolean,
  sort: { [key: string]: { func: () => void } },
  filters: $AffairTable_filters,
  filterOptions: { [key: string]: { options: unknown[] } },
  setFilters: Function,
  timeRange: $AffairTable_props["timeRange"],
  showOptionalColumns: { [key: string]: boolean }
}) => {
  const tableColumns = config.columns || columns;
  
  return (
    <Thead>
      <Tr>
        {tableColumns
          .filter((col) =>
            columnsOptional.includes(col) ? showOptionalColumns[col] : true
          )
          .map(
            (colName) =>
              !getConfig(config, "hideColumns")
                .concat(user.roles.includes(ROLES.ROLE_AREA_CANTONAL) ? [] : "canton")
                .includes(colName) && (
                <ThSortable
                  colName={colName}
                  key={colName}
                  //@ts-ignore
                  sort={get(sort, `${colName}.func`) ||
                  (get(config, `sort[${colName}]`)
                    ? () => {
                      const newFilters = {
                        ...filters,
                        sort: {
                          [colName]:
                            filters.sort && filters.sort[colName]
                              ? filters.sort[colName] === "desc"
                                ? "asc"
                                : "desc"
                              : "desc",
                        },
                      };
  
                      setFilters(newFilters);
                    }
                    : null)}
                  filter={get(config, `filters[${colName}]`) && {
                    options: filterOptions[colName],
                    func: (selected) =>
                      setFilters({ ...filters, [colName]: selected }),
                    currentSelection: filters[colName],
                  }}
                  timeRangeFilter={(colName === "date" &&
                  timeRange) ? {
                      func: timeRange.func,
                      currentSelection: timeRange.currentSelection,
                    } : undefined}
                  //@ts-ignore
                  sortState={get(sort, `${colName}.state`) ||
                  get(filters, `sort[${colName}]`)}>
                  {colName === "badges" && loading ? (
                    <Spinner width={20} height={20} />
                  ) : (
                    getConfig(config, `labels.${colName}`) ||
                    trans(`national.affairs.list.header.${colName}`, colName)
                  )}
                </ThSortable>
              )
          )}
      </Tr>
    </Thead>
  );
};
