export const CANTON_SLUG = {
  AG: 'ag',
  AR: 'ar',
  AI: 'ai',
  BL: 'bl',
  BS: 'bs',
  BE: 'be',
  FR: 'fr',
  GE: 'ge',
  GL: 'gl',
  GR: 'gr',
  JU: 'ju',
  LU: 'lu',
  NE: 'ne',
  NW: 'nw',
  OW: "ow",
  SH: 'sh',
  SZ: 'sz',
  SO: 'so',
  SG: 'sg',
  TI: 'ti',
  TG: 'tg',
  UR: 'ur',
  VD: 'vd',
  VS: 'vs',
  ZG: 'zg',
  ZH: "zh",
};