import axios from "./instance";
import { Routing } from '@backendRouter';

export const deleteItem = async (apiUrl) => {
  await axios.delete(apiUrl);
}

export const editSorting = async (apiUrl, sort) => {
  await axios.patch(apiUrl, {
    sorting: sort
  });
}

export const updateAffairs = async (apiUrl, data) => {
  await axios.patch(apiUrl, data);
}

export const getGroupsBasic = (params = {}) => {
  const url = Routing.generate("api_affair_groups_get_basic_collection", {
    pagination: false,
    ...params
  });

  return axios.get(url);
}

export const getTreeData = (params = {}) => {
  const url = Routing.generate("api_affair_groups_get_collection", {
    "parent[exists]": false,
    "pagination": false,
    ...params
  });

  return axios.get(url);
}

export const getTreeDataArchived = (params = {}) => {
  const url = Routing.generate("api_affair_groups_get_archived_collection", {
    "parent[exists]": false,
    "pagination": false,
    ...params
  });

  return axios.get(url);
}

export const getGroup= (id) => {
  const url = Routing.generate("api_affair_groups_get_details_collection", {
    id
  });

  return axios.get(url);
}

export const getGroupEdit = (id) => {
  const url = Routing.generate("api_affair_group_nodes_get_edit_item", {
    id
  });

  return axios.get(url);
}

export const deleteGroup = (id) => {
  const url = Routing.generate("api_affair_group_nodes_get_item", {
    id
  });

  return axios.delete(url);
}

export const deleteNode = (id) => {
  const url = Routing.generate("api_affair_group_nodes_get_item", {
    id
  });

  return axios.delete(url);
}

export const editGroup = (id, body = {}) => {
  const url = Routing.generate("api_affair_group_nodes_put_item", {
    id
  });

  return axios.put(url, { ["@type"]: "AffairGroup", ...body });
}

export const unarchiveGroup = (id, body = {}) => {
  const url = Routing.generate("api_affair_group_nodes_put_unarchive_item", {
    id
  });

  return axios.put(url, { ["@type"]: "AffairGroup", ...body });
}

export const editNode = (id, body = {}) => {
  const url = Routing.generate("api_affair_group_nodes_put_item", {
    id
  });

  return axios.put(url, { ["@type"]: "AffairGroup", ...body });
}

export const assignAffairToGroup = (parentId, affair) => {
  const url = Routing.generate("api_affair_group_nodes_post_collection");
  const body = {
    item: affair["@id"],
    parent: Routing.generate("api_affair_groups_get_item", {
      id: parentId
    }),
    "@type": `AffairGroup${affair["@type"]}Leaf`
  };

  return axios.post(url, body);
};

export const assignAffairsToGroups = (array) => {
  const url = Routing.generate("api_affair_group_nodes_post_multiple_collection");
  const body = array.map(([affair, groupId]) => ({ item: affair.iri || affair["@id"], parent: groupId, "@type": `AffairGroup${affair["categories"] || affair["@type"]}Leaf` }));

  return axios.post(url, body);
};

export const sortMultipleNodes = (array) => {
  //updates parent and sortPosition for given array of nodes
  const url = Routing.generate("api_affair_group_nodes_put_multiple_collection");

  return axios.put(url, array);
};
