import axios, { headers } from "./instance";
import { Routing } from "@backendRouter";
import { GLOBALS } from "@constants";


export const renewSession = () => {
    sessionStorage.setItem("politikch-sessionStart", String(new Date().getTime()));
    sessionStorage.setItem("politikch-sessionEnd", String(new Date().getTime() + GLOBALS.SESSION_LENGTH));
}
export const clearSession = () => {
    sessionStorage.removeItem("politikch-sessionData");
    sessionStorage.removeItem("politikch-sessionStart");
    sessionStorage.removeItem("politikch-sessionEnd");
}
export function login(username, password) {
    const url = Routing.generate(`login_check`);

    return axios.post(url, {
        username,
        password
    }).then(res => {
        sessionStorage.setItem("politikch-sessionData", JSON.stringify(res.data));

        if (res.data.login === "success" && (!res.data.authMode || (res.data.authMode === "google" && res.data["2fa_complete"] !== false))) {
            renewSession()
        }
        return res;
    });
}

export const logout = () => {
    clearSession();
    window.location.href = Routing.generate("user_security_logout")
}

export const getLoginState = () => {
    const url = Routing.generate("login_state")
    return axios.get(url).then(({ data }) => data.loginState)
}
export const isLoggedIn = async () => {
    const loginState = await getLoginState();
    return loginState;
}

export const getSessionData = () => {
    const dataString = sessionStorage.getItem("politikch-sessionData");
    if (!dataString)
        return null
    return JSON.parse(dataString || "")
}

export const login2fa = (body: { auth_code: number, trusted: boolean }) => {
    const url = Routing.generate(`2fa_login_check`, body);

    return axios.post(url).then(res => {
        sessionStorage.setItem("politikch-sessionData", JSON.stringify(res.data));
        if (res.data["2fa_complete"]) {
            renewSession()
        }
        return res;
    });
}

export const resetPassword = (token: string, newPassword: string) => {
    const url = Routing.generate('api_user_resetting_reset', {
        token
    });

    return axios.post(url, {
        'first': newPassword,
        'second': newPassword
    });
};
