import React, { Component } from "react";
import styled from "styled-components";
import { Button } from "./BaseUI";
import { COLORS } from "@constants";
import { trans } from "@trans";
import { SuccessSVG } from "./svg/Success";

const StyledSubscribeButton = styled(Button)`
  font-size: 10px;
  padding-bottom: 1px;
  padding-top: 1px;
  border: ${(props) => props.tree ? "#EEEEEE 1px solid" : "white 1px solid"}
  color: white;
  font-weight: 600;
  ${(props) =>
    !props.active && !props.disabled
      ? `
  border-color: ${COLORS.PRIMARY};
  color: ${COLORS.PRIMARY};
  background: white;`
      : ""}
  width: 110px!important;
  padding-left: 0;
  padding-right: 0;
`;

type $Props = {
  type: string;
  id: number | string;
  onSubscribe?: Function;
  onUnsubscribe?: Function;
  className?: string;
  data?: $AffairGroup | $Affair | $GroupTreeNode;
  subscribe?: Function;
  unsubscribe?: Function;
  subscription?: $GroupSubscription;
  tree?: boolean;
}
type $State = {
  loading: boolean;
}
export class SubscribeButton extends Component<$Props, $State> {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subscription !== this.props.subscription) {
      this.setState({ loading: false });
    }
  }

subscribe = () => {
  this.setState({ loading: true });

  if (this.props.subscribe) {
    this.props.subscribe(this.props.data);
  }
};

unsubscribe = () => {
  this.setState({ loading: true });

  if (this.props.unsubscribe) {
    this.props.unsubscribe(this.props.data);
  }
};
render() {
  const { loading } = this.state;
  const active = this.props.subscription;
  const onClick = active ? this.unsubscribe : this.subscribe;
  const title = loading
    ? trans("notification.subscription.button.affair.tooltip")
    : active
      ? trans("notification.subscription.button.subscribed")
      : trans("notification.subscription.button.unSubscribed");
  

  return (
    <StyledSubscribeButton
      type='button'
      className={this.props.className}
      tree={this.props.tree}
      title={title}
      active={active}
      onClick={onClick}
      disabled={loading}
      loading={loading}>
      {loading || active ? (
        <span>
          {trans("notification.subscription.button.subscribed")}{" "}
          {active && (
            <SuccessSVG size={12} color={COLORS.SUCCESS} time={0.5} />
          )}
        </span>
      ) : (
        trans("notification.subscription.button.unSubscribed")
      )}
    </StyledSubscribeButton>
  );
}
}
