import React, { useState, useRef } from "react";
import styled from "styled-components";
import Icon from "../InventoryIcon";
import { COLORS, SHARED } from "@constants";
import { detectIE } from '@helpers';

export const Chip = styled.span`
  max-width:100%;
  color: ${COLORS.GRAY1};
  height: calc(${SHARED.INPUT_HEIGHT} - 2px);
  line-height: calc(${SHARED.INPUT_HEIGHT} - 2px);
  margin: 1px 1px;
  padding: 0px 6px;
  white-space: nowrap;
  font-size: 10px;
  font-weight: 600;
  font-family: 'Roboto', sans-serif;
  border-radius: 4px;
  display: ${() => (detectIE() ? "inline-block" : "inline-flex")};
  align-items: center;
  background-color: ${COLORS.PRIMARY_LIGHT};
  ${(props) =>
    props.disabled
      ? `
    background-color: ${COLORS.DISABLED};
    pointer-events: none;
    color: #fff;
  `
      : `  
    &:hover {
        background-color: ${COLORS.PRIMARY};
        color: #FFF;
    }`}

}`;
const DeleteIcon = styled(Icon)`
  color: rgba(0, 0, 0, 0.26);
  &:hover {
    color: inherit;
  }
`;
const InputWrapper = styled.div`
  cursor: pointer;
  position: relative;
  border-radius: 4px;
  padding: 7px 10px;
  min-height: 44px;
  ${(props) =>
    props.disabled ? "pointer-events:none;filter:grayscale(1);" : ""}
  border: 1px solid ${(props) =>
    props.focus && !props.disabled ? "rgb(63, 51, 86)" : "#AAA"};
  transition: border-color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  &:hover {
    border-color: rgb(63, 51, 86);
  }
`;

const InvisibleInput = styled.input`
  margin-left: 2px;
  border: none;
  transform: translateY(2px);
  &:disabled {
    visibility: hidden;
  }
`;

const Label = styled.label`
top:11px;
position:absolute;
background-color: ${(props) =>
    props.minimized ? "rgba(255,255,255,1)" : "rgba(255,255,255,0)"};
padding: 0px 5px;
font-size: 16px;
z-index:1;
line-height: 20px;
${(props) =>
    props.minimized
      ? `transform: translate(-9px,-22px) scale(0.75);color:rgba(0, 0, 0, 0.54);`
      : `color:#AAA;`}
transition: all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
transition-property: color, background-color, transform;
}`;

type $Props = {
  onChangeCallback?: Function,
  onAdd?: Function,
  onDelete?: Function,
  disabled?: boolean,
  label: string,
  chips: ({ value: string })[]
}

export const ChipInput = ({ onChangeCallback, onAdd, onDelete, disabled, label, ...props }: $Props) => {
  const [value, setValue] = useState("");
  const [chips, setChips] = useState(props.chips || []);
  const [shouldDelete, setShouldDelete] = useState(true);
  const [focus, setFocus] = useState(false);
  const textInputRef = useRef<HTMLInputElement>();

  React.useEffect(() => {
    if (props.chips) {
      setChips(props.chips);
    }
  }, [props.chips]);

  const giveFocusToInput = () => {
    textInputRef.current?.querySelector("input")?.focus();
  };

  return (
    <InputWrapper
      ref={textInputRef}
      onClick={giveFocusToInput}
      disabled={disabled}
      focus={focus}>
      <Label minimized={chips.length || focus}>{label}</Label>
      <span>
        {chips.map((chip, i) => (
          <Chip key={i} onClick={(e) => e.stopPropagation()}>
            {chip.value}
            <DeleteIcon
              icon='ui.close'
              onClick={() => onDelete && !disabled && onDelete(i)} />
          </Chip>
        ))}
      </span>

      <InvisibleInput
        value={value}
        size={value.length || 1}
        onChange={(e) => {
          setValue(e.target.value);

          if(onChangeCallback) {
            onChangeCallback(e.target.value);
          }
        }}
        onKeyUp={(e) => {
          var code = e.keyCode ? e.keyCode : e.which;

          // Delete keyword when backspace is pressed
          if (code === 8 && shouldDelete && !disabled) {
            setChips(chips.slice(0, -1));

            if (onChangeCallback) {
              onChangeCallback(chips.slice(0, -1));
            }

            if (onDelete) {
              onDelete(chips.length - 1);
            }
          }

          if (e.target.value === "") {
            setShouldDelete(true);
          }
          else {
            setShouldDelete(false);
          }
        }}
        onKeyPress={(e) => {
          var code = e.keyCode ? e.keyCode : e.which;

          // Submit keyword when Enter is pressed
          if (code == 13 && !disabled) {
            e.stopPropagation();
            e.preventDefault();

            if (e.target.value) {
              setChips(chips.concat({ value: e.target.value }));

              if (onAdd) {
                onAdd({ value });
              }

              if (onChangeCallback) {
                onChangeCallback(chips.concat({ value }));
              }

              setValue("");
            }
          }
        }}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        {...props} />
    </InputWrapper>
  );
};
