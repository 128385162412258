import axios from "./instance";
import { Routing } from '@backendRouter';

export const getReportConfigs = () =>
  axios.get(Routing.generate("api_reports_get_collection", { pagination: false }))

export const createReportConfig = (body = {}) =>
  axios.post(Routing.generate("api_reports_post_collection"), body);


export const editReportConfig = (id, body = {}) =>
  axios.put(Routing.generate("api_reports_put_item", { id }), body);

export const deleteReportConfig = (id) =>
  axios.delete(Routing.generate("api_reports_delete_item", { id }));


export const getAffairReportTemplates = (body = {}) =>
  axios.get(Routing.generate("api_reports_get_collection", body));


export const getAffairReport = (body = {}) =>
  axios.post(Routing.generate("reports_affair"), body);


export const saveVisualReport = (body = {}) =>
  axios.post(Routing.generate("api_visualizer_configs_post_collection"), body)

export const deleteVisualReport = (id) => 
  axios.delete(Routing.generate("api_visualizer_configs_delete_item", { id }));

export const generateVisualReportPublicLink = (visualizerConfigId) =>
  Routing.generate("user_share_visualizer", { visualizerConfigId })

export const getVisualReportConfigs = () =>
  axios.get(Routing.generate("api_visualizer_configs_get_collection", {
    updatedAt: "DESC",
    pagination: false
  }));

export const getVisualReport = (id) =>
 axios.get(Routing.generate("api_visualizer_configs_get_item", { id }));


export const editVisualReport = (id, body = {}) =>
  axios.put(Routing.generate("api_visualizer_configs_put_item", { id }), body)
